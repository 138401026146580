import React, { useEffect, useState } from 'react';
import { Title } from '../../../../components/Title/Title';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { citiesRef } from '../../../../utils/collectionRefs';
import { useNavigate, useParams } from 'react-router-dom';
import { doc,getDoc, updateDoc } from 'firebase/firestore';
import '../Couriers/scss/AddCourier.scss';
import {
  TextField,
  Button,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Helmet } from 'react-helmet';

const CityUpdate = ({ pageTitle }) => {
  const { id } = useParams()
  const [city, setCity] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  useEffect(() => {
    const getCityById = async () => {
      const cityRef = doc(citiesRef, id);
      const citySnap = await getDoc(cityRef);

      if (citySnap.exists()) {
        setCity(citySnap.data());
      } else {
        console.log('No such city!');
      }
    };

    getCityById();
  }, []);


  const handleUpdateCity = async (data) => {
    try {
      setOpen(true);

      await updateDoc(doc(citiesRef, id), { ...data });
      setOpen(false);
      navigate('/cities');

      toast.success('Город успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });


      reset();
    } catch (e) {
      console.error('Error updating document: ', e);
      toast.error('Произошла ошибка при редактировании города', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Редактирование городa' />
        <Title title={'Редактирование города'} icon={<NoteAltRoundedIcon fontSize='24' />} />
        <div className='container-inner'>
          {!city ? (
            <Loader />
          ) : (
            <div className='addCourier-wrapper city'>
              <div className='order-block-head'>
                <h3>Редактирование города</h3>
              </div>
              <form className='add-courier-form'>
                <Box className='add-courier-form__input'>
                  <Controller
                    name='name'
                    control={control}
                    defaultValue={city?.name || ''}
                    rules={{ required: 'Обьязательное полe!' }}
                    render={({ field }) => (
                      <TextField
                        label='Название города'
                        placeholder='Город'
                        multiline
                        variant='outlined'
                        size='small'
                        helperText={errors?.name?.message}
                        error={errors?.name && true}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Button
                  onClick={handleSubmit((data) => handleUpdateCity(data))}
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f', width: 'auto' }}
                  sx={{ marginTop: '10px' }}
                >
                  Редактировать регион/село
                </Button>
              </form>
            </div>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

export default CityUpdate;
