import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { Box, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import CircularProgressBar from '../../../../components/CircularProgressBar/CircularProgressBar';
import { getCountFromServer, getAggregateFromServer, sum, query, where, Timestamp, count, getDocs } from 'firebase/firestore';
import { citiesRef, completedOrdersRef, couriersRef, orderRef, returnedOrderRef } from '../../../../utils/collectionRefs';
import InfoIcon from '@mui/icons-material/Info';

import './scss/Dashboard.scss';
import { Helmet } from 'react-helmet';
import { orderStatus, paymentPerson, typeOfOrder } from './../../../../utils/index';
import { getDayAgo } from '../../../../utils/dateFormatter';
import { returnedOrderStatus } from '../Orders/constants';
import TabContent from './helpers/TabContent';
import { getProgressBarData, getTabsContent } from './constants/index';
import { fetchOrdersForChart } from './helpers/fetchOrdersForChart';
import CustomLineChart from '../../../../components/Charts/CustomLineChart';
import { orderBy } from 'lodash';
import OrderSort from '../../../../components/OrderSort';

const useFilters = () => {
  const [filters, setFilters] = useState({
    cityTo: '',
    cityFrom: '',
    packageType: '',
    status: '',
    whoPays: '',
  });

  const handleFilterChange = useCallback((field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({
      cityTo: '',
      cityFrom: '',
      packageType: '',
      status: '',
      whoPays: '',
    });
  }, [])

  return [filters, handleFilterChange, resetFilters];
};

const useDateRange = () => {
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const handleDateChangeFrom = useCallback((event) => {
    setDateFrom(event.target.value);
  }, []);

  const handleDateChangeTo = useCallback((event) => {
    setDateTo(event.target.value);
  }, []);

  const resetDates = useCallback(() => {
    setDateFrom('')
    setDateTo('')
  }, [])

  return [dateFrom, dateTo, handleDateChangeFrom, handleDateChangeTo, setDateFrom, setDateTo, resetDates];
};

const useTabData = (ref, selectedFilters, selectedDateFrom, selectedDateTo) => {
  const [data, setData] = useState([]);

  const buildQuery = useCallback(() => {
    let q = query(ref);
    let remeptionQ = query(ref, where('redemption', '>', 0))
    Object.keys(selectedFilters)
      .filter((field) => selectedFilters[field] !== 'Все' && selectedFilters[field] !== '')
      .forEach((field) => {
        q = query(q, where(field, '==', selectedFilters[field]));
        remeptionQ = query(remeptionQ, where(field, '==', selectedFilters[field]));
      });

    if (selectedDateFrom) {
      const startOfDay = new Date(selectedDateFrom);
      startOfDay.setHours(0, 0, 0, 0);
      q = query(q, where('dateCreated', '>=', startOfDay));
      remeptionQ = query(remeptionQ, where('dateCreated', '>=', startOfDay));
    }

    if (selectedDateTo) {
      const endOfDay = new Date(selectedDateTo);
      endOfDay.setHours(23, 59, 59, 999);
      q = query(q, where('dateCreated', '<=', endOfDay));
      remeptionQ = query(remeptionQ, where('dateCreated', '<=', endOfDay));
    }

    return { q, remeptionQ };
  }, [ref, selectedFilters, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersQuery = buildQuery();
        const snapshot = await getAggregateFromServer(ordersQuery.q, {
          count: count(),
          cost: sum('cost'),
        })
        const redemptionSnapshot = await getAggregateFromServer(ordersQuery.remeptionQ, {
          redemption: sum('redemption'),
        });
        setData({ ...snapshot.data(), ...redemptionSnapshot.data() });
      } catch (error) {
        console.error('Error fetching tab data:', error.message);
      }
    };

    fetchData();
  }, [ref, selectedFilters, selectedDateFrom, selectedDateTo, buildQuery]);

  return [data];
};

const useRegionStatistics = (selectedCity) => {
  const [statistics, setStatistics] = useState({
    ordersCount: 0,
    couriersCount: 0,
    totalRevenue: 0,
    orderShare: 0,
  });
  const fetchStatistics = useCallback(async () => {
    let selectedCityOrderRef = orderRef;
    let courierrsRef = couriersRef;

    if (selectedCity !== '') {
      selectedCityOrderRef = query(selectedCityOrderRef, where('cityFilter', '==', selectedCity));
      courierrsRef = query(courierrsRef, where('city', '==', `${selectedCity}`));
    }

    try {
      const orderAggregate = await getAggregateFromServer(selectedCityOrderRef, {
        ordersCount: count(),
        totalRedemption: sum('redemption')
      })

      const totalCouriersCount = await getCountFromServer(courierrsRef)
      const totalOrdersCountSnap = await getCountFromServer(orderRef)

      const totalOrdersCount = totalOrdersCountSnap.data().count || 0;
      const ordersCount = orderAggregate.data().ordersCount || 0;
      const couriersCount = totalCouriersCount.data().count || 0;
      const totalRedemption = orderAggregate.data().totalRedemption || 0;

      const orderShare = totalOrdersCount > 0 ? (ordersCount / totalOrdersCount) * 100 : 0;

      setStatistics({
        ordersCount,
        couriersCount,
        totalRedemption,
        orderShare,
      });

    } catch (error) {
      console.error('Error fetching region statistics:', error);
    }
  }, [selectedCity]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  return statistics;
};



const Dashboard = ({ pageTitle }) => {
  const [countOfAllOrders, setCountOfAllOrders] = useState(0)
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCompletedOrders, setTotalCompletedOrders] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [ordersForChart, setOrdersForChart] = useState(null)
  const [activeOrdersForChart, setActiveOrdersForChart] = useState(null)
  const [returnedOrdersForChart, setReturnedOrdersForChart] = useState(null)
  const [totalTodayOrders, setTotalTodayOrders] = useState(0);

  const [cities, setCities] = useState(null)
  const [selectedCity, setSelectedCity] = useState('Все')
  const statistics = useRegionStatistics(selectedCity);
  const [filters, handleFilterChange] = useFilters();

  const [selectedDateFrom, selectedDateTo, handleDateChangeFrom, handleDateChangeTo] = useDateRange();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateFilter, setDateFilter] = useState('all');

  const [orders] = useTabData(orderRef, filters, selectedDateFrom, selectedDateTo);
  const [completedOrders] = useTabData(completedOrdersRef, filters, selectedDateFrom, selectedDateTo);
  const [returnedOrders] = useTabData(returnedOrderRef, filters, selectedDateFrom, selectedDateTo);

  const today = getDayAgo(0);

  const green = '#15B79F';
  const yellow = '#FB9C0C';
  const lavanda = '#635BFF';
  const lavandas = '#635bff80';
  const pink = '#f54db7';
  const pinkS = '#f54db771';



  const fetchCounts = useCallback(async () => {
    let activeOrderQuery = orderRef;
    let completedOrderQuery = completedOrdersRef;
    let returnedOrderQuery = returnedOrderRef;

    try {
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      if (dateFilter === 'currentMonth') {
        const fromTimestamp = Timestamp.fromDate(firstDayOfMonth);
        const toTimestamp = Timestamp.fromDate(today);

        activeOrderQuery = query(
          orderRef,
          where('dateCreated', '>=', fromTimestamp),
          where('dateCreated', '<=', toTimestamp)
        );
        completedOrderQuery = query(
          completedOrdersRef,
          where('dateDelivered', '>=', fromTimestamp),
          where('dateDelivered', '<=', toTimestamp)
        );
        returnedOrderQuery = query(
          returnedOrderRef,
          where('dateCreated', '>=', fromTimestamp),
          where('dateCreated', '<=', toTimestamp)
        );
      } else if (dateFilter === 'custom' && dateFrom && dateTo) {
        const fromDate = new Date(dateFrom);
        fromDate.setHours(0, 0, 0, 0)
        const toDate = new Date(dateTo);
        toDate.setHours(23, 59, 59, 999)
        const fromTimestamp = Timestamp.fromDate(fromDate);
        const toTimestamp = Timestamp.fromDate(toDate);

        activeOrderQuery = query(
          orderRef,
          where('dateCreated', '>=', fromTimestamp),
          where('dateCreated', '<=', toTimestamp)
        );
        completedOrderQuery = query(
          completedOrdersRef,
          where('dateDelivered', '>=', fromTimestamp),
          where('dateDelivered', '<=', toTimestamp)
        );
        returnedOrderQuery = query(
          returnedOrderRef,
          where('dateCreated', '>=', fromTimestamp),
          where('dateCreated', '<=', toTimestamp)
        );
      }

      const completedOrdersCount = await getCountFromServer(completedOrderQuery);
      const completedOrders = completedOrdersCount.data().count;
      setTotalCompletedOrders(completedOrders);

      const ordersCount = await getCountFromServer(activeOrderQuery);
      const orders = ordersCount.data().count;
      setTotalOrders(orders);

      const clientCount = await getCountFromServer(returnedOrderQuery);
      const clients = clientCount.data().count;
      setTotalClients(clients);

      const todayOrdersQuery = query(orderRef, where('dateCreated', '>=', Timestamp.fromDate(today)));
      const todayOrdersSnapshot = await getCountFromServer(todayOrdersQuery);
      setTotalTodayOrders(todayOrdersSnapshot.data().count);

      const allOrders = completedOrders + orders + clients;
      setCountOfAllOrders(allOrders);

    } catch (error) {
      console.error('Error fetching data counts:', error.message);
    }
  }, [dateFilter, dateFrom, dateTo, today]);

  const fetchCompletedOrders = useCallback(async () => {
    const data = await fetchOrdersForChart(completedOrdersRef);
    setOrdersForChart(data);
  }, []);

  const fetchActiveOrders = useCallback(async () => {
    const data = await fetchOrdersForChart(orderRef);
    setActiveOrdersForChart(data);
  }, []);

  const fetchReturnedOrders = useCallback(async () => {
    const data = await fetchOrdersForChart(returnedOrderRef);
    setReturnedOrdersForChart(data);
  }, []);

  useEffect(() => {
    const fectchCities = async () => {
      try {
        const q = query(citiesRef, orderBy('name', 'asc'));
        const fetching = await getDocs(q);
        const settingDocs = fetching.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setCities(settingDocs);
      } catch (error) {
        console.error(error);
      }
    };

    fectchCities();
  }, []);

  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  useEffect(() => {
    fetchCompletedOrders();
    fetchActiveOrders();
    fetchReturnedOrders()
  }, [
    fetchCompletedOrders,
    fetchActiveOrders,
    fetchReturnedOrders
  ]);


  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  const dateFormatterWithoutYear = useCallback((date) => {
    const month = date?.toLocaleString('default', { month: 'short' });
    const day = date?.getDate();
    return `${month} ${day}`;
  }, []);

  const data = useMemo(() => {
    const combinedData = {};

    ordersForChart?.forEach(order => {
      const date = dateFormatterWithoutYear(order.date);
      if (!combinedData[date]) combinedData[date] = { date };
      combinedData[date].Доставленные = order.count;
    });

    activeOrdersForChart?.forEach(order => {
      const date = dateFormatterWithoutYear(order.date);
      if (!combinedData[date]) combinedData[date] = { date };
      combinedData[date].Активные = order.count;
    });

    returnedOrdersForChart?.forEach(order => {
      const date = dateFormatterWithoutYear(order.date);
      if (!combinedData[date]) combinedData[date] = { date };
      combinedData[date].Возвраты = order.count;
    });

    Object.values(combinedData).forEach(dataPoint => {
      dataPoint['Общее количество'] = (dataPoint.Доставленные || 0) + (dataPoint.Активные || 0) + (dataPoint.Возвраты || 0);
    });

    return Object.values(combinedData);
  }, [ordersForChart, activeOrdersForChart, returnedOrdersForChart, dateFormatterWithoutYear]);


  const progressBarData = useMemo(() => getProgressBarData(
    totalCompletedOrders,
    totalOrders,
    totalClients,
    totalTodayOrders,
    countOfAllOrders,
    green,
    lavanda,
    lavandas,
    yellow,
    pink
  ), [
    totalCompletedOrders,
    totalOrders,
    totalClients,
    totalTodayOrders,
    countOfAllOrders,
    green,
    lavanda,
    lavandas,
    yellow,
    pink
  ]);

  const tabsContent = getTabsContent(
    orders,
    completedOrders,
    returnedOrders,
    handleFilterChange,
    selectedDateFrom,
    selectedDateTo,
    handleDateChangeFrom,
    handleDateChangeTo,
    typeOfOrder,
    paymentPerson,
    orderStatus,
    returnedOrderStatus,
    lavanda,
    green,
    yellow,
    pink
  );

  const handleDateChangeFrom1 = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateChangeTo1 = (event) => {
    setDateTo(event.target.value);
  };

  const orderLines = useMemo(() => [
    {
      dataKey: 'Общее количество',
      stroke: pink,
      fill: pinkS,
    },
    {
      dataKey: 'Доставленные',
      stroke: green,
      fill: green,
    },
    {
      dataKey: 'Активные',
      stroke: lavanda,
      fill: lavandas,
    },
    {
      dataKey: 'Возвраты',
      stroke: yellow,
      fill: yellow,
    }
  ], []);

  const regionLines = [
    {
      value: statistics?.ordersCount,
      pathColor: pink,
      textColor: pink,
      trailColor: pinkS,
      subTitle: 'Активные',
      article: '',
      maxValue: 30,
    },
    {
      value: statistics?.couriersCount,
      pathColor: green,
      textColor: green,
      trailColor: '#CCFBEF',
      subTitle: 'Курьеры',
      article: '',
      maxValue: 2,
    },
    {
      value: statistics?.orderShare,
      pathColor: yellow,
      textColor: yellow,
      trailColor: '#FFF3C6',
      subTitle: 'Доля',
      article: '%',
      maxValue: 100,
    },
    {
      value: statistics?.totalRedemption,
      pathColor: lavanda,
      textColor: lavanda,
      trailColor: lavandas,
      subTitle: 'Сумма выкупа',
      article: 'c',
      maxValue: 10000,
    },
  ]

  return (
    <React.Fragment>
      <Helmet><title>{pageTitle}</title></Helmet>
      <div className='containerr'>
        <Header previous='Статистика' />
        <Title title={'Статистика'} icon={<TrendingUpRoundedIcon fontSize='24px' />} />
        <div className='container-inner'>
          <div className='dashboard-block'>
            <div className='dashboard-block-top-title'>
              <h2>Общее количество</h2>
              <Tooltip
                title="В этом блоке отображаются ключевые показатели для выбранного района, включая количество активных заказов, курьеров, долю от заказа и сумму выкупа. Выберите нужный город, чтобы увидеть соответствующую статистику по районам."
                placement='left'
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>

            <Stack direction='row'>
              <FormControl variant="outlined" style={{ minWidth: 200, marginRight: 20 }}>
                <InputLabel id="date-filter-label">По периоду</InputLabel>
                <Select
                  labelId="date-filter-label"
                  id="date-filter-select"
                  value={dateFilter}
                  size='small'
                  onChange={handleDateFilterChange}
                  label="По периоду"
                >
                  <MenuItem value="all">Общий период</MenuItem>
                  <MenuItem value="currentMonth">Текущий месяц</MenuItem>
                  <MenuItem value="custom">Выбор вручную</MenuItem>
                </Select>

              </FormControl>
              {dateFilter === 'custom' && <Divider orientation='vertical' flexItem sx={{ mr: 2 }} />}
              {dateFilter === 'custom' && (
                <Stack direction='row'>
                  <TextField
                    id="date-from"
                    label="Начало"
                    type="date"
                    value={dateFrom}
                    size='small'
                    onChange={handleDateChangeFrom1}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginRight: 20 }}
                  />

                  <TextField
                    id="date-to"
                    label="Конец"
                    type="date"
                    size='small'
                    value={dateTo}
                    onChange={handleDateChangeTo1}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <div className='dashboard-progressbar-container'>
              {progressBarData?.map((item, index) => (
                <div className='dashboard-card' key={index}>
                  <CircularProgressBar
                    value={item?.value}
                    pathColor={item?.pathColor}
                    textColor={item?.textColor}
                    trailColor={item?.trailColor}
                    article={item?.article}
                    maxValue={item.maxValue}
                  />
                  <p className='dashboard-card-subTitle'>{item?.subTitle}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='dashboard-block'>
            <div className='dashboard-block-top-title'>
              <h2>Статистика кол-во заказов за последние 10 дней</h2>
              <Tooltip
                title='На этом графике представлено количество (активных, доставленных, возвратных) заказов за последние 10 дней.'
                placement='left'
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className='dashboard-chart-container'>
              {data && data?.length > 0 && (
                <CustomLineChart
                  data={data}
                  dataKey='date'
                  lines={orderLines}
                />
              )}
            </div>
          </div>
          <div className="dashboard-block">
            <div className='dashboard-block-top-title'>
              <h2>Статистика по районам</h2>
              <Tooltip
                title="В этом блоке отображаются ключевые показатели для выбранного района, включая количество активных заказов, курьеров, долю от заказа и сумму выкупа. Выберите нужный город, чтобы увидеть соответствующую статистику по районам."

                placement='left'
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Box width={'300px'}>
              <TextField
                id='outlined-select-currency'
                select
                label='Города'
                fullWidth
                size='small'
                defaultValue=''
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <MenuItem value='Все'>Все</MenuItem>
                {cities?.map((option) => (
                  <MenuItem key={option.name} value={parseInt(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <div className='dashboard-progressbar-container'>
              {regionLines?.map((item, index) => (
                <div className='dashboard-card' key={index}>
                  <CircularProgressBar
                    value={item?.value}
                    pathColor={item?.pathColor}
                    textColor={item?.textColor}
                    trailColor={item?.trailColor}
                    article={item?.article}
                    maxValue={item.maxValue}
                  />
                  <p className='dashboard-card-subTitle'>{item?.subTitle}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='dashboard-block'>
            <div className='dashboard-block-top-title'>
              <h2>Общая сумма</h2>
              <Tooltip
                title='Здесь отображается общая сумма заказов по статусам: Активные, Доставленные, Возвраты'
                placement='left'
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>

            <>
              <div className='dashboard-total-amount-cards'>
                <div className='orders-sorting-header clear-btn'>
                  <Stack style={{ width: '100%', flexWrap: 'wrap' }} direction='row' gap={2}>
                    <OrderSort
                      sortKey='По типу'
                      defaultValue=''
                      options={typeOfOrder}
                      onChange={(e) => handleFilterChange('packageType', e.target.value)}
                    />
                    <OrderSort
                      sortKey='Кто оплачивает'
                      defaultValue=''
                      options={paymentPerson}
                      onChange={(e) => handleFilterChange('whoPays', e.target.value)}
                    />
                    <OrderSort
                      sortKey='По статусу'
                      defaultValue=''
                      options={[...orderStatus, ...returnedOrderStatus]}
                      onChange={(e) => handleFilterChange('status', e.target.value)}
                    />
                    <div className='order-sort order-sort-date'>
                      <label htmlFor='orderDate' className='orders-sort-date-label'>
                        От
                        <input
                          className='order-sort-dateInput'
                          type='date'
                          name='orderDate'
                          id='orderDate'
                          value={selectedDateFrom || ''}
                          onChange={handleDateChangeFrom}
                        />
                      </label>
                      <span></span>
                      <label htmlFor='orderDate2' className='orders-sort-date-label'>
                        До
                        <input
                          className='order-sort-dateInput'
                          type='date'
                          name='orderDate2'
                          id='orderDate2'
                          value={selectedDateTo || ''}
                          onChange={handleDateChangeTo}
                        />
                      </label>
                    </div>
                  </Stack>
                </div>

                {tabsContent.map((tab, index) => (
                  <TabContent key={index} {...tab} />
                ))}
              </div>
            </>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;