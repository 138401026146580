import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import noData from '../../../../../assets/images/no-image-for-datagrid.png'
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

export const storeCatsColumns = ({ event }) => {
  const userType = localStorage.getItem('userType');

  return [
    {
      width: 100,
      field: 'id',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>ID</strong>,
    },
    {
      width: 100,
      field: 'icon',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return <img src={params.row.icon ?? noData} alt={params.row.nameRu} style={{ width: '100%', height: 'auto' }} />
      },
      renderHeader: () => <strong>Фото</strong>,
      disableColumnMenu: false,
    },
    {
      width: 300,
      field: 'nameKg',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>Название на KG</strong>,
    },
    {
      width: 300,
      field: 'nameRu',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>Название на RU</strong>,
    },
    {
      width: 300,
      field: 'nameEn',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>Название на EN</strong>,
    },
    {
      width: 120,
      field: 'priority',
      sortable: false,
      headerAlign: 'center',
      disableColumnMenu: false,
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Приоритет</strong>,
    },
    {
      field: 'actions',
      sortable: false,
      width: 100,
      cellClassName: 'centered-cell',
      menubar: false,
      renderCell: (params) => (
        <div>
          <Tooltip title='Редактировать' arrow>
            <Link to={`/storeCategory/update/${params.id}`} target='_blank'>
              <IconButton color='primary' aria-label='edit'>
                <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Link>
          </Tooltip>
          {userType === 'Админ' && (
            <Tooltip title='Удалить' arrow>
              <IconButton color='primary' aria-label='edit' onClick={() => event(params?.id)}>
                <DeleteRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
      renderHeader: () => <strong>Действия</strong>,
    }
  ]
};