import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Title } from '../../../../components/Title/Title'
import { useParams } from 'react-router-dom';
import { db } from '../../../../configs';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { citiesRef } from '../../../../utils/collectionRefs';
import { courierType } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { doc, getDocs, getDoc, updateDoc, } from "firebase/firestore";
import {
  MenuItem,
  TextField,
  Button,
  Box,
  Backdrop,
  CircularProgress,
  FormControl,
  Typography,
  Rating,
  Autocomplete,
} from '@mui/material';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import './scss/AddCourier.scss';
import { Helmet } from 'react-helmet';

const EditCourier = ({pageTitle}) => {
  const [city, setCity] = useState(null)
  const [courier, setCourier] = useState(null)
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: "onChange",
  })

  const fetchCities = async () => {
    try {
      const data = await getDocs(citiesRef);
      const cityData = data.docs.map((doc) => ({ ...doc.data() }));
      setCity(cityData);
    } catch (e) {
      console.error('Error fetching city data: ', e);
    }
  };

  useEffect(() => {
    const fetchCourier = async () => {
      try {
        const docRef = doc(db, 'couriers', `${id}`);
        const docSnap = await getDoc(docRef);
        const courierData = { ...docSnap.data(), id: docSnap.id };
        setCourier(courierData);
      } catch (e) {
        console.error('Error fetching courier data: ', e);
      }
    };
    fetchCourier();
    fetchCities();
  }, [id]);

  const pinValidationRules = {
    required: 'Обязательное полe!',
    validate: (value) => {
      if (value.length < 6) {
        return "ПИН должен быть больше 5 символов";
      }
      if (value.length > 6) {
        return "ПИН не должен быть больше 6 символов";
      }
    },
  };

  const handleEditCourier = async (data) => {
    try {
      setOpen(true);

      const updatedData = {
        ...data,
        city: String(data.cityInfo.id),
      };

      await updateDoc(doc(db, 'couriers', `${data.phone}`), updatedData);
      setOpen(false);
      navigate('/couriers');

      toast.success('Курьер успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });


      reset();
    } catch (e) {
      console.error('Error updating document: ', e);
      toast.error('Произошла ошибка при редактировании курьера', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
        <Header previous={'Cписок курьеров'} initial={'Редактирование курьера'} />
        <Title title={'Редактирование курьера'} icon={<NoteAltRoundedIcon fontSize='large' />} />
        <div className="container-inner">
          {
            !courier
              ? <Loader />
              : (
                <div className="addCourier-wrapper">
                  <div className='order-block-head'>
                    <h3>Редактирование курьера</h3>
                  </div>
                  <form className='add-courier-form'>
                    <Box className='add-courier-form__input'>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={courier?.name}
                        rules={{ required: 'Обьязательное полe!' }}
                        render={({ field }) => (
                          <TextField
                            label="Имя"
                            className="add-courier-input"
                            placeholder="Иван"
                            multiline
                            variant="outlined"
                            size="small"
                            helperText={errors?.name?.message}
                            error={errors?.name && true}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="surName"
                        control={control}
                        defaultValue={courier?.surName}
                        rules={{ required: 'Обьязательное полe!' }}
                        render={({ field }) => (
                          <TextField
                            className="add-courier-input"
                            label="Фамилия"
                            placeholder="Иванов"
                            variant="outlined"
                            size="small"
                            helperText={errors?.surName?.message}
                            error={errors?.surName && true}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Box className='add-courier-form__input'>
                      <Controller
                        name="pin"
                        control={control}
                        defaultValue={courier?.pin}
                        rules={pinValidationRules}
                        render={({ field }) => (
                          <TextField
                            className="add-courier-input"
                            label="ПИН"
                            placeholder="0101"
                            variant="outlined"
                            sx={{ flex: 1 }}
                            size="small"
                            type='text'
                            inputProps={{ inputMode: "numeric" }}
                            helperText={errors?.pin?.message}
                            error={errors?.pin && true}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue={courier?.phone}
                        rules={{
                          required: 'Обьязательное полe!',
                          pattern: {
                            value: /^996\d{3}\d{2}\d{2}\d{2}$/,
                            message: 'Неверный формат номера',
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            label="Моб-номер"
                            placeholder="(996)700777777"
                            variant="outlined"
                            size="small"
                            sx={{ flex: 1 }}
                            type="number"
                            className="add-courier-input"
                            helperText={errors?.phone?.message}
                            error={errors?.phone && true}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Box className='add-courier-form__input'>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={courier?.type}
                        rules={{ required: 'Обязательное поле!' }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="filled-select-currency"
                            select
                            label="Тип курьера"
                            variant="outlined"
                            className="add-courier-input"
                            size="small"
                            error={!!errors.type}
                            helperText={errors.type ? errors.type.message : ''}
                          >
                            {courierType.map((type) => (
                              <MenuItem key={type.id} value={type.title}>
                                {type.title}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      <Controller
                        name="cityInfo"
                        control={control}
                        defaultValue={courier?.cityInfo}
                        rules={{ required: 'Обязательное поле!' }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={city || []}
                            getOptionLabel={(option) => option?.name || ''}
                            onChange={(_, val) => { field.onChange(val) }}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Город/район"
                                variant="outlined"
                                size="small"
                                error={!!errors.cityInfo}
                                helperText={errors?.cityInfo?.message}
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <FormControl>
                      <Typography variant="body2" color="disabled">Рейтинг</Typography>
                      <Controller
                        name="raiting"
                        control={control}
                        defaultValue={parseFloat(courier?.raiting)}
                        rules={{ required: 'Обьязательное полe!' }}
                        render={({ field }) => (
                          <Rating
                            name="rating"
                            sx={{ width: '125px' }}
                            defaultValue={parseFloat(courier?.raiting)}
                            precision={0.5}
                            onChange={(event, newValue) => field.onChange(newValue)}
                          />
                        )}
                      />
                    </FormControl>
                    <Button
                      onClick={handleSubmit(data => handleEditCourier(data))}
                      size='large'
                      fullWidth
                      variant='contained'
                      style={{ background: '#000b1f' }}
                      sx={{ marginTop: "10px" }}
                    >
                      Сохранить
                    </Button>
                  </form>
                </div>
              )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  )
}

export default EditCourier