import { Button, Divider } from '@mui/material'
import React from 'react'
import './scss/OrderDetailBox.scss'

const OrderDetailBox = ({ title, items, orderStatus, setOpen2 }) => {
  return (
    <>
      <div className="orders-more-box address">
        <div className="orders-more-box-header">
          <h3>{title}</h3>
          {(title === 'Курьер 1' && (orderStatus === 'status_confirmed' || orderStatus === 'status_rejected')) && (
            <Button onClick={() => setOpen2(true)}>
              Сменить курьера
            </Button>
          )}
        </div>
        <Divider />
        <ul className="orders-more-body-list">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <li key={index}>
                <span>{item.label}</span>
                <span>{item.value}</span>
              </li>
              <Divider />
            </React.Fragment>
          ))}
        </ul>
      </div >
    </>
  )
}

export default OrderDetailBox