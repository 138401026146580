import React from "react";
import { Button } from "@mui/material";
import * as XLSX from 'xlsx';

export const ExportCouriersToExcelButton = ({ data, headers, fileName }) => {
  const exportToExcel = () => {
    const dataFormatter = (courier) => [
      courier.name,
      courier.surName,
      courier.phone,
      `${courier.raiting}`,
      `${courier?.balance}`,
      courier.cityInfo.name,
      !courier.active ? 'Не активен' : 'Активный',
      courier.type,
    ]
    
    const snapshot = data.map(dataFormatter);

    const finalData = [headers, ...snapshot];
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    ws['!cols'] = Array(finalData[0].length).fill({ wch: 18 });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Список курьеров');

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <Button variant='black-contained' onClick={exportToExcel}>
      Экспорт в Excel
    </Button>
  );
};
