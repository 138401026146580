import { statusClassMapForReturned, statusClassMap } from "../constants";

export const getStatusClass = (status) => {
  return `orders-card-values status_order ${statusClassMap[status] || ''}`;
};

export const getStatusClassForReturned= (status) => {
  return `orders-card-values status_order ${statusClassMapForReturned[status] || ''}`;
};

