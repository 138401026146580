import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteDoc, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { citiesRef, userCollectionRef, villageRef } from '../../../../utils/collectionRefs';
import { Loader } from '../../../../components/Loader/Loader';
import { Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { addToHistory } from './../../../../utils/addToHistory';
import { toast } from 'react-toastify';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const EditableListItem = ({ label, storeValue, districtId, onEdit, onDelete, roleType }) => (
  <ListItem>
    <ListItemText primary={label} secondary={''} />
    {districtId && (
      <>
        {roleType === 'admin' && (
          <>
            <IconButton onClick={() => onDelete({ id: districtId, name: label })}>
              <DeleteRoundedIcon />
            </IconButton>
            <IconButton onClick={onEdit}>
              <EditRoundedIcon />
            </IconButton>
          </>
        )}
      </>
    )}
  </ListItem>
)

const fetchDocument = async (ref, setState, setLoading) => {
  try {
    setLoading(true);
    const docSnap = await getDoc(ref);
    setState(docSnap.data());
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.error('Error fetching data:', error);
  }
};

const fetchDocuments = async (queryRef, setState, setLoading) => {
  try {
    setLoading(true);
    const querySnapshot = await getDocs(queryRef);
    const docsData = querySnapshot.docs.map(doc => doc.data());
    setState(docsData);
    setLoading(false);

  } catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
  }
};

const CityDetail = ({ pageTitle }) => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true);
  const [city, setCity] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate()

  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');


  useEffect(() => {
    const fetchCity = async () => {
      const cityRef = doc(citiesRef, id);
      await fetchDocument(cityRef, setCity, setIsLoading);
    };
    fetchCity();
  }, [id])

  useEffect(() => {
    const fetchDistrict = async () => {
      const districtQuery = query(villageRef, where('district', '==', Number(id)));
      await fetchDocuments(districtQuery, setDistricts, setIsLoading);
    };
    fetchDistrict();
  }, [id])

  const handleOpenDialog = (district) => {
    setSelectedDistrict(district);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDistrict(null);
  };

  const handleDeleteDistrict = async () => {
    if (!selectedDistrict?.id) return;
    const LOADING_MESSAGE = `Удаление региона/района...`;
    let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });
    const description = `${userType} ${userName} удалил регион/село "${selectedDistrict?.name}" из города "${city.name}" || ID региона: ${selectedDistrict?.id}`

    try {
      const ref = doc(villageRef, `${selectedDistrict?.id}`);
      await deleteDoc(ref);
      await addToHistory(userCollectionRef, description)
      toast.dismiss(loadingToastId);
      toast.update(loadingToastId, {
        render: 'Регион/район успешно удален!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      setDistricts(districts.filter(district => district.id !== selectedDistrict.id));
    } catch (error) {
      console.error('Error deleting district:', error);
      toast.update(loadingToastId, {
        render: 'Ошибка при удалении региона/района',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header initial='Детали города' previous="Спсиок городов" />
      <Title title="Детали магазина" icon={<StoreMallDirectoryRoundedIcon fontSize='large' />} />
      <div className="container-inner">
        <div style={{ display: 'flex', gap: '10px', }} >
          {isLoading ? <Loader /> : (
            <>
              <div className="store-container">
                <Typography variant="h6" component="div">
                  Наименования города
                </Typography>

                <Grid container spacing={0.25}>
                  <Grid item xs={12} sm={6}>
                    <List>
                      <EditableListItem
                        label={city?.name}
                        storeValue={''}
                        districtId={id}
                        onEdit={() => navigate(`/city/update/${id}`)}
                        onDelete={() => { }}
                      />
                    </List>
                  </Grid>
                </Grid>
              </div>
              <div className="store-container">
                <Typography variant="h6" component="div">
                  Регионы/село текущего города
                </Typography>
                <Grid container spacing={0.25}>
                  {districts?.map((district, index) => (
                    <Grid item xs={12} sm={6} key={district.id || index}>
                      <List>
                        <EditableListItem
                          type="district"
                          label={district.name}
                          storeValue={`ID: ${district.id}`}
                          districtId={district.id}
                          onEdit={() => navigate(`/village/update/${district.id}`)}
                          onDelete={handleOpenDialog}
                          roleType={roleType}
                        />
                      </List>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </>
          )}
        </div>
        <CustomDialog
          open={openDialog}
          onClose={handleCloseDialog}
          event={handleDeleteDistrict}
          text={`Вы уверены, что хотите удалить регион/село "${selectedDistrict?.name}"?`}
          btnText='Удалить'
        />
      </div>
    </div>
  )
}
export default CityDetail
