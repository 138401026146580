import React, { useEffect, useMemo, useState } from 'react';
import {
  doc,
  where,
  getDocs,
  getDoc,
  query,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Loader } from '../../../../components/Loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { db } from '../../../../configs';
import {
  statusLabels,
  packageTypeLabels,
  paymentMethods,
  typeOfOrder,
  orderStatus,
} from '../../../../utils/index';

import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import avatar from '../../../../assets/images/avatar.svg';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import { completedOrdersRef, orderRef } from '../../../../utils/collectionRefs';
import { toast } from 'react-toastify';
import OrderGrid from '../../../../components/OrderGrid ';
import { dateFormatter } from '../../../../utils/dateFormatter';
import copy from 'clipboard-copy';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import '../Couriers/scss/CourierDetail.scss';
import OrderSort from '../../../../components/OrderSort';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import { Helmet } from 'react-helmet';

const ClientDetail = ({ pageTitle }) => {
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [orders, setOrders] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [search, setSearch] = useState('');
  const [activeButtonVariant, setActiveButtonVariant] = useState('contained');
  const [completedButtonVariant, setCompletedButtonVariant] = useState('outlined');
  const navigate = useNavigate();

  // const getOrders = async (queryRef) => {
  //   try {
  //     const querySnapshot = await getDocs(queryRef);
  //     const ordersData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     setOrders(ordersData);
  //   } catch (error) {
  //     console.error("Error fetching orders:", error);
  //   }
  // };
  const getOrders = async (newQuery) => {
    try {
      const q = query(newQuery, where('sender', '==', id));
      const data = await getDocs(q);
      const newOrders = data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      setOrders(newOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    let q = query(
      orderRef,
      orderBy('dateCreated', 'desc'),
      where('sender', '==', id)
    );
    if (selectedDate && selectedDate2) {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate2);
      endOfDay.setHours(0, 0, 0, 0);
      q = query(
        q,
        where('dateCreated', '>=', startOfDay),
        where('dateCreated', '<=', endOfDay)
      );
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newOrders = snapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      setOrders(newOrders);
    });

    return unsubscribe;
  }, [selectedDate, selectedDate2]);

  const sortOrderBy = (field, value) => {
    const sortQuery =
      value === 'Все' ? orderRef : query(orderRef, where(field, '==', value));
    getOrders(sortQuery);
  };

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleDateChange2 = (e) => setSelectedDate2(e.target.value);

  const orderTypeSort = (event) =>
    sortOrderBy('packageType', event.target.value);
  const orderStatusSort = (event) => sortOrderBy('status', event.target.value);

  useEffect(() => {
    const getClient = async () => {
      try {
        const docRef = doc(db, 'clients', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const clientData = docSnap.data();
          setClient({ ...clientData, id: docSnap.id });
        } else {
          console.error("Document doesn't exist");
        }
      } catch (error) {
        console.error('Error fetching client:', error);
      }
    };
    getClient();
  }, [id]);

  const handleActiveOrders = () => {
    setActiveButtonVariant('contained');
    setCompletedButtonVariant('text');
    getOrders(
      query(orderRef, orderBy('dateCreated', 'desc'), where('sender', '==', id))
    );
  };

  const handleCompletedOrders = () => {
    setActiveButtonVariant('text');
    setCompletedButtonVariant('contained');
    getOrders(
      query(
        completedOrdersRef,
        orderBy('dateCreated', 'desc'),
        where('sender', '==', id)
      )
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'status_new':
        return 'orders-card-values status_order new-order';
      case 'status_confirmed':
        return 'orders-card-values status_order confirmed-order';
      case 'status_arrived_sender':
        return 'orders-card-values status_order arrived-sender-order';
      case 'status_on_courier':
        return 'orders-card-values status_order on-courier-order';
      case 'status_at_sorting_center':
      case 'status_at_sorting_center2':
        return 'orders-card-values status_order at-sorting-order';
      case 'status_on_way_cc':
      case 'status_on_courier2':
        return 'orders-card-values status_order on-way';
      case 'status_arrived_receiver':
        return 'orders-card-values status_order arrived-receiver-order';
      case 'status_delivered':
        return 'orders-card-values status_order delivered-order';
      case 'status_rejected':
        return 'orders-card-values status_order rejected-order';
      case 'status_cancelled':
        return 'orders-card-values status_order cancelled-order';
      default:
        return '';
    }
  };

  const handleCopyOrder = async (params) => {
    const {
      senderName,
      senderPhone,
      addressFrom,
      receiverName,
      receiverPhone,
      addressTo,
      dateCreated,
      status,
      packageType,
      tariff,
      whoPays,
      paymentMethod,
      paymentStatus,
      cost,
      redemption,
      comments,
    } = params?.row;

    const sendData = {
      Отправитель: {
        'Имя отправителя': senderName,
        'Телефон отправителя': senderPhone,
        Город: addressFrom?.cityName || null,
        'Район/Село': addressFrom?.districtName || null,
        Адрес: addressFrom?.address,
      },
      Получатель: {
        'Имя получателя': receiverName,
        'Телефон получателя': receiverPhone,
        Город: addressTo?.cityName || null,
        'Район/Село': addressTo?.districtName || null,
        Адрес: addressTo?.address,
      },
      'Детали заказа': {
        ID: params.id,
        PID: params?.pid || null,
        'Дата создания': dateFormatter(dateCreated?.toDate()),
        'Статус заказа': statusLabels[status],
        'Тип посылки': packageTypeLabels[packageType],
        'Тариф заказа': tariff?.name,
        'Платежное лицо': (() => {
          switch (whoPays) {
            case 1:
              return 'Отправитель';
            case 2:
              return 'Получатель';
            default:
              return 'Совместно';
          }
        })(),
        'Метод оплаты': paymentMethods[paymentMethod],
        'Статус оплаты': !paymentStatus ? 'Не оплачен' : 'Оплачен',
        Цена: !cost ? tariff?.cost : cost,
        Выкуп: redemption,
        Комментарии: comments,
      },
    };
    const resultString = JSON.stringify(sendData, null, 2);
    try {
      copy(resultString);
      toast.success('Данные успешно скопированы', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      console.error(error);
      toast.error('Не удалось скопировать данные в буфер обмена:', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const calculateTotalBalance = () => {
    if (!orders) return 0;
    const positiveBalances = orders.map((order) => Math.abs(order?.cost || 0));
    return positiveBalances.reduce((total, balance) => total + balance, 0);
  };

  const calculateTotalRedemption = () => {
    if (!orders) return 0;
    const positiveBalances = orders.map((order) =>
      Math.abs(order?.redemption || 0)
    );
    return positiveBalances.reduce((total, balance) => total + balance, 0);
  };

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredOrder = useMemo(
    () =>
      orders?.filter((order) => {
        const { id, pid, receiver } = order;

        return (
          (id && id.toLowerCase().includes(search)) ||
          (pid && pid.toLowerCase().includes(search)) ||
          (receiver && receiver.toLowerCase().includes(search))
        );
      }),
    [orders, search]
  );

  const userType = localStorage.getItem('userType');

  const ActionIconButton = ({ title, icon, onClick }) => (
    <Tooltip title={title} arrow>
      <IconButton color='primary' aria-label='edit' onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );

  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/update/${params.id}`),
      visible: userType !== false,
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        <Tooltip title='Детали' arrow >
          <Link
            to={
              activeButtonVariant === 'contained'
                ? `/order/details/${params.id}`
                : `/order/completed/details/${params.id}`
            }
            target='_blank'
          >
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip >
        {actionsConfig.map((action, index) => (
          action.visible !== false(
            <ActionIconButton
              key={index}
              title={action.title}
              icon={action.icon}
              onClick={() => action.handler(params)}
            />
          )
        ))}
      </div>
    ),
    renderHeader: () => <strong>Действия</strong>,
  };

  const columns = [
    {
      width: 140,
      field: 'dateCreated',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params.row.dateCreated.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата</strong>,
      disableColumnMenu: false,
    },
    {
      width: 170,
      field: 'pid',
      sortable: false,
      renderHeader: () => <strong>Номер заказа</strong>,
    },
    {
      width: 120,
      field: 'courierOne',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierOne ? 'Отсутствует' : params.row.courierOne}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 120,
      field: 'courierTwo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierTwo ? 'Отствутсвует' : params.row.courierTwo}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер 2</strong>,
    },
    {
      width: 150,
      field: 'status',
      sortable: false,
      headerAlign: 'center',
      editable: false,
      // type: 'singleSelect',
      // valueOptions: orderStatus.map((status) => status.name),
      renderCell: (params) => {
        const statusClassName = getStatusClass(params.row.status);
        return (
          <strong className={statusClassName}>
            {statusLabels[params.row.status]}
          </strong>
        );
      },
      renderHeader: () => <strong>Статус</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'packageType',
      sortable: false,
      renderCell: (params) => {
        return <div>{packageTypeLabels[params.row.packageType]}</div>;
      },
      renderHeader: () => <strong>Тип товара</strong>,
    },
    {
      width: 145,
      field: 'addressFrom',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values country'>
            {params.row.addressFrom.cityName}
          </div>
        );
      },
      renderHeader: () => <strong>Откуда</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 156,
      field: 'sender',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.senderName}</span>
            <span> {params.row.sender}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 145,
      field: 'addressTo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values country'>
            {params.row.addressTo.cityName}
          </div>
        );
      },
      renderHeader: () => <strong>Куда</strong>,
    },
    {
      width: 154,
      field: 'receiver',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.receiverName}</span>
            <span> {params.row.receiver}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 80,
      field: 'cost',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.cost}с</div>;
      },
      renderHeader: () => <strong>Сумма</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'redemption',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.redemption}с</div>;
      },
      renderHeader: () => <strong>Выкуп</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'whoPays',
      sortable: false,
      renderCell: (params) => {
        let whoPaysText;
        switch (params.row.whoPays) {
          case 1:
            whoPaysText = 'Отправитель';
            break;
          case 2:
            whoPaysText = 'Получатель';
            break;
          default:
            whoPaysText = 'Совместно';
        }
        return <div>{whoPaysText}</div>;
      },
      renderHeader: () => <strong>Кто оплачивает</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'senderPays',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.senderPays === null ? '0.0' : params?.row?.senderPays}
          </div>
        );
      },
      renderHeader: () => <strong>SenderPays</strong>,
      cellClassName: 'centered-cell',
    },
    actionsColumn,
  ];
  // if (!client) return <Loader />
  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous={'Список клиентов'} initial={'Детали клиента'} />
      <Title
        title={'Детали клиента'}
        icon={<AssignmentIndRoundedIcon fontSize='large' />}
      />
      {!client ? (
        <Loader />
      ) : (
        <div className='container-inner'>
          <div className='courier-more-wrapper'>
            <div className='courier-more-card сlient'>
              <div className='courier-card-left-side'>
                <div className='courier-card-avatar'>
                  <img
                    src={!client?.avatar ? avatar : client?.avatar}
                    alt={client?.fullName}
                  />
                </div>
                <h3>{client?.fullName}</h3>
              </div>
              <div className='courier-card-right-side'>
                <div className='courier-card-list'>
                  <span>
                    <p>Имя:</p>
                    <p>{client?.fullName}</p>
                  </span>
                  <span>
                    <p>Номер:</p>
                    <p>{client?.phone}</p>
                  </span>
                  <span>
                    <p>Город:</p>
                    <p>{client?.address?.cityName ?? 'Нет данных'}</p>
                  </span>
                </div>
                <div className='courier-card-list client'>
                  <span>
                    <p>Район:</p>
                    <p>{client?.address?.districtName ?? 'Нет данных'}</p>
                  </span>
                  <span
                    className={
                      !client?.blackList ? 'notBlackList' : 'blackList'
                    }
                  >
                    <p>Статус:</p>
                    <p>
                      {!client?.blackList
                        ? 'Не в черном списке'
                        : 'В черном списке !'}
                    </p>
                  </span>
                </div>
                <div className='courier-card-list'>
                  <Box display='flex' gap={'4px'}>
                    <Button
                      variant={activeButtonVariant}
                      className={
                        activeButtonVariant === 'contained'
                          ? 'courier-more-btn contained'
                          : 'courier-more-btn outlined'
                      }
                      onClick={handleActiveOrders}
                    >
                      Активные заказы
                    </Button>
                    <Button
                      variant={completedButtonVariant}
                      className={
                        completedButtonVariant === 'contained'
                          ? 'courier-more-btn contained'
                          : 'courier-more-btn outlined'
                      }
                      onClick={handleCompletedOrders}
                    >
                      Завершенные заказы
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
          <div className='order-statistics-container client'>
            <BalanceCard
              title='Общее количество заказов'
              value={!orders ? 0 : orders?.length}
              icon={ListAltRoundedIcon}
              iconColor='#850e90'
              borderColor='#850e90'
            />
            <BalanceCard
              title='Общая сумма заказов'
              value={Math.abs(calculateTotalBalance())}
              icon={MonetizationOnRoundedIcon}
              iconColor='#10B981'
              borderColor='#10B981'
            />
            <BalanceCard
              title='Общая сумма выкупов'
              value={Math.abs(calculateTotalRedemption())}
              icon={CurrencyExchangeRoundedIcon}
              iconColor='#3A46D6'
              borderColor='#3A46D6'
            />
          </div>
          <div className='orders-sorting-header'>
            <Stack
              sx={{ width: '100%', flexWrap: 'wrap' }}
              direction='row'
              gap={2}
            >
              <SearchInput
                label='Поиск'
                placeholder='PID заказа, получатель'
                variant='standard'
                onChange={handleSearchOrder}
              />
              <OrderSort
                sortKey='По типу'
                defaultValue='Все'
                options={typeOfOrder}
                onChange={orderTypeSort}
              />
              <OrderSort
                sortKey='По статусу'
                defaultValue='Все'
                options={orderStatus}
                onChange={orderStatusSort}
              />
              <div className='order-sort order-sort-date'>
                <label htmlFor='orderDate' className='orders-sort-date-label'>
                  От
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate'
                    id='orderDate'
                    value={selectedDate || ''}
                    onChange={handleDateChange}
                  />
                </label>
                <span></span>
                <label htmlFor='orderDate2' className='orders-sort-date-label'>
                  До
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate2'
                    id='orderDate2'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                  />
                </label>
              </div>
            </Stack>
          </div>
          <div
            className='orders-card-wrapperr'
            style={{ background: 'white', marginTop: '20px' }}
          >
            <OrderGrid
              orders={filteredOrder || orders}
              columns={columns}
              size={20}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDetail;
