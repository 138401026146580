import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const OrderEditModal = ({ isOpen, onClose, onSave, defaultValue, editingField = 'заказа' }) => {
  const [editedValue, setEditedValue] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setEditedValue(defaultValue || '');
    }
  }, [isOpen, defaultValue]);

  const handleChange = (event) => {
    let value = event.target.value
    if (!isNaN(value)) {
      value = Number(value)
    }
    setEditedValue(value);
  };

  const handleSave = () => {
    onSave(editedValue);
    onClose()
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className='returned-order' fullWidth>
      <DialogTitle>Редактирование {editingField}</DialogTitle>
      <form>
        <DialogContent>
          <TextField
            defaultValue={defaultValue}
            onChange={handleChange}
            name={`random-${Math.random().toString(36).substring(7)}`}
            label='Введите значение'
            variant='outlined'
            multiline
            maxRows={4}
            size='small'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ color: '#000b1f' }}>
            Отмена
          </Button>
          <Button onClick={handleSave} color='info'>
            Сохранить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OrderEditModal;
