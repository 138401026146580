import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { storesRef } from '../../../../utils/collectionRefs';
import { Loader } from '../../../../components/Loader/Loader';
import './scss/StoreDetail.scss'
import { Avatar, Box, Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { russianDaysOfWeek } from './constants/formData';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { addToHistory } from './../../../../utils/addToHistory';
import { toast } from 'react-toastify';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import { db } from '../../../../configs';

const EditableListItem = ({ label, storeValue }) => (
  <ListItem>
    <ListItemText primary={label} secondary={storeValue} />
  </ListItem>
);

const fetchData = async (ref, setState, setLoading) => {
  try {
    setLoading(true);
    const docSnap = await getDoc(ref);
    setState(docSnap.data());
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
  }
};


const StoreDetail = ({ pageTitle }) => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate()

  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');

  const workingDays = store?.workingDays
    ? store.workingDays.map(day => russianDaysOfWeek[day]).join(' - ')
    : 'Нет данных';

  useEffect(() => {
    const fetchStore = async () => {
      const storeRef = doc(storesRef, id);
      await fetchData(storeRef, setStore, setIsLoading);
    };
    fetchStore();
  }, [id]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };


  const handleDeleteStore = async () => {
    const ref = doc(storesRef, id)
    const userCollectionRef = collection(db, `${roleType}`, `${userName}`, 'history')
    const description = `${userType} ${userName} удалил магазин с названием "${store?.nameRu || store?.nameKg}"`
    try {
      await deleteDoc(ref)
      await addToHistory(userCollectionRef, description)

      toast.success('Магазин успешно удален', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      navigate('/stores');
    } catch (error) {
      console.error('Error deleting store');
      toast.error('Произошла ошибка при удалении магазина', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  }

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header initial='Детали магазина' previous="Список магазинов" />
      <Title title="Детали магазина" icon={<StoreMallDirectoryRoundedIcon fontSize='large' />} />
      <div className="container-inner">
        {isLoading ? <Loader /> : (
          <>
            <Box display='flex' justifyContent='center' mb={2}>
              <Avatar
                src={store?.logo}
                alt={store?.nameKg}
                variant='rounded'
                sx={{
                  width: 200,
                  height: 120,
                  boxShadow: 'rgb(0 0 0 / 8 %) 0px 1px 2px',
                  border: '1px solid #00000014',
                }}
              />
            </Box>
            <div className="store-container">
              <Typography variant="h6" component="div">
                Наименования магазина
              </Typography>

              <Grid container spacing={0.25}>
                <Grid item xs={12} sm={6}>
                  <List>
                    {['nameKg', 'nameRu', 'nameEn'].map((field, index) => (
                      <EditableListItem
                        key={field}
                        label={`Название на ${field.toUpperCase().slice(-2)}`}
                        storeValue={store?.[field]}
                      />
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {['descKg', 'descRu', 'descEn'].map((field, index) => (
                    <EditableListItem
                      key={field}
                      name={field}
                      label={`Описание на ${field.toUpperCase().slice(-2)}`}
                      storeValue={store[field]}
                    />
                  ))}
                </Grid>
              </Grid>
            </div>
            <div className="store-container">
              <Typography variant="h6" component="div">
                Информация о магазине
              </Typography>
              <Grid container spacing={0.25}>
                <Grid item xs={12} sm={6}>
                  <List>
                    <ListItem >
                      <ListItemAvatar>
                        <Avatar>
                          <LocationOnRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Адрес'}
                        secondary={store?.address}
                      />
                    </ListItem>
                  </List>

                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CategoryRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Категории'}
                        secondary={!store?.categoryNames ? 'Нет категории' : store?.categoryNames?.join(', ')}
                      />
                    </ListItem>
                  </List>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CalendarTodayRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Дни'}
                        secondary={workingDays}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Divider orientation='vertical' flexItem absolute />
                <Grid item xs={12} sm={6}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <LocalPhoneRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary='Телефон' secondary={store?.phones} />
                    </ListItem>
                  </List>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <LinkRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={'Instagram'}
                        secondary={
                          <a
                            href={store?.insta}
                            target='_blank'
                            rel="noreferrer"
                            style={{ display: 'block', wordWrap: 'break-word' }}
                          >
                            {store?.insta}
                          </a>
                        }
                      />
                    </ListItem>
                  </List>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <AccessTimeFilledRoundedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={'Время'} secondary={store?.workingHours} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>

            </div>
          </>
        )}

        <Stack direction='row' className="orders-more-box actions">
          {userType === 'Админ' && (
            <>
              <Button variant='black-contained' onClick={handleClickOpen} disabled={isLoading} fullWidth>
                Удалить
              </Button>
              <Button variant='black-contained' onClick={() => navigate(`/store/update/${id}`)} fullWidth>
                Редактировать
              </Button>
            </>
          )}
        </Stack>
        <CustomDialog
          open={open}
          onClose={handleClickClose}
          event={() => handleDeleteStore()}
          text='Вы уверены что хотите удалить магазин?'
          btnText='Удалить'
        />
      </div>
    </div>
  )
}

export default StoreDetail