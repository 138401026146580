import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { userCollectionRef } from '../../../../utils/collectionRefs'
import { addDoc } from 'firebase/firestore'
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { toast } from 'react-toastify'
import { Loader } from '../../../../components/Loader/Loader'
import CustomDialog from '../../../../components/CustomDialog/CustomDialog'
import OrderGrid from '../../../../components/OrderGrid '
import { kassaTransactionColumns } from './../KassIn/constants/getKassaTransactionColumns';
import axios from 'axios'
import dayjs from 'dayjs'
import { getAuthToken } from '../../../Auth/Login/authToken'
import { useDetailKassa } from './helpers/useDetailKassa';

const KassaDetails = ({ pageTitle }) => {
  const { id } = useParams()
  const { kassa, loading } = useDetailKassa(id)
  const [kassaTransaction, setKassaTransaction] = useState([])
  const [types, setTypes] = useState([])
  const [operation, setOperation] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [kassLoading, setKassaLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({});
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const navigate = useNavigate()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onChange' });



  useEffect(() => {
    const getKassaTypes = async () => {
      try {
        const response = await axios.get(`https://aylex.ru/api/core/type/`);

        if (response.status === 200) {
          setTypes(response.data.results);
        } else {
          console.error('Не удалось получить типы', response.status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getKassaTypes();
  }, []);

  useEffect(() => {
    const getKassaTransactions = async () => {
      setKassaLoading(true)
      try {
        const filters = {
          kassa: id,
          ...Object.keys(selectedFilters).reduce((acc, field) => {
            const value = selectedFilters[field];
            if (value !== 'Все' && value !== '') {
              acc[field] = value;
            }
            return acc;
          }, {}),
        };
        const token = await getAuthToken();
        const response = await axios.get(`https://aylex.ru/api/core/kassaTransaction/`, {
          params: filters,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setKassaTransaction(response.data.results);
          setKassaLoading(false)
        } else {
          console.error('Не удалось получить данные транзакций', response.status);
        }
      } catch (error) {
        setKassaLoading(false)
        console.error(error);
      }
    };

    getKassaTransactions();
  }, [selectedFilters, id]);

  const handleDeleteKassa = async () => {
    const userHistoryData = {
      date: new Date(),
      description: `${userType} ${userName} удалил кассу с названием ${kassa?.name}`,
    };

    try {
      const response = await axios.delete(`https://aylex.ru/api/core/kassa/${id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.status === 204) {
        toast.success('Касса успешно была удалена', {
          autoClose: 2000,
          hideProgressBar: false,
        });

        await addDoc(userCollectionRef, userHistoryData);

        navigate('/kassa');
      } else {
        throw new Error('Не удалось удалить кассу');
      }
    } catch (error) {
      toast.error('Произошла ошибка при удалении кассы', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      console.error('Error deleting kassa:', error);
    }
  };

  const onSubmitKassaTransaction = async (data) => {
    let loadingToastId;
    const newAmount = parseInt(data.summa) * (operation === 'subtract' ? -1 : 1);
    const newBalanceOfKassa = { balance: (kassa?.balance || 0) + newAmount, }
    const fullDescription = `${userType} ${userName} отредактировал баланс кассы`;

    const kassaTransactionData = {
      summa: parseInt(newAmount),
      type: Number(data?.type),
      comment: data.comment ?? '',
      kassa: Number(id),
      dateCreated: new Date(),
      author: `${roleType} ${userName}`,
      balanceBefore: kassa?.balance || 0,
      balanceAfter: (kassa?.balance || 0) + newAmount,
    };

    try {
      handleClose();
      loadingToastId = toast.loading('Изменение баланса кассы', {
        autoClose: true,
      });

      const token = localStorage.getItem('authToken')

      await axios.patch(`https://aylex.ru/api/core/kassa/${id}/`, { balance: newBalanceOfKassa.balance },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Баланс кассы успешно обновлен');
      toast.dismiss(loadingToastId);

      loadingToastId = toast.loading(`Добавление транзакции кассы...`, {
        autoClose: true,
      });
      await axios.post('https://aylex.ru/api/core/kassaTransaction/', kassaTransactionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Транзакция кассы успешно добавлена');
      toast.dismiss(loadingToastId);

      await addDoc(userCollectionRef, {
        date: new Date(),
        description: fullDescription
      })

      reset();
    } catch (error) {
      console.error('Error adding transaction: ', error);
      toast.error('Произошла ошибка!', {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };


  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event?.target?.value);
  }

  const kassaTypeToSort = createOrderSortHandler('type');

  const handleOpen = (op) => {
    setOperation(op);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };
  const handleClickClose = () => {
    setOpenDeleteModal(false);
  };


  const columns = kassaTransactionColumns()

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Список касс' initial='Детали кассы' />
      <Title title='Детали кассы' icon={<AssignmentIndRoundedIcon fontSize='large' />} />
      <div className="container-inner">
        {loading ? <Loader /> : (
          <div className="courier-more-wrapper">
            <div className="courier-more-card сlient">
              <div className='courier-card-right-side'>
                <div className='courier-card-list client'>
                  <span>
                    <p>Название:</p>
                    <p>{kassa?.name}</p>
                  </span>
                  <span>
                    <p>Дата создания:</p>
                    <p>{dayjs(kassa?.dateCreated).format('DD/MM/YYYY')}</p>
                  </span>
                  <span>
                    <p>Комментарии:</p>
                    <p>{kassa?.comment}</p>
                  </span>
                </div>
                <div className='courier-card-list client'>
                  <span>
                    <p>Баланс:</p>
                    <p>{Math.floor(kassa?.balance).toLocaleString()}c</p>
                  </span>
                </div>
                <div className='courier-card-list client'>
                  <Box display='flex' gap='7px' className='courier-button-list'>
                    {userType === 'Админ' && (
                      <>
                        <Button
                          variant='black-contained'
                          onClick={() => navigate(`/courier/update/${kassa?.id}`)}
                        >
                          <EditRoundedIcon />
                        </Button>
                        <Button
                          variant='black-contained'
                          onClick={() => setOpenDeleteModal(true)}
                        >
                          <DeleteRoundedIcon />
                        </Button>
                      </>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <div className='courier-avatar-card'>
              <div className='courier-balance-card courier-balance-card__two'>
                <div className='courier-balance-card-title'>
                  <MonetizationOnRoundedIcon style={{ color: '#10B981' }} />
                  <p>Balance</p>
                </div>
                <div className='courier-balance-card-data'>
                  <p>{(kassa?.balance ?? 0).toLocaleString()}с</p>
                </div>
                <div className='courier-balance-btn-container'>
                  <Button
                    variant='contained'
                    size='md'
                    className='courier-more-btn'
                    onClick={() => handleOpen('add')}
                    disabled={!kassa}
                  >
                    <AddRoundedIcon />
                  </Button>
                  <Button
                    variant='contained'
                    size='md'
                    className='courier-more-btn'
                    onClick={() => handleOpen('subtract')}
                    disabled={!kassa}
                  >
                    <RemoveRoundedIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="orders-sorting-header" style={{ marginTop: '20px' }}>
          <Stack style={{ width: '200px' }}>
            <div className='order-sort'>
              <TextField
                id='outlined-select-currency'
                select
                label='По типу'
                fullWidth
                defaultValue=''
                size='small'
                onChange={kassaTypeToSort}
              >
                <MenuItem value='Все'>Все</MenuItem>
                {types?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Stack>
        </div>
        <div className='orders-card-wrapper' style={{ background: 'white', marginTop: '20px' }}>
          <div className='orders-card-header'>
            {/* <ExportToExcel
              firestoreRef={tabIndex === 0 ? kassaInRef : kassaOutRef}
              selectedFilters={{ kassa: kassa?.uid, ...selectedFilters }}
              headers={headersForTransactionKassa}
              selectedDate={''}
              selectedDate2={''}
              fileName={`Список ${tabIndex === 0 ? 'приходов ' : 'расходов'} касс`}
              dateField="date"
              dataFormatter={kassaTransactionExcelDataFormatter}
            /> */}

          </div>

          {kassLoading
            ? <Loader />
            : <OrderGrid
              orders={kassaTransaction || []}
              columns={columns}
              size={20}
              usePagination={false}
            />}

        </div>


        <Dialog open={openModal} onClose={handleClose} maxWidth='xs'>
          <DialogTitle>
            Изменение баланса {operation === 'subtract' ? '(минус баланс)' : '(плюс баланс)'}
          </DialogTitle>
          <form>
            <DialogContent>
              <Controller
                name='summa'
                control={control}
                defaultValue=''
                rules={{ required: true, min: 1 }}
                render={({ field }) => (
                  <TextField
                    autoFocus
                    margin='dense'
                    id='summa'
                    label='Сумма'
                    type='number'
                    fullWidth
                    error={!!errors.summa}
                    {...field}
                  />
                )}
              />
              <Controller
                name='comment'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    margin='dense'
                    id='comment'
                    label='Описание'
                    fullWidth
                    {...field}
                  />
                )}
              />
              <Controller
                name='type'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    id='outlined-select-currency'
                    select
                    margin='dense'
                    label='Тип'
                    fullWidth
                    {...field}
                  >
                    {types.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ color: '#000b1f' }} variant='text'>
                Отмена
              </Button>
              <Button
                type='submit'
                variant='text'
                sx={{ color: '#000b1f' }}
                onClick={handleSubmit(onSubmitKassaTransaction)}
              >
                {operation === 'subtract' ? 'Списать' : 'Добавить'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <CustomDialog
          open={openDeleteModal}
          onClose={handleClickClose}
          event={() => handleDeleteKassa()}
          text='Вы уверены что хотите удалить кассу?'
          btnText='Удалить'
        />
      </div>
    </div>
  )
}

export default KassaDetails