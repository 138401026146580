import React from 'react';
import { useSpring, animated } from 'react-spring';
import './BalanceCard.scss';

const AnimatedNumber = ({ value }) => {
  const animatedProps = useSpring({
    number: parseInt(value),
    from: { number: 0 },
    delay: 300,
    config: { duration: 700 },
  });

  return (
    <animated.p>
      {animatedProps.number.to((val) =>
        Math.floor(val).toLocaleString()
      )}
    </animated.p>
  );
};

const BalanceCard = ({ title, value, icon: Icon, iconColor }) => {
  const borderStyle = { borderTop: `4px solid ${iconColor}` };

  return (
    <div className='courier-balance-card' style={borderStyle}>
      <div className='courier-balance-card-title'>
        <Icon style={{ color: iconColor }} />
        <p>{title}</p>
      </div>
      <div className='courier-balance-card-data'>
        <AnimatedNumber value={value || 0} />
      </div>
    </div>
  );
};

export default BalanceCard;
