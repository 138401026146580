import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { storeCategorysRef } from '../../../../utils/collectionRefs';
import { uploadImage } from './helpers/uploadImage';
import { Button, TextField, Box, Backdrop, CircularProgress, Grid, Typography, Tooltip, Avatar } from '@mui/material'
import { toast } from 'react-toastify';
import {  collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { addToHistory } from '../../../../utils/addToHistory';
import { db } from '../../../../configs';
import { RequiredInput } from '../../../../components/Form/FormValidation';
import { storeDetalFormData } from './constants/formData';
import { Loader } from '../../../../components/Loader/Loader';

const fetchData = async (ref, setState, setLoading) => {
  try {
    setLoading(true);
    const docSnap = await getDoc(ref);
    setState(docSnap.data());
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
  }
};

const StoreCategoryEdit = ({ pageTitle }) => {
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [newImage, setNewImage] = useState(null)
  const inputFileRef = useRef(null);

  const { id } = useParams()

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
  })


  useEffect(() => {
    const fetchStore = async () => {
      const storeRef = doc(storeCategorysRef, id);
      await fetchData(storeRef, setCategory, setIsLoading);
    };
    fetchStore();
  }, [id]);

  const handlePostStore = async (store) => {
    const userCollectionRef = collection(db, `${roleType}`, `${userName}`, 'history');
    const storessRef = doc(storeCategorysRef, id);
    const description = `${userType} ${userName} отредактировал категорию с названием "${store.nameRu || store.nameKg}"`


    try {
      setOpen(!open);

      let imgUrl = category?.icon
      if (newImage instanceof File) {
        imgUrl = await uploadImage(newImage, newImage.name);
      }
      const storeData = {
        nameKg: store.nameKg,
        nameRu: store.nameRu,
        nameEn: store.nameEn,
        priority: parseFloat(store?.priority),
        icon: imgUrl ?? store?.icon,
      }
      await updateDoc(storessRef, storeData);

      await addToHistory(userCollectionRef, description)

      toast.success('Категория магазина успешно отредактирована');
      navigate('/storeCategories');
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка при редактировании категории');
    } finally {
      setOpen(false)
    }
  }

  const handleImageClick = () => {
    inputFileRef.current.click();
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };
  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header initial='Редактирование категории' previous='Статистика' />
      <Title title='Редактирование категории' icon={<AddCircleIcon fontSize='large ' />} />
      <div className="container-inner">
        {isLoading ? <Loader /> : (
          <div className="order-form">
            <form className="order-form-flex">
              <div className='order-block'>
                <div className='order-block-head'>
                  <h3>Детали категории магазина</h3>
                </div>
                <div className='order-input-block'>
                  <Grid container spacing={0.75}>
                    {storeDetalFormData[0].slice(0, 3).map((field) => (
                      <Grid item xs={4} key={field}>
                        <TextField
                          label={field.startsWith('name') ? `Название на ${field.slice(-2).toUpperCase()}` : `Описание на ${field.slice(-2).toUpperCase()}`}
                          variant='outlined'
                          size='small'
                          fullWidth
                          defaultValue={category?.[field]}
                          helperText={errors[field]?.message}
                          error={!!errors[field]}
                          {...register(field, RequiredInput)}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <TextField
                    id='outlined-basic'
                    label='Приоритет'
                    variant='outlined'
                    size='small'
                    type='number'
                    fullWidth
                    defaultValue={parseFloat(category?.priority)}
                    error={errors?.priority ? true : false}
                    helperText={errors ? errors?.priority?.message : ''}
                    {...register('priority', RequiredInput.required)}
                  />
                  <input
                    size='small'
                    type="file"
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    gap={1}
                    mb={2}
                  >
                    <Typography variant='p'>Фото категории:</Typography>
                    <Tooltip title="Нажмите, чтобы изменить изображение" arrow>
                      <Avatar
                        src={newImage ? URL.createObjectURL(newImage) : category?.icon}
                        alt={category?.nameKg}
                        variant='rounded'
                        onClick={handleImageClick}
                        sx={{
                          width: 150,
                          height: 120,
                          boxShadow: 'rgb(0 0 0 / 8 %) 0px 1px 2px',
                          border: '1px solid #00000014',
                          cursor: 'pointer'
                        }}
                      />
                    </Tooltip>
                  </Box>
                </div>

              </div>
            </form>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
              <Button
                onClick={handleSubmit((data) => handlePostStore(data))}
                size='large'
                variant='red-contained'
                color='primary'
              >
                Сохранить
              </Button>
            </Box>
          </div>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </div>
  )
}

export default StoreCategoryEdit 