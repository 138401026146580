import axios from "axios";

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Refresh токен отсутствует');
    }

    const response = await axios.post('https://aylex.ru/api/token/refresh/', {
      refresh: refreshToken,
    });
    if (response.status === 200 && response.data.access) {
      localStorage.setItem('authToken', response.data.access);
      return response.data.access;
    } else {
      throw new Error('Не удалось обновить токен');
    }
  } catch (error) {
    console.error('Ошибка обновления токена:', error);
    throw error;
  }
};


export const getAuthToken = async () => {
  const token = localStorage.getItem('authToken');

  if (!token || isTokenExpired(token)) {
    return await refreshToken();
  }
  return token;
};

const isTokenExpired = (token) => {
  const decoded = JSON.parse(atob(token.split('.')[1])); // Декодирование JWT
  return decoded.exp * 1000 < Date.now();
};
