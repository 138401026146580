import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, TextField, Box, Backdrop, CircularProgress, Autocomplete, Stack, Grid, FormGroup, FormControlLabel, Checkbox, Chip } from '@mui/material'
import { toast } from 'react-toastify';
import { collection, doc, getDocs, orderBy, query, setDoc } from 'firebase/firestore'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { storeCategorysRef, storesRef } from '../../../../utils/collectionRefs';
import { Title } from '../../../../components/Title/Title';
import { db } from '../../../../configs';
import { RequiredInput } from '../../../../components/Form/FormValidation';
import { russianDaysOfWeek, storeDetalFormData } from './constants/formData';
import { uploadImage } from './helpers/uploadImage';
import { addToHistory } from '../../../../utils/addToHistory';

const StoreAdd = ({ pageTitle }) => {
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([])
  const navigate = useNavigate()

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onChange',
  })

  const fetchCategories = useCallback(async () => {
    try {
      const q = query(storeCategorysRef, orderBy('priority', 'asc'));
      const faqDocs = await getDocs(q);
      const newFaqs = faqDocs.docs.map(item => ({
        ...item.data(),
        id: item.id,
      }));
      setCategories(newFaqs);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  }, []);

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  const handlePostStore = async (store) => {
    const userCollectionRef = collection(db, `${roleType}`, `${userName}`, 'history');
    const storessRef = doc(storesRef, `${Date.now()}`);
    const description = `${userType} ${userName} добавил магазин с названием "${store.nameRu || store.nameKg}"`

    try {
      setOpen(!open);

      let imgUrl = ''
      if (store.photo[0] instanceof File) {
        imgUrl = await uploadImage(store.photo[0], store.title);
      }

      const workingDays = storeDetalFormData[1].filter(day => store[day]);

      const storeData = {
        address: store.address,
        categories: store.category.map(cat => cat.id),
        categoryNames: store.category.map(cat => cat.nameRu),
        nameRu: store.nameRu,
        nameEn: store.nameEn,
        nameKg: store.nameKg,
        descRu: store.descRu,
        descEn: store.descEn,
        descKg: store.descKg,
        insta: store.insta,
        priority: parseFloat(store?.priority),
        catobj: store?.category,
        workingHours: `${store.hourFrom}-${store.hourTo}`,
        hourFrom: store?.hourFrom,
        hourTo: store?.hourTo,
        workingDays,
        logo: imgUrl,
      }

      await setDoc(storessRef, storeData);

      await addToHistory(userCollectionRef, description)

      toast.success('Магазина успешно добавлен');
      navigate('/stores');
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка при добавлении магазина');
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <div className="container">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Статистика'} initial={'Добавление магазина'} />
        <Title title={'Добавление магазина'} icon={<AddCircleIcon fontSize='meduim' />} />
        <div className="container-inner">
          <div className="order-form">
            <form className="order-form-flex">
              <div className='order-block' style={{ width: '48%', height: 'auto' }}>
                <div className='order-block-head'>
                  <h3>Детали магазина</h3>
                </div>
                <div className='order-input-block'>
                  <Grid container spacing={0.75}>
                    {storeDetalFormData[0].map((field) => (
                      <Grid item xs={4} key={field}>
                        <TextField
                          label={field.startsWith('name') ? `Название на ${field.slice(-2).toUpperCase()}` : `Описание на ${field.slice(-2).toUpperCase()}`}
                          variant='outlined'
                          size='small'
                          fullWidth
                          helperText={errors[field]?.message}
                          error={!!errors[field]}
                          {...register(field, RequiredInput)}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  <TextField
                    id='outlined-basic'
                    label='Ссылка на instagram'
                    variant='outlined'
                    size='small'
                    fullWidth
                    helperText={errors?.insta?.message}
                    error={errors?.insta && true}
                    {...register('insta', RequiredInput.required)}
                  />
                  <TextField
                    id='outlined-basic'
                    label='Адрес магазина'
                    variant='outlined'
                    size='small'
                    fullWidth
                    error={errors?.address ? true : false}
                    helperText={errors ? errors?.address?.message : ''}
                    {...register('address', RequiredInput.required)}
                  />
                  <TextField
                    id='outlined-basic'
                    label='Приоритет'
                    variant='outlined'
                    size='small'
                    type='number'
                    fullWidth
                    error={errors?.priority ? true : false}
                    helperText={errors ? errors?.priority?.message : ''}
                    {...register('priority', RequiredInput.required)}
                  />

                  <TextField
                    size='small'
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    error={errors?.address ? true : false}
                    helperText={errors ? errors?.address?.message : ''}
                    {...register('photo', RequiredInput.required)}
                  />
                  <Controller
                    name='category'
                    control={control}
                    defaultValue={[]}
                    rules={{ required: 'Объязательное поле' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        id='category'
                        options={categories || []}
                        getOptionLabel={(option) => option.nameRu}
                        filterOptions={(options, state) => {
                          const selectedValues = field.value || [];
                          return options.filter(option => !selectedValues.some(val => val.id === option.id));
                        }}
                        onChange={(_, val) => field.onChange(val)}
                        value={field.value || []}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip label={option.nameRu} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={`random-${Math.random().toString(36).substring(7)}`}
                            label='Категория'
                            variant='outlined'
                            size='small'
                            error={!!errors?.category}
                            helperText={errors?.category?.message}
                          />
                        )}
                      />
                    )}
                  />
                </div>

              </div>
              <div className='order-block'>
                <div className='order-block-head'>
                  <h3>Рабочее время</h3>
                </div>
                <div className='order-input-block'>
                  <Stack direction='row' gap={1} justifyContent='space-between'>
                    <FormGroup>
                      {storeDetalFormData[1].map((day) => (
                        <FormControlLabel
                          key={day}
                          control={<Checkbox {...register(day)} />}
                          label={russianDaysOfWeek[day]}
                        />
                      ))}
                    </FormGroup>
                    <Stack direction='row' justifyContent='space-between'>
                      <Stack direction='row' gap={1}>
                        {storeDetalFormData[2].map((field) => (
                          <TextField
                            key={field}
                            type='time'
                            size='small'
                            focused
                            label={field === 'hourFrom' ? 'Начало' : 'Конец'}
                            InputProps={{ sx: { width: '110px' } }}
                            {...register(field, RequiredInput)}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </div>
              </div>
            </form>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
              <Button
                onClick={handleSubmit((data) => handlePostStore(data))}
                size='large'
                variant='red-contained'
                color='primary'
              >
                Добавить магазин
              </Button>
            </Box>
          </div>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div >
    </>
  )
}

export default StoreAdd