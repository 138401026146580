import { getDocs, orderBy, query } from "firebase/firestore";

export const fetchData = async (collectionRef, sortField, sortOrder) => {
  try {
    const querySnapshot = await getDocs(
      query(collectionRef, orderBy(sortField, sortOrder))
    );
    return querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }));
  } catch (error) {
    console.error('Error fetching documents:', error);
    return [];
  }
};
