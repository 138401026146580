import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { BiPackage } from 'react-icons/bi';
import { GoPackage } from 'react-icons/go';
import { FiPackage } from 'react-icons/fi';
import { dateFormatter } from '../../../../utils/dateFormatter';
import { archivedOrdersRef, couriersRef, orderRef } from '../../../../utils/collectionRefs';
import { db } from '../../../../configs';
import { Title } from '../../../../components/Title/Title';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { toast } from 'react-toastify';
import {
  orderStatus2,
  packageTypeLabels,
  paymentMethods,
} from '../../../../utils';
import {
  Divider,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Autocomplete,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import OrderDetailBox from '../../../../components/OrderDetailBox';
import './scss/OrderDetail.scss';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import noImage from '../../../../assets/images/noimage.jpeg';
import { Helmet } from 'react-helmet';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import OrderEditModal from '../../../../components/CustomDialog/Modal';
import { addToHistory } from './../../../../utils/addToHistory';

const OrderDetail = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [editField, setEditedField] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [selectedChangeCourier, setSelectedChangeCourier] = useState(null);
  const [courierOne, setCourierOne] = useState(null);
  const [courierTwo, setCourierTwo] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const qrCodeRef = useRef(null);

  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');

  const userCollectionRef = collection(
    db,
    `${roleType}`,
    `${userName}`,
    'history'
  );
  useEffect(() => {
    const getOrder = async () => {
      try {
        const docRef = doc(orderRef, id);
        const data = await getDoc(docRef);
        return { ...data.data(), id: data.id };
      } catch (error) {
        console.error(error.message);
        return null;
      }
    };

    const getCouriers = async () => {
      try {
        const querySnapshot = await getDocs(couriersRef);
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return data;
      } catch (error) {
        console.error(error.message);
        return null;
      }
    };

    const fetchData = async () => {
      const order = await getOrder();
      const couriersData = await getCouriers();
      setOrder(order);
      setCouriers(couriersData);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const imgList = [
    {
      urlPhoto: order?.senderItemPhoto,
      title: 'Фото отправителя',
    },
    {
      urlPhoto: order?.senderSignature,
      title: 'Подпись отправителя',
    },
    {
      urlPhoto: order?.receiverItemPhoto,
      title: 'Фото получателя',
    },
    {
      urlPhoto: order?.receiverSignature,
      title: 'Подпись получателя',
    },
  ];

  const fetchCourierData = async (courierId, setCourier) => {
    if (courierId) {
      const docRef = doc(db, 'couriers', courierId);
      const docSnap = await getDoc(docRef);
      setCourier(docSnap.exists() ? docSnap.data() : null);
    }
  };

  useEffect(() => {
    fetchCourierData(order?.courierOne, setCourierOne);
  }, [order?.courierOne]);

  useEffect(() => {
    fetchCourierData(order?.courierTwo, setCourierTwo);
  }, [order?.courierTwo]);

  const moveOrderToArchive = async (orderData) => {
    try {
      const id = orderData.id;
      const archivedOrderDoc = doc(archivedOrdersRef, id);
      const orderDoc = doc(orderRef, id);

      await setDoc(archivedOrderDoc, orderData);
      await deleteDoc(orderDoc);

      await addDoc(userCollectionRef, {
        date: new Date(),
        description: `${userType} ${userName} удалил заказ с ID ${id}`
      })

      toast.success('Заказ успешно удален', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      navigate('/orders');
    } catch (error) {
      console.error('Error deleting order:', error);
      toast.error('Произошла ошибка при удалении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const handleEditOrder = async (editedOrder, editField) => {
    setEditValue(editedOrder)
    setEditedField(editField)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleSaveChanges = async (editedValue) => {
    try {
      const orderDocRef = doc(orderRef, id);
      const description = `${userType} ${userName} обновил ${editField} на ${editedValue} | ID: ${id}`
      const historyRef = collection(orderDocRef, 'history')
      const LOADING_MESSAGE = `Редактирование ${editField}...`;
      let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });

      await updateDoc(orderDocRef, { [editField]: editedValue });
      await addToHistory(historyRef, description)
      await addToHistory(userCollectionRef, description)
      
      setIsModalOpen(false);

      toast.dismiss(loadingToastId);
      toast.success('Заказ успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка при удалении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      setIsModalOpen(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleCourierChange = (_, value) => {
    setSelectedCourier(value);
  };
  const handleCourierChange2 = (_, value) => {
    setSelectedChangeCourier(value);
  };

  const assignCourier = async () => {
    if (selectedCourier) {
      const STATUS_CONFIRMED = 'status_confirmed';
      const LOADING_MESSAGE = 'Назначение курьера...';
      const SUCCESS_MESSAGE = 'Курьер успешно назначен для заказа с номером';
      const ERROR_MESSAGE = 'Ошибка при выборе назначении курьера:';
      let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });
      try {
        await updateDoc(doc(db, 'orders', id), {
          courierOne: selectedCourier.id,
          couriersForFilter: [`${selectedCourier.id}`],
          lastCourier: selectedCourier.id,
          status: STATUS_CONFIRMED,
          statusFilter: [STATUS_CONFIRMED],
        });

        toast.dismiss(loadingToastId);
        toast.success(`${SUCCESS_MESSAGE} ${order?.pid}`);
      } catch (error) {
        toast.error(ERROR_MESSAGE, {
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error(error);
      }
    }
  };

  const changeCourier = async () => {
    if (selectedChangeCourier) {
      const STATUS_CONFIRMED = 'status_confirmed';
      const LOADING_MESSAGE = 'Смена курьера...';
      const SUCCESS_MESSAGE = '"Курьер успешно заменен"';
      const ERROR_MESSAGE = 'Ошибка при выборе смене курьера:';
      let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });
      try {
        await updateDoc(doc(db, 'orders', id), {
          courierOne: selectedChangeCourier.id,
          couriersForFilter: [`${selectedChangeCourier.id}`],
          lastCourier: selectedChangeCourier.id,
          status: STATUS_CONFIRMED,
          statusFilter: [STATUS_CONFIRMED],
        });

        toast.dismiss(loadingToastId);
        toast.success(`${SUCCESS_MESSAGE} ${order?.pid}`);
        setOpen2(false)
      } catch (error) {
        toast.error(ERROR_MESSAGE, {
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.error(error);
      }
    }
  };


  const handleClose = () => {
    setOpen2(false);
  };

  function renderOrderStatus(order) {
    const isRejectedOrCancelled = order.status === 'status_rejected' || order.status === 'status_cancelled';

    return (
      <Stepper
        activeStep={
          isRejectedOrCancelled
            ? 10
            : orderStatus2.findIndex((status) => status.value === order.status)
        }
        alternativeLabel
        sx={{ overflowX: 'scroll', paddingBottom: '20px' }}
        className='scrollable'
      >
        {isRejectedOrCancelled ? (
          <Step key={order.status} sx={{ minWidth: '110px' }}>
            <StepLabel sx={{ fontSize: '12px', color: 'red' }} error>
              {orderStatus2.find((status) => status.value === order.status)?.name}
            </StepLabel>
          </Step>
        ) : (
          orderStatus2?.slice(0, 10).map((label) => (
            <Step key={label.value}>
              <StepLabel sx={{ fontSize: '12px', minWidth: '110px' }}>
                {label.name}
              </StepLabel>
            </Step>
          ))
        )}
      </Stepper>
    );
  }

  const OrderDetails = ({ sender, receiver }) => {
    return (
      <>
        <OrderDetailBox
          title='Отправитель'
          items={[
            { label: 'Имя', value: sender?.senderName },
            { label: 'Номер телефона', value: sender?.sender },
            { label: 'Город', value: sender?.addressFrom?.cityName },
            {
              label: 'Регион/село',
              value: sender?.addressFrom?.districtName || '----------',
            },
            { label: 'Адрес отправки', value: sender?.addressFrom?.address },
          ]}
        />
        <OrderDetailBox
          title='Получатель'
          items={[
            { label: 'Имя', value: receiver?.receiverName },
            { label: 'Номер телефона', value: receiver?.receiver },
            { label: 'Город', value: receiver?.addressTo?.cityName },
            {
              label: 'Регион/село',
              value: receiver?.addressTo?.districtName || '----------',
            },
            { label: 'Адрес отправки', value: receiver?.addressTo?.address },
          ]}
        />
      </>
    );
  };

  const renderCourierDetailBox = (courier, title, orderStatus) => {
    if (!courier) return null;

    const items = [
      { label: 'Имя', value: `${courier.name} ${courier.surName}` },
      { label: 'Номер телефона', value: courier.phone },
      { label: 'Город', value: courier.cityInfo?.name },
      { label: 'Рейтинг', value: courier.raiting },
      { label: 'Тип курьера', value: courier.type },
    ];

    return <OrderDetailBox title={title} items={items} orderStatus={orderStatus2} setOpen2={setOpen2} />;
  };

  const renderPackageTypeIcon = (packageType) => {
    switch (packageType) {
      case 'document':
        return (
          <FilePresentRoundedIcon fontSize='small' sx={{ color: '#3A46D6' }} />
        );
      case 'medicine':
        return (
          <MedicationRoundedIcon fontSize='small' sx={{ color: '#b42318' }} />
        );
      case 'food':
        return (
          <FastfoodRoundedIcon fontSize='small' sx={{ color: '#f4450f' }} />
        );
      case 'large_box':
        return <GoPackage size={18} color='#f0ad4e' />;
      case 'small_box':
        return <BiPackage size={18} color='#f0ad4e' />;
      case 'box':
        return <FiPackage size={18} color='#f0ad4e' />;
      default:
        return null;
    }
  };

  const printOrderInfo = () => {
    if (qrCodeRef.current) {
      const qrCodeValue = qrCodeRef.current.toDataURL();
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Список заказов'} initial='Детали заказа' />
        <Title
          title={'Детали заказа'}
          icon={<DescriptionRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          {!order ? (
            <Loader />
          ) : (
            <div className='orders-more-wrapper'>
              <div className='orders-more-box'>
                <div className='orders-more-box-header'>
                  <h3>Статус заказа</h3>
                </div>
                <Divider />
                <Box sx={{ width: '100%' }}>{renderOrderStatus(order)}</Box>
              </div>
              <ImageList
                sx={{ width: 500, height: 450 }}
                cols={2}
                rowHeight={164}
              >
                {imgList.map((item) => (
                  <ImageListItem key={item.title}>
                    <img
                      srcSet={!item.urlPhoto ? noImage : item.urlPhoto}
                      src={!item.urlPhoto ? noImage : item.urlPhoto}
                      alt={item.title}
                    />
                    <ImageListItemBar title={item.title} position='top' />
                  </ImageListItem>
                ))}
              </ImageList>
              {!order?.courierOne?.length && order?.status === 'status_new' && (
                <div className='orders-more-box select-courier status-new-courier'>
                  <div className='orders-more-box-header'>
                    <h3>Назначение курьера</h3>
                  </div>
                  <Autocomplete
                    id='select-courier'
                    disablePortal
                    options={couriers || []}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.phone})`
                    }
                    onChange={handleCourierChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={`random-${Math.random().toString(36).substring(7)}`}
                        label='Выберите курьера'
                        variant='outlined'
                        size='small'
                      />
                    )}
                  />
                  <Button
                    onClick={assignCourier}
                    variant='contained'
                    color='info'
                    disabled={!selectedCourier}
                  >
                    Назначить
                  </Button>
                </div>
              )}
              <Box
                sx={{ width: '100%' }}
                gap='12px'
                borderTop={courierOne || courierTwo ? 'none' : '2px solid#ccc'}
              >
                {[courierOne, courierTwo].map((courier, index) => (
                  <Box
                    sx={{ width: '100%', marginTop: '16px' }}
                    display='flex'
                    gap='12px'
                    key={index}
                  >
                    {renderCourierDetailBox(courier, `Курьер ${index + 1}`, order?.status)}
                  </Box>
                ))}
              </Box>

              <OrderDetails title='Заказ' sender={order} receiver={order} />

              <div className='order-detail-wrap'>
                <div className='orders-more-box'>
                  <div className='orders-more-box-header'>
                    <h3>Детали заказа</h3>
                  </div>
                  <Divider />
                  <ul className='orders-more-body-list'>
                    <li>
                      <span>ID</span>
                      <span>{order?.id}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Дата</span>
                      <span>{dateFormatter(order?.dateCreated?.seconds)}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Тип посылки</span>
                      <span className='order-more-list-type'>
                        {packageTypeLabels[order?.packageType]}
                        {renderPackageTypeIcon(order?.packageType)}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Тариф заказа</span>
                      <span className='order-more-list-type'>
                        {order?.tariff?.name}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Платежное лицо</span>
                      <span>
                        {(() => {
                          switch (order?.whoPays) {
                            case 1:
                              return 'Отправитель';
                            case 2:
                              return 'Получатель';
                            default:
                              return 'Совместно';
                          }
                        })()}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Метод оплаты</span>
                      <span>{paymentMethods[order?.paymentMethod]}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Статус оплаты</span>
                      <span className='order-more-list-type'>
                        {!order?.paymentStatus ? 'Не оплачен' : 'Оплачен'}
                        {!order?.paymentStatus ? (
                          <DoNotDisturbAltRoundedIcon
                            fontSize='small'
                            sx={{ color: '#b42318' }}
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            fontSize='small'
                            sx={{ color: '#0b815a' }}
                          />
                        )}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Реквизиты</span>
                      <span className='order-more-list-type-edit'>
                        {order?.requisite}
                        <NoteAltRoundedIcon onClick={() => handleEditOrder(order?.requisite, 'requisite')} />
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Выкуп</span>
                      <span className='order-more-list-type-edit'>
                        {order?.redemption}с
                        <NoteAltRoundedIcon onClick={() => handleEditOrder(order?.redemption, 'redemption')} />
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Сумма</span>
                      <span className='order-more-list-type-edit'>
                        {order?.cost}с
                        <NoteAltRoundedIcon onClick={() => handleEditOrder(order?.cost, 'cost')} />
                      </span>
                    </li>
                    <Divider />
                    <li className='order-more-list-comments'>
                      <span>Коментарии</span>
                      <span className='order-more-list-type-edit'>
                        {order?.comments ? order?.comments : '----------'}
                        <NoteAltRoundedIcon onClick={() => handleEditOrder(order?.comments ?? '', 'comments')} />
                      </span>
                    </li>
                    {/* <Divider />
                    <li className='order-more-list-comments'>
                      <span>Заметки</span>
                      <span className='order-more-list-type-edit'>
                        {order?.notes ? order?.comments : '----------'}
                      </span>
                    </li> */}
                  </ul>
                </div>
                <Box
                  display='flex'
                  alignItems='center'
                  flexDirection='column'
                  gap='12px'
                >
                  <Box ref={qrCodeRef} className='order-qr-box'>
                    <div className='orders-more-box-header'>
                      <h3>PID: {order?.pid ? order?.pid : 'Нет PID'}</h3>
                    </div>
                    <QRCode
                      value={order?.pid ?? 'Отсутствует'}
                      size={260}
                      style={{ margin: '0 auto' }}
                    />
                  </Box>
                  <ReactToPrint
                    content={() => qrCodeRef.current}
                    trigger={() => (
                      <Button variant='black-contained' onClick={printOrderInfo} fullWidth>
                        Распечатать
                      </Button>
                    )}
                  />
                </Box>
              </div>

              <div className='orders-more-box actions'>
                {userType === 'Админ' && (
                  <>
                    <Button variant='black-contained' onClick={handleClickOpen}>
                      Удалить
                    </Button>
                    <Button
                      variant='black-contained'
                      onClick={() => navigate(`/order/update/${id}`)}
                    >
                      Редактировать
                    </Button>
                  </>
                )}
                <Button
                  variant='black-contained'
                  onClick={() => navigate(`/order/history/orders/${id}`)}
                >
                  История
                </Button>
                <Button
                  variant='black-contained'
                  onClick={() => navigate(`/order/notes/${id}`)}
                >
                  Заметки
                </Button>
              </div>
            </div>
          )}
          <OrderEditModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveChanges}
            defaultValue={editValue}
            editingField={editField}
          />
          <Dialog open={open2} onClose={handleClose} className='returned-order'>
            <DialogTitle>Сменить курьера</DialogTitle>
            <form style={{ width: '400px' }}>

              <DialogContent>
                <Autocomplete
                  id='select-courier'
                  disablePortal
                  defaultValue={courierOne}
                  options={couriers || []}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.phone})`
                  }
                  onChange={handleCourierChange2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={`random-${Math.random().toString(36).substring(7)}`}
                      label='Выберите курьера'
                      variant='outlined'
                      size='small'
                    />
                  )}
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} sx={{ color: '#000b1f' }}>
                  Отмена
                </Button>
                <Button
                  onClick={changeCourier}
                  color='info'
                  disabled={!selectedChangeCourier}
                >
                  Сменить
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <CustomDialog
            open={open}
            onClose={handleClickClose}
            event={() => moveOrderToArchive(order)}
            text='Вы уверены что хотите удалить заказ?'
            btnText='Удалить'
          />
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
