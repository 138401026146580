import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { getDocs, orderBy, query } from 'firebase/firestore';
import { storesRef } from '../../../../utils/collectionRefs';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OrderGrid from '../../../../components/OrderGrid ';
import { Loader } from '../../../../components/Loader/Loader';
import { storeColumns } from './constants/storeColumns';

import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';

const Stores = ({ pageTitle }) => {
  const [stores, setStores] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const USERTYPE = localStorage.getItem('userType');

  const fetchStores = useCallback(async () => {
    setIsLoading(true)
    try {
      const q = query(storesRef, orderBy('priority', 'asc'))
      const storesDocs = await getDocs(q)
      const newStoreDocs = storesDocs.docs.map((item) => ({ ...item.data(), id: item.id }))
      setStores(newStoreDocs)
    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  const actionsConfig = [

    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/store/update/${params.id}`),
    },

  ];

  const columns = storeColumns({ actionsConfig: actionsConfig, path: '/store/details' })

  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header initial='Магазины' previous='' />
        <Title title='Магазины' icon={<StoreMallDirectoryRoundedIcon fontSize='large' />} />
        <div className="container-inner">
          <div className="orders-card-wrapper">
            <div className="orders-card-header">
              <div className="orders-card-parent">
                <div className="orders-card-parent__btns">
                  {USERTYPE === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/store/add')}>
                      Новый магазин +
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {isLoading ? <Loader /> : <OrderGrid orders={stores} columns={columns} size={20} />}

          </div>
        </div>
      </div>
    </>
  )
}

export default Stores