import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export const useKassa = () => {
  const [kassa, setKassa] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchKassaData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://aylex.ru/api/core/kassa/`);
      setKassa(response.data.results);
    } catch (error) {
      console.error('Error fetching kassa data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchKassaData();
  }, [fetchKassaData]);

  return { kassa, loading };
};