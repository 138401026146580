import { getDoc, updateDoc } from "firebase/firestore";

export const updateOrderStatus = async (orderRef, newStatus) => {
  const orderSnapshot = await getDoc(orderRef);
  const orderData = orderSnapshot.data();
  if (
    newStatus === 'status_at_sorting_center2' ||
    newStatus === 'status_on_way_cc'
  ) {
    const addressToCity = orderData.addressTo?.city;
    await updateDoc(orderRef, {
      status: newStatus,
      statusFilter: [newStatus],
      cityFilter: addressToCity,
    });
  } else {
    const addressFromCity = orderData.addressFrom?.city;
    await updateDoc(orderRef, {
      status: newStatus,
      statusFilter: [newStatus],
      cityFilter: addressFromCity,
    });
  }

  return 'Update successful';
};