import axios from "axios";
import { toast } from "react-toastify";

export const uploadImage = async (file, title) => {

  const formData = new FormData();
  formData.append('image', file);
  formData.append('title', title);

  try {
    const response = await axios.post('https://aylex.ru/api/core/image/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response?.data?.image;
  } catch (error) {
    console.error('Error uploading image:', error);
    toast.error('Произошла ошибка при загрузке изображения', { autoClose: 2000, hideProgressBar: false });
    throw error;
  }
};