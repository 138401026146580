import { Loader } from './components/Loader/Loader';
import AuthRoutes from './pages/AuthRoutes';
import { useAuth } from './provider/useAuth';
import PrivateRoute from './pages/PrivateRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './pages/PrivateRoutes/general.scss';

export const App = () => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <Loader height={'100vh'} />;
  }

  return (
    <>
      {currentUser ? <PrivateRoute /> : <AuthRoutes />}
      <ToastContainer />
    </>
  );
};
