import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import React from "react";
import noData from '../../../../../assets/images/no-image-for-datagrid.png'
import { russianDaysOfWeek2 } from "./formData";
import AutoLink from "../../../../../components/AutoLink";

const ActionIconButton = ({ title, icon, onClick }) => (
  <Tooltip title={title} arrow>
    <IconButton color='primary' aria-label='edit' onClick={onClick}>
      {React.cloneElement(icon, { style: { color: '#000b1f' } })}
    </IconButton>
  </Tooltip>
);
const WorkingDaysCell = ({ workingDays }) => (
  <div className='orders-card-values'>
    {workingDays.map((day, index) => (
      <span key={index}>
        {russianDaysOfWeek2[day]}
        {index === workingDays.length - 1 ? '' : '-'}
      </span>
    ))}
  </div>
);

export const storeColumns = ({ actionsConfig, path, editable, customLabels, orderType }) => {
  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: !actionsConfig ? 0 : 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        {!actionsConfig ? null : (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`${path}/${params.id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )}
      </div>
    ),
    renderHeader: () => <strong>{!actionsConfig ? '' : 'Действия'}</strong>,
  };
  return [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`${path}/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip>
      )
    },
    {
      width: 100,
      field: 'logo',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return <img src={params.row.logo ?? noData} alt={params.row.nameRu} style={{ width: '100%', height: 'auto' }} />
      },
      renderHeader: () => <strong>Логотип</strong>,
      disableColumnMenu: false,
    },
    {
      width: 140,
      field: 'phones',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>Телефон</strong>,
      disableColumnMenu: false,
    },

    {
      width: 200,
      field: 'nameRu',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>Название</strong>,
    },
    {
      width: 240,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      field: 'descRu',
      renderHeader: () => <strong>Описание</strong>,
    },

    {
      width: 230,
      field: 'insta',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      disableColumnMenu: false,
      renderHeader: () => <strong>Instagram</strong>,
      renderCell: (params) => {
        return (
          <div className='orders-card-values'>
            <span><AutoLink text={params.row.insta} /></span>
          </div>
        );
      },
    },
    {
      width: 200,
      field: 'workingDays',
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      sortable: false,
      renderHeader: () => <strong>Рабочие дни</strong>,
      renderCell: (params) => <WorkingDaysCell workingDays={params.row.workingDays} />,
      disableColumnMenu: false,
    },
    {
      width: 120,
      field: 'workingHours',
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      sortable: false,
      renderHeader: () => <strong>Рабочий час</strong>,
      disableColumnMenu: false,
    },
    {
      width: 250,
      field: 'categoryNames',
      sortable: false,
      headerAlign: 'center',
      disableColumnMenu: false,
      cellClassName: 'centered-cell',
      renderCell: (params) => {
        const categories = params?.row?.categoryNames?.join(', ');
        return <div className="orders-card-values">
          {categories}
        </div>;
      },
      renderHeader: () => <strong>Категории</strong>,
    },
    {
      width: 230,
      field: 'address',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      disableColumnMenu: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values'>
            <span>{params.row.address ?? 'Нет адреса'}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Адрес</strong>,
    },
    actionsColumn
  ]
};