import React from "react";
import { dateFormatter } from "../../../../../utils/dateFormatter";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { transactionStatusLabels } from "../constants";
import AutoLink from "../../../../../components/AutoLink";

export const transActionColumns = ({ path }) => {
  const currentDate = Date.now()
  return [
    {
      width: 150,
      field: 'date',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params.row?.date?.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата</strong>,
      disableColumnMenu: false,
    },
    {
      width: 130,
      field: 'user',
      sortable: false,
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 130,
      field: 'sender',
      sortable: false,
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 130,
      field: 'receiver',
      sortable: false,
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 300,
      field: 'type',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Тип расхода</strong>,
      renderCell: (params) => {
        return <div>{transactionStatusLabels[params.row.type]}</div>;
      },
    },
    {
      width: 140,
      field: 'balanceBefore',
      sortable: false,
      headerAlign: 'center',
      columnAlign: true,
      renderCell: (params) => {
        return (
          <strong className='orders-card-values balanceBefore'>
            {params.row.balanceBefore}с
          </strong>
        );
      },
      renderHeader: () => <strong>Баланс до</strong>,
    },
    {
      width: 140,
      field: 'sum',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <strong className='orders-card-values sum'>{params.row.sum}с</strong>
        );
      },
      renderHeader: () => <strong>Cумма</strong>,
    },
    {
      width: 140,
      field: 'balanceAfter',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <strong className='orders-card-values balanceAfter'>
            {params.row.balanceAfter}с
          </strong>
        );
      },
      renderHeader: () => <strong>Баланс после</strong>,
    },
    {
      width: 600,
      field: 'comment',
      sortable: false,
      renderHeader: () => <strong>Комментарии</strong>,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'wrap' }}>
            <AutoLink text={params.row.comment} />
          </div>
        );
      },
    },
    {
      width: 200,
      field: 'creatorName',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Создатель</strong>,
      renderCell: (params) => {
        const creatorName = params?.row?.creatorName
        const creator = params?.row?.creator
        return (
          <strong>
            {creator} - {creatorName}
          </strong>
        );
      },
    },
    {
      field: 'actions',
      sortable: false,
      width: 100,
      menubar: false,
      renderCell: (params) => {
        const createdDate = params?.row?.date?.seconds * 1000;
        const difference = Math.abs(currentDate - createdDate);
        const lessThanOneDay = difference < 30 * 24 * 60 * 60 * 1000;
        return (
          <div>
            {lessThanOneDay ? (
              <Tooltip title='Редактировать' arrow>
                <Link to={`${path}/${params.id}`} targer="_blank">
                  <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
                </Link>
              </Tooltip>
            ) : null}
          </div>
        )
      },
      renderHeader: () => <strong>Действия</strong>,
    },
  ];
} 