import React, { useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, Container, FormControl, Stack, TextField, Typography } from '@mui/material'
import Logo from '../../../assets/images/logo.svg'
import { useForm } from 'react-hook-form'
import { doc, setDoc } from 'firebase/firestore'
import { deleteRequestRef } from '../../../utils/collectionRefs'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
const DeleteRequest = () => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(false)
  const [errorss, setErrorss] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleSendData = async (data) => {
    try {
      setLoading(!loading)
      await setDoc(doc(deleteRequestRef, `${data.phone}`), { ...data });
      setLoading(false);
      setState(true)
      reset();
    } catch (error) {
      setLoading(false);
      setErrorss(true)
      console.error(error)
    }
  }

  return (
    <>
      <Container maxWidth="sm" fixed sx={{ mt: '80px', mb: '20px', }}>
        <Box sx={{ bgcolor: 'white', padding: '2rem', borderRadius: '12px' }}>
          <Stack direction='column' justifyContent='center' alignItems='center' sx={{ width: '100%' }} gap={4}>
            <div style={{ width: '300px', height: '100px' }}>
              <img src={Logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
            <div>
              <Typography variant="h4">
                Запрос на удаление личных данных
              </Typography>
              <Typography>
                Для отправки запроса для удаления ваших личных данных заполните ниже форму, наши менеджеры свяжутся с вами в ближайшее время
              </Typography>

              {state && (<TaskAltRoundedIcon fontSize='32' style={{ color: 'green', fontSize: '42px', marginTop: '20px' }} />)}
              {errorss && (<DangerousRoundedIcon fontSize='32' style={{ color: 'red', fontSize: '42px', marginTop: '20px' }} />)}
            </div>
            <FormControl fullWidth sx={{ gap: '20px', }}>
              <TextField
                label="Номер"
                type='number'
                size='small'
                error={errors?.phone && true}
                helperText={errors?.phone?.message}
                {...register('phone', { required: 'Заполните поле' })}
              />
              <TextField
                id='outlined-multiline-static'
                label='Коментарии'
                multiline
                rows={4}
                error={errors?.comments && true}
                helperText={errors?.phone?.message}
                {...register('comments', { required: 'Заполните поле' })}
              />
              <Button
                variant='contained'
                type='submit'
                onClick={handleSubmit((data) => handleSendData(data))}
                disabled={loading}
                sx={{ padding: '14px' }}
              >
                Отправить
              </Button>
            </FormControl>
          </Stack>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </Box>
      </Container>
    </>
  )
}

export default DeleteRequest