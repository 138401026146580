import * as React from 'react';
import { sideListForAdmin, sideListForManager } from '../../utils';
import { NavLink } from 'react-router-dom';
import { handleSignOut } from '../../configs/index';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Logo from '../../assets/images/logo.svg';
import './Navbar.scss';

const Navbar = () => {
  const userType = localStorage.getItem('userType');
  const arrSidebarList = userType === 'Админ' ? sideListForAdmin : sideListForManager;

  const navLinkStyles2 = ({ isActive }) => {
    return {
      width: '100%',
      background: isActive ? '#a0aad258' : '',
      color: isActive ? 'white' : '#beb9c6',
      transition: 'background-color 0.3s ease',
      ':hover': {
        background: '#a0aad2',
      },
    };
  };

  return (
    <>
      <nav className='nav scrollable' style={{ overflowY: 'scroll' }}>
        <div className='nav-list-wrapper'>
          <div className='nav-logo'>
            <img src={Logo} alt='LOGO' />
          </div>
          <hr />
          <ul className='nav-list'>
            {arrSidebarList?.map(({ id, icon, path, title }, index) => (
              <NavLink
                to={path}
                className='link'
                style={navLinkStyles2}
                key={index}
              >
                <li>
                  <span>{icon}</span>
                  <span className='nav-list__text'>{title}</span>
                </li>
              </NavLink>
            ))}
          </ul>
          <li>
            <button onClick={handleSignOut} className='navbar-signOut'>
              <LogoutRoundedIcon />
              <span className='nav-list__text'>Выйти</span>
            </button>
          </li>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
