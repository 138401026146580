import React from 'react'
import { GridOverlay } from '@mui/x-data-grid';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import InboxIcon from '@mui/icons-material/Inbox';
import { Box, Typography } from '@mui/material';

export const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <InboxIcon sx={{ fontSize: 40, color: 'gray' }} />
      <Typography sx={{ mt: 1, color: 'gray' }}>Таблица пуста. Добавьте данные для отображения.</Typography>
    </Box>
  </GridOverlay>
);

export const CustomNoResultsOverlay = () => (
  <GridOverlay>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <SearchOffRoundedIcon sx={{ fontSize: 40, color: 'gray' }} />
      <Typography sx={{ mt: 1, color: 'gray' }}>Нет данных, соответствующих текущим фильтрам.</Typography>
    </Box>
  </GridOverlay>
);
