import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../configs';
import HistoryList from '../../../../components/Lists/HistoryList';
import { Loader } from '../../../../components/Loader/Loader';
import noDataImage from '../../../../assets/images/no-data3.svg';
import NoData from '../../../../components/NoData/NoData';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { Helmet } from 'react-helmet';
import './History.scss';

const UserHistory = ({ pageTitle }) => {
  const { role, id } = useParams();
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    const docRef = doc(db, role, id);
    const unsubscribe = onSnapshot(
      query(
        collection(docRef, 'history'),
        orderBy('date', 'desc')
      ),
      (snapshot) => {
        const notes = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setNotes(notes);
      }
    );

    return unsubscribe;
  }, [role, id]);

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='История пользователя' />
        <Title
          title='История пользователя'
          icon={<AccessTimeFilledRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <div className='order-history-top'>
            <p>ID</p>
            <h3> - {id}</h3>
          </div>
          <div className='orders-history-wrapper'>
          {notes
              ? <HistoryList items={notes} />
              : <Loader />
            }
            {notes?.length < 1 && <NoData img={noDataImage} text='История пользователя пуста' />}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHistory;
