import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useTransition, animated } from 'react-spring';
import { dateFormatter2 } from '../../utils/dateFormatter';

const HistoryList = ({ items, animationConfig }) => {
  const transitions = useTransition(items, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    trail: 200,
    keys: item => item.id,
    ...animationConfig,
  });

  const styleForTimline = {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
  }
  const renderContent = (item) => (
    <div className='timeline-content'>
      <strong>{dateFormatter2(item?.date?.seconds * 1000)} ({new Date(item?.date?.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })})
      </strong>
      <p dangerouslySetInnerHTML={{ __html: item?.description }} />
    </div>
  );

  return (
    <Timeline sx={styleForTimline}>
      {transitions((styles, item, _) => (
        <animated.div style={styles} key={item.id}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot sx={{ background: '#FF4654' }} />
              <TimelineConnector sx={{ borderRadius: '12px' }} />
            </TimelineSeparator>
            <TimelineContent>{renderContent(item)}</TimelineContent>
          </TimelineItem>
        </animated.div>
      ))}
    </Timeline>
  );
};

export default HistoryList;