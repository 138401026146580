import React, { useEffect, useMemo, useState } from 'react';
import { getAggregateFromServer, getDocs, orderBy, query, sum } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Title } from './../../../../components/Title/Title';
import { Header } from './../../../../components/Header/Header';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { agentsRef } from '../../../../utils/collectionRefs';
import { fieldsToSearchCourier, headers } from './constants';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import OrderGrid from '../../../../components/OrderGrid ';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { getAgentsColumn } from './helpers/getAgentsColumn';
import { Loader } from '../../../../components/Loader/Loader';
import { ExportCouriersToExcelButton } from '../../../../components/Buttons/ExportCouriersToExcell';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';

const Agents = ({ pageTitle }) => {
  const [couriers, setCouriers] = useState(null);
  const [totalBalance, setTotalBalance] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('');

  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    setIsLoading(true);
    await fetchBalanceOfCouriers();
    await fetchingCouriers();
    setIsLoading(false);
  };

  const fetchBalanceOfCouriers = async () => {
    try {
      const querySnapshot = await getAggregateFromServer(agentsRef, {
        balance: sum('balance')
      });
      setTotalBalance({ ...querySnapshot.data() })
    } catch (error) {
      console.error(error);
    }
  }

  const fetchingCouriers = async () => {
    try {
      setIsLoading(true)
      const querySnapshot = await getDocs(query(agentsRef, orderBy('balance', 'desc')));
      const couriersData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCouriers(couriersData);
    } catch (error) {
      console.error('Error fetching couriers:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleSearchCourier = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredCouriers = useMemo(() => {
    if (!couriers) return { filteredCouriers: [], balance: 0, };
    const filteredCouriers = couriers.filter((courier) =>
      fieldsToSearchCourier.some((field) => {
        const value = courier[field];
        return value && value.toLowerCase().includes(search.toLowerCase());
      })
    );
    const totalBalance = filteredCouriers.reduce((sum, courier) => sum + (courier.balance || 0), 0)

    return { filteredCouriers, totalBalance };
  }, [couriers, search]);

  const actionsConfig = [
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/agent/update/${params.id}`),
      visible: userType === 'Админ',
    },
    // {
    //   title: 'Удалить',
    //   icon: <DeleteRoundedIcon style={{ color: '#000b1f' }} />,
    //   handler: (params) =>
    //     toast.info('Функция пока не готова, приношу свои извинения :)'),
    //   visible: userType === 'Админ',
    // },
  ];

  const columns = getAgentsColumn({ actionsConfig: actionsConfig, path: '/agent/details' })

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Cтатистика' initial='Список агентов' />
        <Title
          title={'Список агентов'}
          icon={<AssignmentRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <div className='courier-list-header'>
            <div className='orders-sorting-header'>
              <div className='couriers-search-form'>
                <SearchInput
                  label='Поиск по агентам'
                  placeholder='Имя, фамилия, номер'
                  variant='standard'
                  onChange={handleSearchCourier}
                />
              </div>
            </div>
            <BalanceCard
              title='Общий баланс агентов'
              value={search ? filteredCouriers?.totalBalance : totalBalance?.balance || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor='#10B981'
              borderColor='#10B981'
            />
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='couriers-card-parent__btns'>
                  {userType === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/agent/add')}>
                      Создать агента +
                    </Button>
                  )}
                  {/* <ExportCouriersToExcelButton data={couriers} headers={headers} fileName='Список агентов' /> */}
                </div>
              </div>
            </div>
            {isLoading ? <Loader /> :
              <OrderGrid
                orders={filteredCouriers.filteredCouriers || couriers}
                columns={columns}
                size={20}
              />}


          </div>
        </div>
      </div>
    </>
  );
};

export default Agents;
