import { getDayAgo } from "../../../../../utils/dateFormatter";
import { getCountFromServer, query, Timestamp, where } from "firebase/firestore";

const tenDaysAgo = getDayAgo(10);
const nineDaysAgo = getDayAgo(9);
const eightDaysAgo = getDayAgo(8);
const sevenDaysAgo = getDayAgo(7);
const sixDaysAgo = getDayAgo(6);
const fiveDaysAgo = getDayAgo(5);
const fourDaysAgo = getDayAgo(4);
const threeDaysAgo = getDayAgo(3);
const twoDaysAgo = getDayAgo(2);
const oneDaysAgo = getDayAgo(1);
const today = getDayAgo(0)

const fetchOrdersCount = async (orderRef, from, to) => {
  const fromTimestamp = Timestamp.fromDate(from);
  const toTimestamp = Timestamp.fromDate(to);
  const q = query(
    orderRef,
    where('dateCreated', '>=', fromTimestamp),
    where('dateCreated', '<=', toTimestamp)
  );
  return getCountFromServer(q);
};


export const fetchOrdersForChart = async (orderRef) => {
  try {
    const queries = [
      { from: tenDaysAgo, to: nineDaysAgo },
      { from: nineDaysAgo, to: eightDaysAgo },
      { from: eightDaysAgo, to: sevenDaysAgo },
      { from: sevenDaysAgo, to: sixDaysAgo },
      { from: sixDaysAgo, to: fiveDaysAgo },
      { from: fiveDaysAgo, to: fourDaysAgo },
      { from: fourDaysAgo, to: threeDaysAgo },
      { from: threeDaysAgo, to: twoDaysAgo },
      { from: twoDaysAgo, to: oneDaysAgo },
      { from: oneDaysAgo, to: today },
    ];

    const countPromises = queries.map(({ from, to }) => {
      return fetchOrdersCount(orderRef, from, to);
    });

    const todayQuery = query(
      orderRef,
      where('dateCreated', '>=', Timestamp.fromDate(today)),
    );
    const todayCountPromise = getCountFromServer(todayQuery);

    const counts = await Promise.all([...countPromises, todayCountPromise]);

    return [
      ...counts.slice(0, -1).map((count, index) => ({
        count: count.data().count,
        date: queries[index].from,
      })),
      {
        count: counts[counts.length - 1].data().count,
        date: today,
      },
    ];
  } catch (error) {
    console.error('Error fetching orders:', error.message);
  }
}