import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { storeCategorysRef } from '../../../../utils/collectionRefs';
import { uploadImage } from './helpers/uploadImage';
import { Button, TextField, Box, Backdrop, CircularProgress, Grid } from '@mui/material'
import { toast } from 'react-toastify';
import { collection, doc, setDoc } from 'firebase/firestore'
import { addToHistory } from '../../../../utils/addToHistory';
import { db } from '../../../../configs';
import { RequiredInput } from '../../../../components/Form/FormValidation';
import {  storeDetalFormData } from './constants/formData';

const StoreCaregoryAdd = ({ pageTitle }) => {
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
  })


  const handlePostStore = async (store) => {
    const userCollectionRef = collection(db, `${roleType}`, `${userName}`, 'history');
    const storessRef = doc(storeCategorysRef, `${Date.now()}`);
    const description = `${userType} ${userName} добавил категорию магазина с названием "${store.nameRu || store.nameKg}"`

    try {
      setOpen(!open);

      let imgUrl = ''
      if (store.photo[0] instanceof File) {
        imgUrl = await uploadImage(store.photo[0], store.title);
      }

      const storeData = {
        nameKg: store.nameKg,
        nameRu: store.nameRu,
        nameEn: store.nameEn,
        priority: parseFloat(store?.priority),
        icon: imgUrl,
      }
      await setDoc(storessRef, storeData);

      await addToHistory(userCollectionRef, description)

      toast.success('Категория для магазина успешно добавлена');
      navigate('/storeCategories');
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка при добавлении категории');
    } finally {
      setOpen(false)
    }
  }

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header initial='Добавить категорию' previous='Статистика' />
      <Title title='Добавить категорию' icon={<AddCircleIcon fontSize='large ' />} />
      <div className="container-inner">
        <div className="order-form">
          <form className="order-form-flex">
            <div className='order-block'>
              <div className='order-block-head'>
                <h3>Детали категории магазина</h3>
              </div>
              <div className='order-input-block'>
                <Grid container spacing={0.75}>
                  {storeDetalFormData[0].slice(0, 3).map((field) => (
                    <Grid item xs={4} key={field}>
                      <TextField
                        label={field.startsWith('name') ? `Название на ${field.slice(-2).toUpperCase()}` : `Описание на ${field.slice(-2).toUpperCase()}`}
                        variant='outlined'
                        size='small'
                        fullWidth
                        helperText={errors[field]?.message}
                        error={!!errors[field]}
                        {...register(field, RequiredInput)}
                      />
                    </Grid>
                  ))}
                </Grid>

                <TextField
                  id='outlined-basic'
                  label='Приоритет'
                  variant='outlined'
                  size='small'
                  type='number'
                  fullWidth
                  error={errors?.priority ? true : false}
                  helperText={errors ? errors?.priority?.message : ''}
                  {...register('priority', RequiredInput.required)}
                />
                <TextField
                  size='small'
                  type="file"
                  inputProps={{ accept: 'image/*' }}
                  error={errors?.address ? true : false}
                  helperText={errors ? errors?.address?.message : ''}
                  {...register('photo', RequiredInput.required)}
                />
              </div>

            </div>
          </form>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
            <Button
              onClick={handleSubmit((data) => handlePostStore(data))}
              size='large'
              variant='red-contained'
              color='primary'
            >
              Добавить категорию
            </Button>
          </Box>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </div>
  )
}

export default StoreCaregoryAdd 