
export const getTabsContent = (
  orders,
  completedOrders,
  returnedOrders,
  handleFilterChange,
  selectedDateFrom,
  selectedDateTo,
  handleDateChangeFrom,
  handleDateChangeTo,
  typeOfOrder,
  paymentPerson,
  orderStatus,
  returnedOrderStatus,
  lavanda,
  green,
  yellow,
  pink
) => {
  const totalOrders = (orders?.count || 0) + (completedOrders?.count || 0) + (returnedOrders?.count || 0);
  const totalCost = (orders?.cost || 0) + (completedOrders?.cost || 0) + (returnedOrders?.cost || 0);
  const totalRedemption = (orders?.redemption || 0) + (completedOrders?.redemption || 0) + (returnedOrders?.redemption || 0);
  return [
    {
      label: 'Активные',
      orders: orders,
      handleFilterChange: handleFilterChange,
      selectedDateFrom: selectedDateFrom,
      selectedDateTo: selectedDateTo,
      handleDateChangeFrom: handleDateChangeFrom,
      handleDateChangeTo: handleDateChangeTo,
      options: { typeOfOrder, paymentPerson, orderStatus },
      iconColor: lavanda,
    },
    {
      label: 'Завершенные',
      orders: completedOrders,
      handleFilterChange: handleFilterChange,
      selectedDateFrom: selectedDateFrom,
      selectedDateTo: selectedDateTo,
      handleDateChangeFrom: handleDateChangeFrom,
      handleDateChangeTo: handleDateChangeTo,
      options: { typeOfOrder, paymentPerson },
      iconColor: green,
    },
    {
      label: 'Возвраты',
      orders: returnedOrders,
      handleFilterChange: handleFilterChange,
      selectedDateFrom: selectedDateFrom,
      selectedDateTo: selectedDateTo,
      handleDateChangeFrom: handleDateChangeFrom,
      handleDateChangeTo: handleDateChangeTo,
      options: { typeOfOrder, paymentPerson, orderStatus: returnedOrderStatus },
      iconColor: yellow,
    },
    {
      label: 'Все заказы',
      orders: {
        count: totalOrders,    
        cost: totalCost,        
        redemption: totalRedemption, 
      },
      handleFilterChange: handleFilterChange,
      selectedDateFrom: selectedDateFrom,
      selectedDateTo: selectedDateTo,
      handleDateChangeFrom: handleDateChangeFrom,
      handleDateChangeTo: handleDateChangeTo,
      options: { typeOfOrder, paymentPerson },
      iconColor: pink,
    },
  ]
};


export const getProgressBarData = (
  totalCompletedOrders,
  totalOrders,
  totalClients,
  totalActiveOrdersToday,
  totalTodayOrders,
  green,
  lavanda,
  lavandas,
  yellow,
  pink
) => {
  const maxCompletedOrders = Math.max(totalCompletedOrders, 40000);
  const maxOrders = Math.max(totalOrders, 2000);
  const maxClients = Math.max(totalClients, 2000);
  const maxTodayOrders = Math.max(totalTodayOrders, 400);
  return [
    {
      value: totalCompletedOrders,
      pathColor: green,
      textColor: green,
      trailColor: '#CCFBEF',
      subTitle: 'Доставленные',
      article: '',
      maxValue: maxCompletedOrders,
    },
    {
      value: totalOrders,
      pathColor: lavanda,
      textColor: lavanda,
      trailColor: lavandas,
      subTitle: 'Активные',
      article: '',
      maxValue: maxOrders,
    },
    {
      value: totalClients,
      pathColor: yellow,
      textColor: yellow,
      trailColor: '#FFF3C6',
      subTitle: 'Возвраты',
      article: '',
      maxValue: maxClients,
    },
    {
      value: totalActiveOrdersToday,
      pathColor: lavanda,
      textColor: lavanda,
      trailColor: lavandas,
      subTitle: 'Заказы за сегодня',
      article: '',
      maxValue: 700,
    },
    {
      value: totalTodayOrders,
      pathColor: pink,
      textColor: pink,
      trailColor: '#f54db771',
      subTitle: 'Все заказы',
      article: '',
      maxValue: maxTodayOrders,
    },
  ]
};


export const balanceCards = (orders) => {
  return [
    {
      title: 'Количество заказов',
      value: orders?.count,
    },
    {
      title: 'Cумма заказов',
      value: orders?.cost,
    },
    {
      title: 'Cумма выкупов',
      value: orders?.redemption,
    },
  ]
};

