import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { faqRef } from '../../../../utils/collectionRefs';
import { getDocs, orderBy, query, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Slide, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, AccordionActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Loader } from '../../../../components/Loader/Loader';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import './scss/FAQ.scss';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';


const FAQ = ({ pageTitle }) => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentFaqId, setCurrentFaqId] = useState(null);
  const { control, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    mode: 'onBlur'
  });

  const fetchFAQS = useCallback(async () => {
    setIsLoading(true);
    try {
      const q = query(faqRef, orderBy('priority', 'asc'));
      const faqDocs = await getDocs(q);
      const newFaqs = faqDocs.docs.map(item => ({
        ...item.data(),
        id: item.id,
      }));
      setFaqs(newFaqs);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFAQS();
  }, [fetchFAQS]);

  const onSubmit = async (data) => {
    if (isEditMode) {
      await updateDoc(doc(faqRef, data.id), { questionRu: data.questionRu, answerRu: data.answerRu });
    } else {
      await addDoc(faqRef, { questionRu: data.questionRu, answerRu: data.answerRu, priority: faqs.length + 1 });
    }
    fetchFAQS();
    handleClose();
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(faqRef, id));
    fetchFAQS();
  };

  const handleEditClick = (faq) => {
    setIsEditMode(true);
    setValue('id', faq.id);
    setValue('questionRu', faq.questionRu);
    setValue('answerRu', faq.answerRu);
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setIsEditMode(false);
    setCurrentFaqId(null);
    setValue('id', null);
    setValue('questionRu', null);
    setValue('answerRu', null);
    setOpen(false);
  };

  const currentId = watch('id');

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous="Статистика" initial="FAQ" />
      <Title title="FAQ" icon={<HelpRoundedIcon fontSize="24px" />} />
      <div className="container-inner">
        <div className="faq-container">
          <div>
            <Button variant="black-contained" onClick={() => setOpen(true)}>
              Добавить вопрос
            </Button>
          </div>
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              faqs.map((faq, index) => (
                <Slide
                  in
                  direction="up"
                  key={faq.id}
                  timeout={{ enter: 500, exit: 300 }}
                  style={{ transitionDelay: `${index * 200}ms` }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id={`panel-${faq.id}`}
                    >
                      {faq.questionRu}

                    </AccordionSummary>
                    <AccordionDetails>
                      {faq.answerRu}
                    </AccordionDetails>
                    <AccordionActions>
                      <Button variant="black-contained" onClick={() => handleEditClick(faq)}>
                        <EditRoundedIcon fontSize='small' />
                      </Button>
                      <Button variant="black-contained" onClick={() => handleDelete(faq.id)}>
                        <DeleteRoundedIcon fontSize='small' />
                      </Button>
                    </AccordionActions>
                  </Accordion>
                </Slide>
              ))
            )}
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentId ? 'Редактировать вопрос' : 'Добавить вопрос'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Пожалуйста, заполните поля ниже для добавления или редактирования вопроса.
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="questionRu"
              control={control}
              defaultValue=""
              rules={{ required: 'Поле ответ обязательно для заполнения' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  margin="dense"
                  label="Вопрос"
                  fullWidth
                  variant="standard"
                  error={!!errors.questionRu}
                  helperText={errors.questionRu?.message}
                />
              )}
            />
            <Controller
              name="answerRu"
              control={control}
              defaultValue=""
              rules={{ required: 'Поле ответ обязательно для заполнения' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Ответ"
                  fullWidth
                  variant="standard"
                  error={!!errors.answerRu}
                  helperText={errors.answerRu?.message}
                />
              )}
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Отмена
              </Button>
              <Button type="submit" color="primary">
                {currentId ? 'Обновить' : 'Добавить'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FAQ;
