import "instantsearch.css/themes/satellite.css";
import { Hits, SearchBox, Configure } from "react-instantsearch";
import './scss/InstantSearch.scss'
import OrderList from "./Lists/OrderList";
import { orderLabels } from "../utils";

const Hit = ({ hit }) => {
  return (
    <OrderList {...hit} key={hit.id} />
  )
};

export const Search = () => {
  return (
    <>
      {/* <Configure hitsPerPage={'auto'} /> */}
      <div className="ais-InstantSearch">
        <SearchBox placeholder="Поиск активных заказов" />
        <div className="orders-list-wrapper-labels">
          {
            orderLabels.map((label, index) => (
              <div key={index}>
                <p>{label.name}</p>
              </div>
            ))
          }
        </div>
        <Hits hitComponent={Hit} />
      </div>
    </>
  );
};