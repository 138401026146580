import React, { useEffect, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import { Box, Button } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../../configs'
import { Loader } from '../../../../components/Loader/Loader'
import avatar from '../../../../assets/images/avatar.svg';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Profile = ({ pageTitle }) => {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  const USERTYPE = localStorage.getItem('userType');
  const USERNAME = localStorage.getItem('userName');
  const ROLETYPE = localStorage.getItem('roleType');

  useEffect(() => {
    const getUser = async () => {
      try {
        const docRef = doc(db, ROLETYPE, USERNAME);
        const data = await getDoc(docRef);
        return { ...data.data(), id: data.id };
      } catch (error) {
        console.error(error.message);
        return null;
      }
    };

    const fetchData = async () => {
      try {
        const user = await getUser();
        setUser(user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

  }, [ROLETYPE, USERNAME]);

  return (
    <>
      <div className="container">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Профиль' />
        <div className="container-inner">
          <div className='courier-more-wrapper'>
            <div className='courier-more-card profile'>
              {loading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <div className='courier-card-left-side'>
                    <div className='courier-card-avatar'>
                      <img
                        src={!user?.avatar ? avatar : user?.avatar}
                        alt={user?.name}
                      />
                    </div>
                    <h3>
                      {' '}
                      {user?.login} {user?.name}
                    </h3>
                  </div>
                  <div className='courier-card-right-side'>
                    <div className='courier-card-list'>
                      <span>
                        <p>Тип аккаунта:</p>
                        <p>{USERTYPE}</p>
                      </span>
                      <span>
                        <p>Имя:</p>
                        <p>{user?.name}</p>
                      </span>
                      <span>
                        <p>Телефон:</p>
                        <p>{user?.phone}</p>
                      </span>

                    </div>
                    <div className='courier-card-list client'>
                      <span>
                        <p>Логин:</p>
                        <p>{user?.login}</p>
                      </span>
                      <span
                        className={
                          !user?.active ? 'blackList' : 'notBlackList'
                        }
                      >
                        <p>Статус:</p>
                        <p>
                          {!user?.active
                            ? 'Не активный !'
                            : 'Активный'}
                        </p>
                      </span>
                    </div>

                    <div className='courier-card-list profile'>
                      <Box display='flex' gap={'4px'}>

                        <Button
                          variant='contained'
                          className='courier-more-btn'
                          onClick={() => navigate(`/userHistory/${ROLETYPE}/${user?.id}`)}
                        >
                          История
                        </Button>
                      </Box>
                    </div>
                  </div>
                </React.Fragment>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile