import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import admin from '../../../assets/images/admin.svg';
import dispatcher from '../../../assets/images/dispatcher.svg';
import accountant from '../../../assets/images/accountant.svg';
import request from '../../../assets/images/request.svg';
import './Auth.css';

const SelectRole = () => {
  const roles = [
    { id: 1, name: 'Админ', photo: admin },
    { id: 2, name: 'Менеджер', photo: dispatcher },
    { id: 3, name: 'Бухгалтер', photo: accountant },
  ];

  return (
    <>
      <div className='role-container'>
        <div className='role-container-logo'>
          <img src={logo} alt='Aylex Logo' />
        </div>
        <h2>Для входа выберите тип аккаунта</h2>
        <div className='role-cards-block'>
          {roles?.map((role) => (
            <NavLink
              to='/auth/login'
              state={{ from: role?.name }}
              className='role-cards'
              key={role.id}
            >
              <img src={role.photo} alt='*' />
              <h2>{role.name}</h2>
            </NavLink>
          ))}
        </div>
        <div className='role-cards-block'>
          <NavLink
            to='/auth/deleteRequest'
            state={{ from: 'Удаленный запрос' }}
            className='role-cards'
          >
            <img src={request} alt='*' />
            <h2>Удаленный запрос</h2>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default SelectRole;
