import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Line
} from 'recharts';

const CustomLineChart = ({ data, dataKey, yAxisKey, lines, width = '100%', height = 350, tooltipFormatter }) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart data={data} barCategoryGap='10%' stackOffset='none'>
        <CartesianGrid strokeDasharray="1 0" vertical={true} />
        <XAxis
          dataKey={dataKey}
          tick={{ fill: '#000' }}
          axisLine
          tickLine
        />
        <YAxis
          tick={{ fill: '#000' }}
          axisLine
          tickLine
        />
        <ChartTooltip
          labelFormatter={(value) => value}
          contentStyle={{ borderRadius: '8px', fontWeight: '500' }}
        />
        {lines.map((line, index) => (
          <Line
            key={index}
            type={line.type || "monotone"}
            dataKey={line.dataKey}
            stroke={line.stroke}
            fill={line.fill}
            fillOpacity={line.fillOpacity || 0.3}
            strokeWidth={line.strokeWidth || 2}
            activedot={line.activedot || { r: 8 }}
            animationDuration={line.animationDuration || 2000}
            isAnimationActive={line.isAnimationActive || true}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
