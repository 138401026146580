import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    black: {
      light: '#000b1f',
      main: '#000000',
      dark: '#000000',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'black-contained' },
          style: {
            color: '#ffffff',
            backgroundColor: '#000b1f',
            '&:hover': {
              backgroundColor: '#000b1f',
            },
          },
        },
        {
          props: { variant: 'red-contained' },
          style: {
            color: '#ffffff',
            backgroundColor: '#FF4654',
            '&:hover': {
              backgroundColor: '#c01534',
            },
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#FF4654", // Замените на нужный вам цвет
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: "#FF4654",
          },
        }
      }
    }
  },
})