import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';

const ScrollToTop = ({ isVisible }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!isVisible) return null;

  return (
    <IconButton
      onClick={scrollToTop}
      sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000, backgroundColor: 'white', borderRadius: '50%' }}
    >
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export default ScrollToTop;
