import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import HistoryList from '../../../../components/Lists/HistoryList';
import { Loader } from '../../../../components/Loader/Loader';
import noDataImage from '../../../../assets/images/no-data3.svg';
import NoData from '../../../../components/NoData/NoData';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';

import './History.scss';
import { Helmet } from 'react-helmet';
import { orderRef } from '../../../../utils/collectionRefs';

const Notes = ({ pageTitle }) => {
  const { id } = useParams();
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(orderRef, id, 'notes'),
        orderBy('date', 'desc')
      ),
      (snapshot) => {
        const notes = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setNotes(notes);
      }
    );

    return unsubscribe;
  }, [id]);

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Детали заказа' initial='Заметки заказа' />
        <Title
          title='Заметки заказа'
          icon={<AccessTimeFilledRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <div className='order-history-top'>
            <p>№</p>
            <h3>{id}</h3>
          </div>
          <div className='orders-history-wrapper'>
            {notes
              ? <HistoryList items={notes} />
              : <Loader />
            }
            {notes?.length < 1 && <NoData img={noDataImage} text='Заметок нет' />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;
