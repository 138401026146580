import React from 'react';
import './scss/OrderSort.scss';
import { MenuItem, TextField } from '@mui/material';

const OrderSort = ({ sortKey, options, defaultValue, onChange, value }) => {
  return (
    <div className='order-sort'>
      <TextField
        id='outlined-select-currency'
        select
        label={sortKey}
        fullWidth
        defaultValue={defaultValue}
        size='small'
        onChange={onChange}
      >
        <MenuItem value='Все'>Все</MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.name} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
     </div>
  )

};

export default OrderSort;
