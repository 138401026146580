import React from 'react'

const NoData = ({ img, text }) => {
  return (
    <>
      <figure>
        <svg width="100%" height="300px" style={{ "margin": "20px 0" }}>
          <image href={img} width="100%" height="100%" />
        </svg>
        <figcaption style={{ "textAlign": "center" }}>
          <h1>{!text ? "Результатов не найдено" : text}</h1>
        </figcaption>
      </figure>
    </>
  )
}

export default NoData