import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ value, pathColor, textColor, trailColor, article, maxValue }) => {
  const roundedValue = Math.round(value) ?? 0;
  const text = `${roundedValue} ${article ?? ''}`;
  
  // Уменьшаем размер шрифта в зависимости от длины текста
  const getTextSize = () => {
    if (text.length > 6) return "18px"; // Если текст длиннее 6 символов
    return "22px"; // Если текст до 4 символов
  }

  const stylesForBar = {
    pathTransition: "stroke-dashoffset 2.5s ease 0s",
    pathColor: pathColor,
    textColor: textColor,
    textSize: getTextSize(),
    trailColor: trailColor,
    fontWeight: 800,
    cursor: 'pointer'
  }
  
  return (
    <div style={{ cursor: 'default' }}>
      <CircularProgressbar
        value={!value ? 100 : value}
        text={`${roundedValue} ${article ?? ''}`}
        maxValue={maxValue}
        styles={buildStyles(stylesForBar)}
      />
    </div>
  )
}

export default CircularProgressBar