import React from 'react';
import { autoLinkDelimiter } from '../utils';

const AutoLink = React.memo(({ text }) => (
  <>
    {text?.split(autoLinkDelimiter).map((word, index) => {
      const match = word.match(autoLinkDelimiter);
      return match ? (
        <a
          href={match[0].startsWith('http') ? match[0] : `http://${match[0]}`}
          key={index}
          target="_blank"
          rel="noreferrer"
        >
          {match[0]}
        </a>
      ) : (
        word
      );
    })}
  </>
));

export default AutoLink;
