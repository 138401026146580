import React, { useCallback, useEffect, useState } from 'react';
import { getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import { MenuItem, Stack, TextField } from '@mui/material';
import { Title } from '../../../../components/Title/Title';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { Header } from '../../../../components/Header/Header';
import { citiesRef, clientsRef } from '../../../../utils/collectionRefs';
import OrderGrid from '../../../../components/OrderGrid ';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { Helmet } from 'react-helmet';
import { clientsColumn } from './helpers/clientColumns';
import { Loader } from '../../../../components/Loader/Loader';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import ExportToExcel from './../../../../components/Buttons/ExportToExcell';
import { headers } from './constants';
import { clientExcelDataFormatter } from './helpers/excelDataFormatter';
import { debounce } from 'lodash';

const Clients = ({ pageTitle }) => {
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [cities, setCities] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({
    'address.city': '',
    password: '',
  });

  const fetchDocs = async () => {
    setLoading(true);
    let q = query(clientsRef);

    if (selectedFilters['address.city'] && selectedFilters['address.city'] !== 'Все') {
      q = query(q, where('address.city', '==', selectedFilters['address.city']));
    }
    const appValue = selectedFilters['password'];
    if (appValue === 'Установлено') {
      q = query(q, where('password', '!=', ''));
    } else if (appValue === 'Не установлено') {
      q = query(q, where('password', '==', ''));
    }

    if (page > 0 && lastVisible) {
      q = query(q, startAfter(lastVisible), limit(pageSize));
    } else {
      q = query(q, limit(pageSize));
      setLastVisible(null);
    }

    try {
      let countQuery = query(clientsRef);
      if (selectedFilters['address.city'] && selectedFilters['address.city'] !== 'Все') {
        countQuery = query(countQuery, where('address.city', '==', selectedFilters['address.city']));
      }
      const countAppValue = selectedFilters['password'];
      if (countAppValue === 'Установлено') {
        countQuery = query(countQuery, where('password', '!=', ''));
      } else if (countAppValue === 'Не установлено') {
        countQuery = query(countQuery, where('password', '==', ''));
      }

      if (search) {
        countQuery = query(
          countQuery,
          where('forSearch', 'array-contains', search.toLowerCase())
        );
        q = query(
          q,
          where('forSearch', 'array-contains', search.toLowerCase())
        );
      }

      const countSnapshot = await getCountFromServer(countQuery);
      setTotalRowCount(countSnapshot.data().count);

      const querySnapshot = await getDocs(q);
      const newClients = querySnapshot.docs.map((doc) => {
        const data = doc.data()
        return {
          id: doc.id,
          cityName: data?.address?.cityName || 'Отсутствует',
          address: data?.address?.address || 'Отсутствует',
          fullName: data?.fullName || 'Отсутствует',
          phone: data?.phone || 'Отсутствует',
          password: data?.password || '',
        }
      });

      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setLastVisible(null);
      }

      setClients(newClients);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching clients:', error);
    }
  };

  const debouncedFetchDocs = useCallback(debounce(fetchDocs, 300), [selectedFilters, page, pageSize, search]);

  useEffect(() => {
    debouncedFetchDocs();
    return () => {
      debouncedFetchDocs.cancel(); // Очистка debounce при размонтировании
    };
  }, [selectedFilters, page, pageSize, search, debouncedFetchDocs]);

  useEffect(() => {
    fetchData(citiesRef, 'name', 'asc', setCities)
  }, []);

  const fetchData = async (collectionRef, sortField, sortOrder, setData) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder))
      );
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setData(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };
  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event?.target?.value);
  }

  const clientCityToSort = createOrderSortHandler('address.city');

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    setLastVisible(null);
  };

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Клиенты' />
        <Title
          title={'Список клиентов'}
          icon={<PeopleAltRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <div className='order-statistics-container'>
            <BalanceCard
              title='Общее количество клиентов'
              value={totalRowCount}
              icon={NumbersRoundedIcon}
              iconColor='#635BFF'
            />
          </div>
          <div className='orders-sorting-header flexed'>
            <SearchInput
              label='Поиск по тел'
              placeholder='Имя, тел-номер'
              variant='outlined'
              onChange={handleSearchOrder}
            />
            <div className='order-sort' style={{ maxWidth: '200px', width: '100%' }}>
              <TextField
                id='outlined-select-currency'
                select
                label='По городу'
                fullWidth
                size='small'
                defaultValue=''
                onChange={clientCityToSort}
              >
                <MenuItem value='Все'>Все</MenuItem>
                {cities?.map((option, index) => (
                  <MenuItem key={index} value={parseInt(option.id)}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {/* <div className='order-sort' style={{ maxWidth: '200px', width: '100%' }}>
              <TextField
                id='outlined-select-currency'
                select
                label='Приложение'
                fullWidth
                size='small'
                defaultValue=''
                onChange={clientAppToSort}
              >
                <MenuItem value='Все'>Все</MenuItem>
                <MenuItem value='Установлено'>Установлено</MenuItem>
                <MenuItem value='Не установлено'>Не установлено</MenuItem>
              </TextField>
            </div> */}
            <div />
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <Stack spacing={2} direction='row'>
                <ExportToExcel
                  firestoreRef={clientsRef}
                  selectedFilters={selectedFilters}
                  headers={headers}
                  search={search}
                  fileName='Список клиентов'
                  selectedDate={null}
                  selectedDate2={null}
                  dateField={null}
                  dataFormatter={clientExcelDataFormatter}
                  isClientExport={true}
                />
              </Stack>
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={clients}
                columns={clientsColumn()}
                size={pageSize}
                page={page}
                usePagination={true}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                totalRowCount={totalRowCount}
              />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
