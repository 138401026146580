export const headers = [
  'Имя',
  'Фамилия',
  'Номер',
  'Рейтинг',
  'Баланс',
  'Город',
  'Статус аккаунта',
  'Тип курьера',
];

export const typeOfOrder = [
  { id: 1, value: 'document', name: 'Документ' },
  { id: 2, value: 'medicine', name: 'Лекарство' },
  { id: 3, value: 'food', name: 'Еда' },
  { id: 4, value: 'small_box', name: 'Маленькая коробка' },
  { id: 5, value: 'box', name: 'Коробка' },
  { id: 6, value: 'large_box', name: 'Большая коробка' },
  { id: 7, value: 'Пакет', name: 'Пакет' },
  { id: 8, value: 'other', name: 'Другое' },
];

export const fieldsToSearchCourier = [
  'id',
  'name',
  'surName',
  'type',
  // 'cityInfo',
];


export const countOfOrdersCard = (totalOrders, totalCompletedOrders, totalReturnedOrders) => {
  return [
    {
      title: 'Кол-во активных заказов',
      value: totalOrders,
      borderColor: '#635BFF'
    },
    {
      title: 'Кол-во доставленных заказов',
      value: totalCompletedOrders,
      borderColor: '#15B79F'
    },
    {
      title: 'Кол-во возвратных заказов',
      value: totalReturnedOrders,
      borderColor: '#FB9C0C'
    },
  ]
};

