import { Button } from "@mui/material";
import { headers } from "../../apps/Layout/Pages/Cities/constants";
import * as XLSX from 'xlsx';

export const ExportCitiesToExcellButton = ({ ordersData, fileName }) => {
  const exportToExcel = () => {
    const data = ordersData?.map((city) => [
      !city.id ? 'Отсутствует' : city.id,
      !city.name ? 'Отсутствует' : city.name,

    ]);
    const finalData = [headers, ...data];
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    ws['!cols'] = Array(finalData[0].length).fill({ wch: 22 });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <Button variant='black-contained' onClick={exportToExcel}>
      Экспорт в Excel
    </Button>
  );
};