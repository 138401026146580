import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';

export const clientsColumn = () => {
  return [
    {
      flex: 1,
      field: 'fullName',
      sortable: false,
      renderHeader: () => <strong>Имя</strong>,
      disableColumnMenu: false,
      minWidth: 130,
    },
    {
      flex: 1,
      field: 'phone',
      sortable: false,
      renderHeader: () => <strong>Телефон</strong>,
      minWidth: 130,
    },
    {
      flex: 1,
      field: 'cityName',
      sortable: false,
      renderHeader: () => <strong>Город</strong>,
      minWidth: 130,
    },
    {
      flex: 1,
      field: 'address',
      sortable: false,
      renderHeader: () => <strong>Адрес</strong>,
      minWidth: 130,
    },
    {
      flex: 0.7,
      field: 'password',
      sortable: false,
      minWidth: 130,  
      renderCell: (params) => {
        const hasPassword = params.row?.password;
        const appStatus = !hasPassword  ? 'red' : 'green';
        return (
          <strong style={{ color: appStatus }}>
            {!hasPassword ? 'Не установлено' : 'Установлено'}
          </strong>
        );
      },
      renderHeader: () => <strong>Приложение</strong>,
    },
    {
      field: 'actions',
      sortable: false,
      flex: 0.5,
      menubar: false,
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <Tooltip title={'Детали'} arrow>
            <Link to={`/client/details/${params.id}`}>
              <IconButton aria-label='edit'>
                <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Link>
          </Tooltip>

        </div>
      ),
      renderHeader: () => <strong>Действия</strong>,
    },
  ]
}