import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, TextField, Box, Backdrop, CircularProgress, Autocomplete, Stack, Grid, FormGroup, FormControlLabel, Checkbox, Avatar, Typography, Tooltip } from '@mui/material'
import { toast } from 'react-toastify';
import {  collection, doc, getDoc, getDocs, orderBy, query,  updateDoc } from 'firebase/firestore'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { storeCategorysRef, storesRef } from '../../../../utils/collectionRefs';
import { Title } from '../../../../components/Title/Title';
import { db } from '../../../../configs';
import { RequiredInput } from '../../../../components/Form/FormValidation';
import { russianDaysOfWeek, storeDetalFormData } from './constants/formData';
import { uploadImage } from './helpers/uploadImage';
import { Loader } from '../../../../components/Loader/Loader';
import { addToHistory } from './../../../../utils/addToHistory';

const fetchData = async (ref, setState, setLoading) => {
  try {
    setLoading(true);
    const docSnap = await getDoc(ref);
    setState(docSnap.data());
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setLoading(false);
  }
};


const StoreEdit = ({ pageTitle }) => {
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const { id } = useParams()
  const [open, setOpen] = useState(false);
  const [store, setStore] = useState(null)
  const [categories, setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()
  const [newImage, setNewImage] = useState(null)
  const inputFileRef = useRef(null);

  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      insta: store?.insta,
      address: store?.address,
      priority: parseFloat(store?.priority) || 0,
      category: store?.category,
    }
  })

  const fetchCategories = useCallback(async () => {
    setIsLoading(true)
    try {
      const q = query(storeCategorysRef, orderBy('priority', 'asc'));
      const faqDocs = await getDocs(q);
      const newFaqs = faqDocs.docs.map(item => ({
        ...item.data(),
        id: item.id,
      }));
      setCategories(newFaqs);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    } finally {
      setIsLoading(false)
    }
  }, []);

  useEffect(() => {
    const fetchStore = async () => {
      const storeRef = doc(storesRef, id);
      await fetchData(storeRef, setStore, setIsLoading);
    };
    fetchStore();
    fetchCategories();
  }, [id]);

  useEffect(() => {
    if (store) {
      setValue('insta', store.insta || '');
      setValue('address', store.address || '');
      setValue('priority', store.priority || '0');
      setValue('category', store.category || '');
    }
  }, [store, setValue]);

  const handleEditStore = async (data) => {
    const userCollectionRef = collection(db, `${roleType}`, `${userName}`, 'history');
    const storessRef = doc(storesRef, id);
    const description = `${userType} ${userName} отредактировал магазин с названием "${data.nameRu || data.nameKg}"`

    try {
      setOpen(!open);

      let imgUrl = store?.logo
      if (newImage instanceof File) {
        imgUrl = await uploadImage(newImage, newImage.name);
      }

      const workingDays = storeDetalFormData[1].filter(day => data[day]);

      const storeData = {
        address: data.address,
        descEn: data.descEn,
        descRu: data.descRu,
        descKg: data.descKg,
        nameKg: data.nameKg,
        nameRu: data.nameRu,
        nameEn: data.nameEn,
        insta: data.insta,
        priority: parseFloat(data.priority),
        category: data.category,
        workingHours: `${data.hourFrom}-${data.hourTo}`,
        hourFrom: data.hourFrom,
        hourTo: data.hourTo,
        workingDays,
        logo: imgUrl ?? data?.logo,
      }

      await updateDoc(storessRef, storeData);

      await addToHistory(userCollectionRef, description)

      toast.success('Магазин успешно отредактирован');
      navigate('/stores');
    } catch (error) {
      console.error(error)
      toast.error('Произошла ошибка при редактировании магазина');
    } finally {
      setOpen(false)
    }
  }
  const handleImageClick = () => {
    inputFileRef.current.click();
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]);
  };
  return (
    <>
      <div className="container">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Статистика'} initial={'Добавление магазина'} />
        <Title title={'Добавление магазина'} icon={<AddCircleIcon fontSize='meduim' />} />
        <div className="container-inner">
          {isLoading ? <Loader /> : (
            <>

              <div className="order-form">
                <form className="order-form-flex">
                  <div className='order-block'>
                    <div className='order-block-head'>
                      <h3>Детали магазина</h3>
                    </div>
                    <div className='order-input-block'>
                      <Grid container spacing={0.75}>
                        {storeDetalFormData[0].map((field) => (
                          <Grid item xs={4} key={field}>
                            <TextField
                              label={field.startsWith('name') ? `Название на ${field.slice(-2).toUpperCase()}` : `Описание на ${field.slice(-2).toUpperCase()}`}
                              variant='outlined'
                              size='small'
                              fullWidth
                              defaultValue={store?.[field]}
                              helperText={errors[field]?.message}
                              error={!!errors[field]}
                              {...register(field, RequiredInput)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {['insta', 'address'].map(field => (
                        <TextField
                          key={field}
                          label={field === 'insta' ? 'Ссылка на instagram' : 'Адрес магазина'}
                          variant='outlined'
                          size='small'
                          fullWidth
                          defaultValue={store?.[field]}
                          helperText={errors[field]?.message}
                          error={!!errors[field]}
                          {...register(field, RequiredInput)}
                        />
                      ))}
                      <TextField
                        id='outlined-basic'
                        label='Приоритет'
                        variant='outlined'
                        size='small'
                        type='number'
                        fullWidth
                        defaultValue={parseFloat(store?.priority)}
                        error={errors?.priority ? true : false}
                        helperText={errors ? errors?.priority?.message : ''}
                        {...register('priority', RequiredInput.required)}
                      />

                      <Controller
                        name='category'
                        control={control}
                        defaultValue={store?.category}
                        rules={{ required: 'Объязательное поле' }}
                        render={({ field }) => (
                          <Autocomplete
                            id='category'
                            disablePortal
                            options={categories || []}
                            getOptionLabel={(option) => option.nameRu}
                            onChange={(_, val) => field.onChange(val)}
                            value={field.value || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={`random-${Math.random().toString(36).substring(7)}`}
                                label='Категория'
                                variant='outlined'
                                size='small'
                                error={!!errors?.category}
                                helperText={errors?.category?.message}
                              />
                            )}
                          />
                        )}
                      />
                      <input
                        size='small'
                        type="file"
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <div className='order-block'>
                    <div className='order-block-head'>
                      <h3>Рабочее время</h3>
                    </div>
                    <div className='order-input-block'>
                      <Stack direction='row' gap={1} justifyContent='space-between'>
                        <FormGroup>
                          {storeDetalFormData[1].map((day) => (
                            <FormControlLabel
                              key={day}
                              control={<Checkbox {...register(day)} defaultChecked={store?.workingDays?.includes(day)} />}
                              label={russianDaysOfWeek[day]}
                            />
                          ))}
                        </FormGroup>
                        <Stack direction='column' justifyContent='space-between'>
                          <Stack direction='row' gap={1}>
                            {storeDetalFormData[2].map((field) => (
                              <TextField
                                key={field}
                                defaultValue={store?.[field]}
                                type='time'
                                size='small'
                                label={field === 'hourFrom' ? 'Начало' : 'Конец'}
                                InputProps={{ sx: { width: '110px' } }}
                                {...register(field, RequiredInput)}
                              />
                            ))}
                          </Stack>
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            gap={1}
                            mb={2}
                          >
                            <Typography variant='p'>Фото магазина:</Typography>
                            <Tooltip title="Нажмите, чтобы изменить изображение" arrow>
                              <Avatar
                                src={newImage ? URL.createObjectURL(newImage) : store?.logo}
                                alt={store?.nameKg}
                                variant='rounded'
                                onClick={handleImageClick}
                                sx={{
                                  width: 150,
                                  height: 120,
                                  boxShadow: 'rgb(0 0 0 / 8 %) 0px 1px 2px',
                                  border: '1px solid #00000014',
                                  cursor: 'pointer'
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Stack>
                      </Stack>
                    </div>
                  </div>
                </form>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
                  <Button
                    onClick={handleSubmit((data) => handleEditStore(data))}
                    size='large'
                    variant='red-contained'
                    color='primary'
                  >
                    Сохранить
                  </Button>
                </Box>
              </div>

            </>

          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div >
    </>
  )
}

export default StoreEdit