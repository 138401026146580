import React from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const SearchInput = ({ onChange, label, placeholder, variant, value }) => (
  <div>
    <TextField
      id='standart'
      size='small'
      type='search'
      label={label}
      variant={variant}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      fullWidth
      sx={{ minWidth: '150px' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    {/* <TextField
      label={label}
      variant="outlined-search"
      placeholder={placeholder}
      sx={{ borderRadius: '20px' }}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    /> */}
  </div>
);

export default SearchInput;
