import React, { useEffect, useMemo, useState } from 'react';
import { count, getAggregateFromServer, getDocs, orderBy, query, sum, where, } from 'firebase/firestore';
import { orderStatus, typeOfOrder, statusLabels, alternativeStatusLabels, paymentPerson, } from '../../../../utils/index';
import { Stack, Button, MenuItem, TextField, } from '@mui/material';
import { Title } from '../../../../components/Title/Title';
import { Header } from '../../../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import {  cityOrdersRef, villageRef } from '../../../../utils/collectionRefs';
import OrderSort from '../../../../components/OrderSort';
import { fieldsToSearchOrder, headers } from '../Orders/constants';
import OrderGrid from '../../../../components/OrderGrid ';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import OrderStatistics from '../../../../components/OrderStatistics';
import { Helmet } from 'react-helmet';
import ExportToExcelButton from '../../../../components/Buttons/ExportToExcell';
import { handleCopyOrder } from '../Orders/helpers/handleCopyOrder';
import { handleChangeStatus } from '../Orders/helpers/handleChangeStatus';
import { orderColumnsCity } from '../Orders/helpers/orderColumns';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { Loader } from '../../../../components/Loader/Loader';

const CityOrder = ({ pageTitle }) => {
  const [orders, setOrders] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [cities, setCities] = useState(null);
  const [search, setSearch] = useState('');
  const [aggregates, setAggregates] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState({
    cityTo: '',
    cityFrom: '',
    packageType: '',
    status: '',
    whoPays: '',
  });
  const USERTYPE = localStorage.getItem('userType');
  const navigate = useNavigate();

  const clearFilter = () => window.location.reload();

  useEffect(() => {
    let q = query(cityOrdersRef, orderBy('dateCreated', 'desc'));

    Object.keys(selectedFilters).forEach((field) => {
      const value = selectedFilters[field];

      if (value !== 'Все' && value !== '') {
        q = query(q, where(field, '==', value));
      }
    });

    if (selectedDate && selectedDate2) {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate2);
      endOfDay.setHours(23, 59, 59, 999);
      q = query(
        q,
        where('dateCreated', '>=', startOfDay),
        where('dateCreated', '<=', endOfDay)
      );
    }

    const fetchDocs = async () => {
      try {
        setLoading(true)
        const snapshot = await getAggregateFromServer(q, {
          count: count(),
          totalCost: sum('cost'),
          totalRedemption: sum('redemption'),
        })
        setAggregates({ ...snapshot.data() })

        const querySnapshot = await getDocs(q);
        const newOrders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        setOrders(newOrders);
        setLoading(false)

        setAggregates(prevAggregates => ({
          ...prevAggregates,
        }));
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false)

      }
    };

    fetchDocs();
  }, [selectedFilters, selectedDate, selectedDate2]);

  useEffect(() => {
    fetchData(villageRef, 'name', 'asc', setCities);
    // fetchData(managerRef, 'name', 'asc', setManagers);
  }, []);

  const fetchData = async (collectionRef, sortField, sortOrder, setData) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder), where('district', '==', 1))
      );
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setData(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };
  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event.target.value);
  }
  const orderCityToSort = createOrderSortHandler('cityTo');
  const orderCityFromSort = createOrderSortHandler('cityFrom');
  const orderTypeSort = createOrderSortHandler('packageType');
  const orderStatusSort = createOrderSortHandler('status');
  const orderWhoPaysSort = createOrderSortHandler('whoPays');

  const handleDateChange = (field, value) => {
    if (field === 'selectedDate') {
      setSelectedDate(value);
    } else if (field === 'selectedDate2') {
      setSelectedDate2(value);
    }
  };

  const handleDateChange1 = (e) => handleDateChange('selectedDate', e.target.value);
  const handleDateChange2 = (e) => handleDateChange('selectedDate2', e.target.value);

  const handleStatusChange = async (id, newStatus) => {
    await handleChangeStatus({
      orders: orders,
      id,
      newStatus,
      dataRef: cityOrdersRef,
      statusLabels,
      alternativeStatusLabels,
    });
  };

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/cityOrder/update/${params.id}`),
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const filteredData = useMemo(() => {
    if (!orders) return { filteredOrders: [], aggregates: { count: 0, totalCost: 0, totalRedemption: 0 } };
    const filteredOrders = orders.filter((order) =>
      fieldsToSearchOrder.some((field) => {
        const value = order[field];
        return value && value.toLowerCase().includes(search.toLowerCase());
      })
    );

    const aggregates = {
      count: filteredOrders.length,
      totalCost: filteredOrders.reduce((sum, order) => sum + (order.cost || 0), 0),
      totalRedemption: filteredOrders.reduce((sum, order) => sum + (order.redemption || 0), 0),
    };

    return { filteredOrders, aggregates };
  }, [orders, search]);

  const columns = orderColumnsCity({ actionsConfig: actionsConfig, path: '/cityOrder/details', editable: true, });

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Список заказов' />
        <Title
          title={'Список заказов'}
          icon={<AssignmentRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <OrderStatistics aggregates={search ? filteredData.aggregates : aggregates} />
          <div className='orders-sorting-header clear-btn'>
            <Button variant='black' onClick={clearFilter}>
              Очистить
            </Button>
            <Stack
              sx={{ width: '100%', flexWrap: 'wrap' }}
              direction='row'
              gap={2}
            >
              <OrderSort
                sortKey='По типу'
                defaultValue=''
                options={typeOfOrder}
                onChange={orderTypeSort}
              />
              <OrderSort
                sortKey='Кто оплачивает'
                defaultValue=''
                options={paymentPerson}
                onChange={orderWhoPaysSort}
              />
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Откуда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityFromSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Куда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityToSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <OrderSort
                sortKey='По статусу'
                defaultValue=''
                options={orderStatus}
                onChange={orderStatusSort}
              />
              <div className='order-sort order-sort-date'>
                <label htmlFor='orderDate' className='orders-sort-date-label'>
                  От
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate'
                    id='orderDate'
                    value={selectedDate || ''}
                    onChange={handleDateChange1}
                  />
                </label>
                <span></span>
                <label htmlFor='orderDate2' className='orders-sort-date-label'>
                  До
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate2'
                    id='orderDate2'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                  />
                </label>
              </div>
            </Stack>
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  {USERTYPE === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/cityOrder/add')}>
                      Новый заказ +
                    </Button>
                  )}
                  <ExportToExcelButton data={orders} headers={headers} fileName="Список заказов" />
                </div>
                <div style={{ width: '41%' }}>
                  <SearchInput
                    label='Поиск'
                    placeholder='Введите'
                    variant='outlined'
                    onChange={handleSearchOrder}
                  />
                </div>
              </div>
            </div>
            {loading ? <Loader /> : <OrderGrid orders={filteredData.filteredOrders || orders} columns={columns} size={20} handleChangeStatus={handleStatusChange} />}
          </div>
        </div>
      </div >
    </>
  );
};

export default CityOrder;