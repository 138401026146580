import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import OrderGrid from '../../../../components/OrderGrid ';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { kassaColumn } from './constants/getKassaColumn';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import axios from 'axios';

const Kassa = ({ pageTitle }) => {
  const navigate = useNavigate()
  const USERTYPE = localStorage.getItem('userType');

  const [kassa, setKassa] = useState([])
  const [aggregates, setAggregates] = useState(null)
  const [loading, setLoading] = useState()

  const fetchKassaFromApi = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://aylex.ru/api/core/kassa/');
      setAggregates({ count: response?.data?.count })
      setKassa(response?.data?.results);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Kassa from API:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchKassaFromApi();
  }, [fetchKassaFromApi]);

  const columns = kassaColumn()

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Список касс' />
      <Title
        title='Список касс'
        icon={<CurrencyExchangeIcon fontSize='meduim' />}
      />
      <div className="container-inner">
        <div className='order-statistics-container'>

          <BalanceCard
            title='Общее количество касс'
            value={aggregates?.count}
            icon={NumbersRoundedIcon}
            iconColor='#635BFF'
          />
          {/* <BalanceCard
            title='Общий баланс касс'
            value={aggregates?.totalBalance || 0}
            icon={NumbersRoundedIcon}
            iconColor='#635BFF'
          /> */}
        </div>

        <div className='orders-card-wrapper'>
          <div className='orders-card-header'>
            <div className='orders-card-parent'>
              <div className='orders-card-parent__btns'>
                {USERTYPE === 'Админ' && (
                  <Button variant='black-contained' onClick={() => navigate('/kassa/add')}>
                    Новая касса +
                  </Button>
                )}
                {/* <ExportToExcel
                  firestoreRef={kassaRef}
                  selectedFilters={{}}
                  headers={headersForKassa}
                  selectedDate={''}
                  selectedDate2={''}
                  fileName="Список касс"
                  dateField="dateCreated"
                  dataFormatter={kassaExcelDataFormatter}
                /> */}
              </div>

            </div>
          </div>
          <OrderGrid
            orders={kassa}
            columns={columns}
            loading={loading}
            size={20}
            usePagination={false}
          />

        </div>
      </div>
    </div>
  )
}

export default Kassa