import { packageTypeLabels, statusLabels } from "../../../../../utils";
import { dateFormatter } from "../../../../../utils/dateFormatter";
import { returnedOrderStatusLabels } from "../constants";

export const orderExcelDataFormatter = ((order) => {
  const dateCreated = dateFormatter(order.dateCreated?.seconds);
  const cost = order.cost ? `${order.cost}` : `${order.tariff?.cost}`;
  const whoPays = (() => {
    switch (order.whoPays) {
      case 1: return 'Отправитель';
      case 2: return 'Получатель';
      default: return 'Совместно';
    }
  })();
  
  return [
    dateCreated,
    order.pid || 'Отсутствует',
    order.courierOne || 'Отсутствует',
    order.courierTwo || 'Отсутствует',
    statusLabels[order.status],
    packageTypeLabels[order.packageType],
    order.addressFrom?.cityName || null,
    `${order.senderName}, (${order.sender})`,
    order.addressTo?.cityName || null,
    `${order.receiverName}, (${order.receiverPhone})`,
    cost,
    `${order.redemption}`,
    whoPays,
    order.senderPays === null ? '0.0' : `${order.senderPays}`
  ]
})
export const completedOrderExcelDataFormatter = ((order) => {
  const dateCreated = dateFormatter(order.dateCreated?.seconds);
  const dateDelivered = order.dateDelivered ? dateFormatter(order.dateDelivered?.seconds) : 'Не доставлен';
  const cost = order.cost ? `${order.cost}` : `${order.tariff?.cost}`;
  const whoPays = (() => {
    switch (order.whoPays) {
      case 1: return 'Отправитель';
      case 2: return 'Получатель';
      default: return 'Совместно';
    }
  })();

  return [
    dateCreated,
    order.dateDelivered ? dateDelivered : null,
    order.pid || 'Отсутствует',
    order.courierOne || 'Отсутствует',
    order.courierTwo || 'Отсутствует',
    statusLabels[order.status],
    packageTypeLabels[order.packageType],
    order.addressFrom?.cityName || null,
    `${order.senderName}, (${order.sender})`,
    order.addressTo?.cityName || null,
    `${order.receiverName}, (${order.receiverPhone})`,
    cost,
    `${order.redemption}`,
    whoPays,
    order.senderPays === null ? '0.0' : `${order.senderPays}`
  ]
})
export const returnedOrderExcelDataFormatter = ((order) => {
  const dateCreated = dateFormatter(order.dateCreated?.seconds);
  const dateDelivered = order.dateDelivered ? dateFormatter(order.dateDelivered?.seconds) : 'Не доставлен';
  const cost = order.cost ? `${order.cost}` : `${order.tariff?.cost}`;
  const whoPays = (() => {
    switch (order.whoPays) {
      case 1: return 'Отправитель';
      case 2: return 'Получатель';
      default: return 'Совместно';
    }
  })();

  return [
    dateCreated,
    order.dateDelivered ? dateDelivered : null,
    order.pid || 'Отсутствует',
    order.courierOne || 'Отсутствует',
    order.courierTwo || 'Отсутствует',
    returnedOrderStatusLabels[order.status],
    packageTypeLabels[order.packageType],
    order.addressFrom?.cityName || null,
    `${order.senderName}, (${order.sender})`,
    order.addressTo?.cityName || null,
    `${order.receiverName}, (${order.receiverPhone})`,
    cost,
    `${order.redemption}`,
    whoPays,
    order.senderPays === null ? '0.0' : `${order.senderPays}`
  ]
})