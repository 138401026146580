import React from 'react';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import BalanceCard from './BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';

const OrderStatistics = ({ aggregates, totalOrdersForToday, totalDebts }) => {
  return (
    <div className='order-statistics-container order-statistics-container-two'>
      {!totalOrdersForToday ? null : (
        <BalanceCard
          title='Заказы за сегодня'
          value={aggregates.totalOrdersForToday || 0}
          icon={NumbersRoundedIcon}
          iconColor='#0e9d06'
        />
      )}
      <BalanceCard
        title='Общее количество заказов'
        value={aggregates.count || 0}
        icon={NumbersRoundedIcon}
        iconColor='#635BFF'
      />
      <BalanceCard
        title='Общая сумма заказов'
        value={aggregates.totalCost || 0}
        icon={MonetizationOnRoundedIcon}
        iconColor='#10B981'
      />
      <BalanceCard
        title='Общая сумма выкупов'
        value={aggregates.totalRedemption || 0}
        icon={CurrencyExchangeRoundedIcon}
        iconColor='#3A46D6'
      />
      {!totalDebts ? null : (
        <BalanceCard
          title='Общая сумма долгов'
          value={aggregates.totalDebts || 0}
          icon={CurrencyExchangeRoundedIcon}
          iconColor='#f70303'
        />
      )}
    </div>
  );
};

export default OrderStatistics;
