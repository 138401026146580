import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import * as AuthPages from '../../apps/Auth';
import { useAuth } from '../../provider/useAuth';

const AuthRoutes = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate('/');
  //   } else {
  //     return
  //   }
  // }, [currentUser]);
  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/user-type');
    } else {
      navigate('/');
    }
  }, [currentUser]);

  return (
    <Routes>
      <Route path='/auth/user-type' element={<AuthPages.SelectRole />} />
      <Route path='/auth/login' element={<AuthPages.Auth />} />
      <Route path='/auth/deleteRequest' element={<AuthPages.DeleteRequest />} />
    </Routes>
  );
};
 
export default AuthRoutes;
