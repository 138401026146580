import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiMoreHorizontal } from 'react-icons/fi'
import { db } from '../../configs';
import copy from 'clipboard-copy';
import { dateFormatter, dateFormatter2 } from '../../utils/dateFormatter';
import { alternativeStatusLabels, orderStatus, packageTypeLabels, paymentMethods, statusLabels } from '../../utils';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import './scss/Order.scss'
import {
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  collection,
} from 'firebase/firestore';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Zoom
} from '@mui/material';
import CustomDialog from '../CustomDialog/CustomDialog';
import { toast } from 'react-toastify';
import { getStatusClass } from '../../apps/Layout/Pages/Orders/helpers/getStatusClass';
import { handleCopyOrder } from './../../apps/Layout/Pages/Orders/helpers/handleCopyOrder';
import { updateOrderStatus } from './../../apps/Layout/Pages/Orders/helpers/updateOrderStatus';
import { addToHistory } from '../../utils/addToHistory';


function MenuListTooltip({ title, onClick, icon }) {
  return (
    <Tooltip title={title} placement='left-start' TransitionComponent={Zoom}>
      <MenuItem onClick={onClick} className="order-card-actions-menu">
        {icon}
      </MenuItem>
    </Tooltip>
  );
}

const OrderList = (order) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [statusPopUp, setStatusPopUp] = useState(false)
  const [statusValue, setStatusValue] = useState('')
  const openAction = Boolean(anchorEl)

  const USERTYPE = localStorage.getItem('userType');
  const USERNAME = localStorage.getItem('userName');
  const ROLETYPE = localStorage.getItem('roleType');

  const iconStyle = {
    color: '#000b1f',
  };

  const deleteOrder = async (id) => {
    try {
      await deleteDoc(doc(db, 'orders', id));

      toast.success('Заказ успешно удален', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      await addDoc(collection(db, 'orders', id, 'history'), {
        date: new Date(),
        description: 'Админ удалил заказ'
      });
    } catch (error) {
      toast.error('Произошла ошибка при удалении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      setOpen(false);
    }
  };

  const handleClickOpen = () => setOpen(true);

  const handleOpen = (event) => setAnchorEl(event.currentTarget)

  const handleClosePopUp = () => {
    setAnchorEl(null)
    setOpen(false);
  };

  const handleOpenStatusPopUp = () => setStatusPopUp(true)

  const handleCloseStatusPopUp = () => setStatusPopUp(false)

  const handleChangeStatus = async () => {
    try {
      setStatusPopUp(false);
      const orderRef = doc(db, 'orders', order?.objectid);
      const historyRef = collection(orderRef, 'history');
      const userHistoryRef = collection(db, ROLETYPE, USERNAME, 'history');
      const description = `${USERTYPE} ${USERNAME} изменил статус заказа из "${statusLabels[order?.status]}" ➜ "${statusValue}"`;

      await Promise.all([
        updateOrderStatus(orderRef, alternativeStatusLabels[statusValue]),
        addToHistory(historyRef, description),
        addToHistory(userHistoryRef, description),
      ]);
    } catch (error) {
      console.error(error.message);
    }
  };


  const getWhoPaysText = (whoPays) => {
    let whoPaysText;

    switch (whoPays) {
      case 1:
        whoPaysText = 'Отправитель';
        break;
      case 2:
        whoPaysText = 'Получатель';
        break;
      default:
        whoPaysText = 'Совместно';
        break;
    }

    return whoPaysText;
  };

  return (
    <>
      <div
        className='orders-card'
        onDoubleClick={() => navigate(`/order/details/${order?.objectID}`)}
      >
        <div className="orders-card-values">
          <time>{dateFormatter2(order?.dateCreated)}</time>
        </div>
        <div className="orders-card-values">
          <p>{order?.pid}</p>
        </div>
        <div className="orders-card-values">
          <p>{order?.courierOne || 'Отстутствует'}</p>
        </div>
        <div className="orders-card-values">
          <p>{order?.courierTwo || 'Отстутствует'}</p>
        </div>
        <div onClick={handleOpenStatusPopUp} className={getStatusClass(order?.status)}>
          <p>{statusLabels[order.status]}</p>
        </div>
        <div className='orders-card-values'>
          <p>{packageTypeLabels[order?.packageType]}</p>
        </div>
        <div className='orders-card-values'>
          <span> {order?.addressFrom?.cityName ?? ''}</span>
        </div>
        <div className='orders-card-values'>
          <span> {order?.senderName}</span>
          <span>{order?.sender}</span>
        </div>
        <div className='orders-card-values'>
          <span> {order?.addressTo?.cityName ?? ''}</span>
        </div>
        <div className='orders-card-values'>
          <span> {order?.receiverName}</span>
          <span>{order?.receiver}</span>
        </div>
        <div className='orders-card-values'>
          <p>{order?.cost}с</p>
        </div>
        <div className='orders-card-values'>
          <p>{order?.redemption}с</p>
        </div>
        <div className='orders-card-values'>
          <p>{getWhoPaysText(order?.whoPays)}</p>
        </div>
        <div className='orders-card-values'>
          <p>{paymentMethods[order?.paymentMethod]}</p>
        </div>
        <div className='orders-card-values'>
          <p>{order?.senderPays === null ? 0.0 : order?.senderPays}</p>
        </div>
        <div className="order-card-actions">
          <IconButton
            id="long-button"
            aria-haspopup="true"
            aria-controls={openAction ? 'long-menu' : undefined}
            aria-expanded={openAction ? 'true' : undefined}
            className="order-card-actions-btn"
            onClick={handleOpen}
          >
            <FiMoreHorizontal />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleClosePopUp}
            PaperProps={{
              style: {
                width: '5ch',
              },
            }}
          >
            <MenuListTooltip icon={<DeleteRoundedIcon style={iconStyle} />} title="Удалить заказ" onClick={() => toast.info('Скоро...')} />
            <MenuListTooltip icon={<NoteAltRoundedIcon style={iconStyle} />} title="Редактировать заказ" onClick={() => navigate(`/order/update/${order?.objectID}`)} />
            <MenuListTooltip icon={<FileCopyRoundedIcon style={iconStyle} />} title="Копировать заказ" onClick={() => toast.info('Скоро...')} />
            <MenuListTooltip icon={<AccessTimeFilledRoundedIcon style={iconStyle} />} title="История заказа" onClick={() => navigate(`/order/history/orders/${order?.objectID}`)} />
            <MenuListTooltip
              icon={<RemoveRedEyeRoundedIcon style={iconStyle} />}
              title="Подробности заказа"
              onClick={() => navigate(`/order/details/${order?.objectID}`)}
            />
          </Menu>
        </div>
      </div>
      <Dialog open={statusPopUp} onClose={handleCloseStatusPopUp}>
        <DialogTitle>Нажмите на поле чтобы изменить статус заказа</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-select-currency"
            select
            fullWidth
            defaultValue={order?.status}
            size='small'
            onChange={e => setStatusValue(e.target.value)}
          >
            {orderStatus.map((status) => (
              <MenuItem key={status.name} value={status.value}>
                {status.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusPopUp}>Отменить</Button>
          <Button onClick={handleChangeStatus}>Подтвердить</Button>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={open}
        onClose={handleClosePopUp}
        event={() => deleteOrder(order?.objectid)}
        text="Вы уверены что хотите удалить заказ?"
        btnText="Удалить"
      />
    </>
  )
}

export default OrderList