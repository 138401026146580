import React from "react";
import { Link } from "react-router-dom";
import { Tooltip, IconButton } from '@mui/material';
import { RemoveRedEyeRounded } from '@mui/icons-material';
import { dateFormatter } from "../../../../../utils/dateFormatter";

const ActionIconButton = ({ title, icon, onClick }) => (
  <Tooltip title={title} arrow>
    <IconButton color='primary' aria-label='edit' onClick={onClick}>
      {React.cloneElement(icon, { style: { color: '#000b1f' } })}
    </IconButton>
  </Tooltip>
);


export const getAgentsColumn = ({ actionsConfig, path }) => {
  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        {!actionsConfig ? null : (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`${path}/${params.id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRounded style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )}
      </div>
    ),
    renderHeader: () => <strong>{!actionsConfig ? '' : 'Действия'}</strong>,
  };
  return [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`${path}/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRounded style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip >
      )
    },
    {
      flex: 1,
      headerName: 'Дата создания',
      field: 'dateCreated',
      sortable: true,
      minWidth: 130,
      cellClassName: 'centered-cell',
      headerAlign: 'center',
      renderCell: (params) => {
        return <div>{dateFormatter(params.row.dateCreated?.seconds)}</div>;
      },
    },
    {
      flex: 1,
      headerName: 'Телефон',
      field: 'phone',
      sortable: true,
      minWidth: 130,
      cellClassName: 'centered-cell',
      headerAlign: 'center',
    },
    {
      flex: 1,
      field: 'name',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Имя</strong>,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
    },
    {
      flex: 1,
      field: 'surName',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Фамилия</strong>,
      cellClassName: 'centered-cell',
      headerAlign: 'center',
    },
    {
      flex: 1,
      field: 'balance',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Баланс</strong>,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
    },
    actionsColumn,
  ];

} 