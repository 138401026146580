import { onAuthStateChanged } from 'firebase/auth'
import React, { createContext, useEffect, useState } from 'react'
import { auth } from '../configs'
// import { app, auth } from './../configs/index';

export const AuthContext = createContext({
  currentUser: false,
  userRole: null,
  isLoading: true,
  setLoading: () => { },
})

export const AuthProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(true)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setLoading(false)
      setCurrentUser(!!currentUser)
    })

    return unsubscribe
  }, [])

  const value = React.useMemo(() => ({ currentUser, isLoading, setLoading }), [
    currentUser,
    isLoading

  ])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}

