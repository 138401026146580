import { Box, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import not404Found from '../../../../assets/images/404NotFound.svg'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className='container'>
      <div className='container-inner'>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={4}
          height='100vh'
        >
          <Box
            component="img"
            sx={{
              height: [233, 433],
              width: [350, 650],
              maxWidth: 650,
            }}
            alt="404 ошибка нет такой страницы"
            src={not404Found}
          />
          <Button variant='outlined' color='primary' size='medium' onClick={() => navigate('/')}>
            На главную страницу
          </Button>
        </Box>
      </div>
    </div >
  )
}
