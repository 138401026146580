import React from 'react';
import './Header.scss';
import { MdNavigateNext } from 'react-icons/md';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Header = ({ previous, initial }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className='main-header'>
        <div className='header-page-navigation'>
          <div>
            <h3>{previous}</h3>
            <MdNavigateNext />
            <h3>{initial}</h3>
          </div>
        </div>
        <Tooltip title='Часто задавеамы вопросы' placement='left' arrow placeholder=''>
          <IconButton onClick={() => navigate('/faq')}>
            <HelpRoundedIcon fontSize='large' />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};
