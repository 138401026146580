import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import {
  payment,
  paymentPerson,
  paymentStatus,
  typeOfOrder,
} from '../../../../utils/index';
import {
  cityOrdersRef,
  tariffRef,
  villageRef,
} from '../../../../utils/collectionRefs';
import { db } from '../../../../configs';
import { Title } from '../../../../components/Title/Title';
import { FormValidation } from '../../../../components/Form/FormValidation/exports';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import {
  Button,
  TextField,
  MenuItem,
  Box,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const AddCityOrder = ({ pageTitle }) => {
  const date = new Date();
  const [open, setOpen] = useState(false);
  const [tariff, setTariff] = useState(null);
  const [district, setDistrict] = useState(null);
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [tariffDocs, districtDocs] = await Promise.all([
          getDocs(tariffRef),
          getDocs(query(villageRef, where('district', '==', 1))),
        ]);

        setTariff(tariffDocs.docs.map((doc) => ({ ...doc.data() })));
        setDistrict(districtDocs.docs.map((doc) => ({ ...doc.data() })));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);



  const handlePostOrder = async (order) => {
    const userCollectionRef = collection(
      db,
      `${roleType}`,
      `${userName}`,
      'history'
    );
    try {
      setOpen(!open);


      const sendData = {
        addressFrom: {
          address: order.fromAddress,
          city: 1,
          cityName: 'Бишкек',
          district: order?.fromDistrict?.id ?? null,
          districtName: order?.fromDistrict?.name ?? null,
          link2gis: order?.fromLink2gis ?? '',
          lat: 42.876254,
          lon: 74.604228,
        },
        addressTo: {
          address: order.toAddress,
          city: 1,
          cityName: 'Бишкек',
          district: order?.toDistrict?.id ?? null,
          districtName: order?.toDistrict?.name ?? null,
          link2gis: order?.toLink2gis ?? '',
          lat: 42.876254,
          lon: 74.604228,
        },
        tariff: {
          cost: order.tariff.cost,
          name: order.tariff.name,
          uid: String(order.tariff.order),
        },
        tariffId: String(order.tariff.order),
        creator: userName,
        cancellationReason: '',
        comments: order.commits,
        cost: !order.cost ? order.tariff.cost : Number(order.cost),
        cityFilter: 1,
        cityFrom: 1,
        cityTo: 1,
        courierOne: '',
        courierTwo: '',
        dateCreated: date,
        packageType: order.orderType,
        paymentMethod: order.paymentMethod,
        paymentStatus: order.paymentStatus === 'false' ? false : true,
        pid: `AE-${1}-${date.getTime()}`,
        receiver: order.toPhone,
        receiverName: order.toName,
        receiverPhone: order.toPhone,
        redemption: Number(order.redemption),
        sender: order.fromPhone,
        senderName: order.fromName,
        senderPhone: order.fromPhone,
        status: 'status_new',
        statusFilter: ['status_new'],
        whoPays: Number(order.paymentPerson),
      };
      await addDoc(cityOrdersRef, sendData);
      await addDoc(userCollectionRef, {
        date: new Date(),
        description: `${userType} ${userName} добавил заказ в список заказов (Город)`
      })
      setOpen(false);
      navigate('/cityOrders');

      toast.success('Заказ успешно добавлен', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      reset();
    } catch (e) {
      setOpen(false);
      console.log(e)
      toast.error('Произошла ошибка при добавлении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Добавление заказа (Город)' />
        <Title
          title='Добавление заказа (Город)'
          icon={<AddCircleIcon fontSize='meduim' />}
        />
        <div className='container-inner'>
          {isLoading ? (
            <Loader />

          ) : (
            <form className='order-form'>
              <div className='order-form-flex'>
                {/* ОТПРАВИТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Отправитель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='fromPhone'
                      control={control}
                      defaultValue=''
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Отправитель'
                          size='small'
                          error={!!errors?.fromPhone}
                          helperText={errors?.fromPhone?.message}
                          // inputRef={inputRef}
                          {...field}
                        // onChange={(e) => {
                        //   handlePhoneChange(e.target.value);
                        //   if (e.target.value === '') {
                        //     setValue('fromPhone', ''); // Если значение пустое, сбросить его
                        //     setValue('fromName', ''); // Если значение пустое, сбросить его
                        //   }
                        //   setValue('fromPhone', e.target.value);
                        // }}
                        />
                      )}
                    />
                    {/* <div
                      className={
                        !isMenuOpen
                          ? 'testing-client-list'
                          : 'testing-client-list open'
                      }
                    >
                      <ul>{renderMenuItems()}</ul>
                    </div> */}
                    <TextField
                      id='outlined-basic'
                      label={'Имя отправителя'}
                      variant='outlined'
                      size='small'
                      helperText={errors?.fromName?.message}
                      error={errors?.fromName && true}
                      {...register('fromName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />

                    <Controller
                      name='fromDistrict'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='from-district'
                          disablePortal
                          options={district || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => field.onChange(val)}
                          value={field.value || null}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name={`random-${Math.random()
                                  .toString(36)
                                  .substring(7)}`}
                                label='Район/село'
                                variant='outlined'
                                size='small'
                                error={!!errors?.fromDistrict}
                                helperText={errors?.fromDistrict?.message}
                                className='w-full'
                              />
                            );
                          }}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Ссылка 2GIS'
                      variant='outlined'
                      size='small'
                      error={errors?.fromLink2gis ? true : false}
                      helperText={errors ? errors?.fromLink2gis?.message : ''}
                      {...register('fromLink2gis', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес отправителя'
                      variant='outlined'
                      size='small'
                      error={errors?.fromAddress ? true : false}
                      helperText={errors ? errors?.fromAddress?.message : ''}
                      {...register('fromAddress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ----------------- */}
                {/* ПОЛУЧАТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Получатель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='toPhone'
                      control={control}
                      defaultValue=''
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Получатель'
                          size='small'
                          error={!!errors?.toPhone}
                          helperText={errors?.toPhone?.message}
                          // inputRef={inputRef2}
                          {...field}
                        // onChange={(e) => {
                        //   handlePhoneChange2(e.target.value);
                        //   if (e.target.value === '') {
                        //     setValue('toPhone', ''); // Если значение пустое, сбросить его
                        //     setValue('toName', ''); // Если значение пустое, сбросить его
                        //   }
                        //   setValue('toPhone', e.target.value);
                        // }}
                        />
                      )}
                    />
                    {/* <div
                      className={
                        !isMenuOpen2
                          ? 'testing-client-list'
                          : 'testing-client-list open'
                      }
                    >
                      <ul>{renderMenuItems2()}</ul>
                    </div> */}
                    <TextField
                      id='outlined-basic'
                      label={'Имя получателя'}
                      variant='outlined'
                      size='small'
                      helperText={errors?.toName?.message}
                      error={errors?.toName && true}
                      {...register('toName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <Controller
                      name='toDistrict'
                      control={control}
                      defaultValue=''
                      // rules={{
                      //   required: 'Объязательное поле',
                      // }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-district'
                          disablePortal
                          options={district || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => field.onChange(val)}
                          value={field?.value || null}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name={`random-${Math.random()
                                  .toString(36)
                                  .substring(7)}`}
                                label='Район/село'
                                variant='outlined'
                                size='small'
                                error={!!errors?.toDistrict}
                                helperText={errors?.toDistrict?.message}
                                className='w-full'
                              />
                            );
                          }}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Ссылка 2GIS'
                      variant='outlined'
                      size='small'
                      error={errors?.toLink2gis ? true : false}
                      helperText={errors ? errors?.toLink2gis?.message : ''}
                      {...register('toLink2gis', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес получателя'
                      variant='outlined'
                      size='small'
                      error={errors?.toAddress ? true : false}
                      helperText={errors ? errors?.toAddress?.message : ''}
                      {...register('toAddress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ------------- */}
                {/* ДЕТАЛИ ЗАКАЗА */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Детали заказа</h3>
                  </div>
                  <div className='order-input-block'>
                    <Box sx={{ display: 'flex', gap: '4px' }}>
                      <TextField
                        sx={{ width: '90%' }}
                        id='filled-select-currency'
                        select
                        label='Тип посылки'
                        defaultValue={typeOfOrder[0].value}
                        variant='outlined'
                        size='small'
                        error={errors?.orderType && true}
                        {...register('orderType', {
                          required: FormValidation.RequiredInput.required,
                        })}
                      >
                        {typeOfOrder.map((type) => (
                          <MenuItem key={type.id} value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Controller
                        name='tariff'
                        control={control}
                        defaultValue={tariff[0]}
                        rules={{ required: 'Обязательное поле!' }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={tariff || []}
                            getOptionLabel={(option) =>
                              `${option?.name}(${option?.cost}с)` || ''
                            }
                            style={{ width: '90%' }}
                            onChange={(_, val) => {
                              field.onChange(val);
                              setValue('cost', val?.cost);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Тариф'
                                variant='outlined'
                                className='add-courier-input'
                                size='small'
                                style={{ width: '100%' }}
                                error={!!errors.tariff}
                                helperText={errors?.tariff?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>

                    <TextField
                      type='text'
                      size='small'
                      label=' Укажите  цену'
                      defaultValue={
                        !getValues('cost') ? tariff[0]?.cost : getValues('cost')
                      }
                      {...register('cost')}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Выкуп (0 если без выкупа)'
                      variant='outlined'
                      size='small'
                      defaultValue={0}
                      type='number'
                      {...register('redemption', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Кто оплачивает'
                      defaultValue={paymentPerson[0].value}
                      variant='outlined'
                      size='small'
                      {...register('paymentPerson', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentPerson.map((person) => (
                        <MenuItem key={person.id} value={person.value}>
                          {person.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Метод оплаты'
                      defaultValue={payment[0].value}
                      variant='outlined'
                      size='small'
                      {...register('paymentMethod', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {payment.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Статус оплаты'
                      defaultValue={paymentStatus[0].value}
                      variant='outlined'
                      size='small'
                      {...register('paymentStatus', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentStatus.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                {/* ДОПОЛНИТЕЛЬНО */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Дополнительно</h3>
                  </div>
                  <div className='order-input-block'>
                    <TextField
                      id='outlined-multiline-static'
                      label='Коментарии'
                      multiline
                      rows={4}
                      {...register('commits')}
                    />
                  </div>
                </div>
              </div>
              <Box sx={{ display: 'flex', gap: '4px' }}>
                <Button
                  onClick={handleSubmit((data) => handlePostOrder(data))}
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f' }}
                >
                  Оформить заказ
                </Button>
              </Box>
            </form>
          )}

        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};
export default AddCityOrder;
