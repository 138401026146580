import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { storeCategorysRef } from '../../../../utils/collectionRefs';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OrderGrid from '../../../../components/OrderGrid ';
import { Loader } from '../../../../components/Loader/Loader';

import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import { storeCatsColumns } from './constants/storeCatsColumns';
import { toast } from 'react-toastify';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';

const StoreCategories = ({ pageTitle }) => {
  const [storeCats, setStoreCats] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate()
  const USERTYPE = localStorage.getItem('userType');

  const fetchStores = useCallback(async () => {
    setIsLoading(true)
    try {
      const q = query(storeCategorysRef, orderBy('priority', 'asc'))
      const storesDocs = await getDocs(q)
      const newStoreDocs = storesDocs.docs.map((item) => ({ ...item.data(), id: item.id }))
      setStoreCats(newStoreDocs)
    } catch (error) {
      console.error('Error fetching storeCats:', error);
    } finally {
      setIsLoading(false)
    }
  }, [])

  const deleteStoreCategory = async (id) => {
    try {
      setIsLoading(true)
      const deletingDoc = doc(storeCategorysRef, id)
      await deleteDoc(deletingDoc)
      toast.success('Категория успешно удалена', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      await fetchStores();
    } catch (error) {
      console.error('Error deleting order:', error);
      toast.error('Произошла ошибка при удалении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      setOpen(false)
      setIsLoading(false)
    }
  }
  const handleClickOpen = (id) => {
    setOpen(true);
    setDeleteId(id)
  };
  const handleClickClose = () => {
    setOpen(false);
    setDeleteId(null)
  };
  useEffect(() => {
    fetchStores();
  }, [fetchStores]);


  const columns = storeCatsColumns({ event: handleClickOpen })

  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header initial='Категории магазина' previous='' />
        <Title title='Категории магазина' icon={<StoreMallDirectoryRoundedIcon fontSize='large' />} />
        <div className="container-inner">
          <div className="orders-card-wrapper">
            <div className="orders-card-header">
              <div className="orders-card-parent">
                <div className="orders-card-parent__btns">
                  {USERTYPE === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/storeCategory/add')}>
                      Новая категория +
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {isLoading ? <Loader /> : <OrderGrid orders={storeCats} columns={columns} size={20} />}

          </div>
        </div>
      </div>
      <CustomDialog
        open={open}
        onClose={handleClickClose}
        event={() => deleteStoreCategory(deleteId)}
        text='Вы уверены что хотите удалить категорию?'
        btnText='Удалить'
      />
    </>
  )
}

export default StoreCategories