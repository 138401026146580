import { dateFormatter } from "../../../../../utils/dateFormatter";
import { transactionStatusLabels } from "../constants";

export const transactionExcelDataFormatter = ((transaction) => {
  const sender = transaction.sender ? `${transaction.sender}` : 'Отсутствует'
  const receiver = transaction.receiver ? `${transaction.receiver}` : 'Отсутствует'
  return [
    dateFormatter(transaction.date?.seconds),
    `${transaction.user}`,
    sender,
    receiver,
    `${transactionStatusLabels[transaction.type]}`,
    ` ${transaction.balanceBefore}`,
    ` ${transaction.sum}`,
    ` ${transaction.balanceAfter}`,
    ` ${transaction.comment}`,
  ]
})