import { IconButton, Tooltip } from "@mui/material";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Link } from "react-router-dom";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import dayjs from "dayjs";

export const kassaColumn = () => {
  const roleType = localStorage.getItem('roleType')
  return [
    {
      flex: 0.1,
      field: 'id',
      sortable: false,
      renderHeader: () => <strong>#</strong>,
    },
    {
      flex: 0.5,
      field: 'dateCreated',
      disableColumnMenu: false,
      sortable: true,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderCell: (params) => (
        <div>
          <time>{dayjs(params.row.dateCreate).format('DD/MM/YYYY')}</time>
        </div>
      ),
      renderHeader: () => <strong>Дата создания</strong>,
    },
    {
      flex: 1,
      field: 'name',
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Название</strong>,
    },
    {
      flex: 1,
      field: 'balance',
      disableColumnMenu: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Баланс</strong>,
    },
    {
      flex: 1,
      field: 'comment',
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Коментарии</strong>,
    },
    {
      field: 'actions',
      sortable: false,
      flex: 0.5,
      menubar: false,
      renderCell: (params) => (
        <div>
          <Tooltip title='Детали' arrow>
            <Link
              to={`/kassa/details/${params.id}`}
              target='_blank'
            >
              <IconButton aria-label='edit'>
                <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Link>
          </Tooltip>
          {roleType === 'admin' && (
            <Tooltip title='Редактировать' arrow>
              <Link
                to={`/kassa/update/${params.id}`}
                target='_blank'
              >
                <IconButton aria-label='edit'>
                  <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        </div>
      ),
      renderHeader: () => <strong>Действия</strong>,
    }
  ]
};