import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { BiPackage } from 'react-icons/bi';
import { GoPackage } from 'react-icons/go';
import { FiPackage } from 'react-icons/fi';
import { dateFormatter } from '../../../../utils/dateFormatter';
import { db } from '../../../../configs';
import { Title } from '../../../../components/Title/Title';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { toast } from 'react-toastify';
import {
  orderStatus,
  packageTypeLabels,
  paymentMethods,
} from '../../../../utils';
import {
  Divider,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import OrderDetailBox from '../../../../components/OrderDetailBox';
import './scss/OrderDetail.scss';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import noImage from '../../../../assets/images/noimage.jpeg';
import { Helmet } from 'react-helmet';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';

import { addToHistory } from './../../../../utils/addToHistory';
import { completedOrdersRef, couriersRef, orderRef } from '../../../../utils/collectionRefs';
import OrderEditModal from '../../../../components/CustomDialog/Modal';

const CompletedOrderDetail = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [courierOne, setCourierOne] = useState(null);
  const [courierTwo, setCourierTwo] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [editField, setEditedField] = useState(null);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const qrCodeRef = useRef(null);

  const navigate = useNavigate();

  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const userCollectionRef = collection(
    db,
    `${roleType}`,
    `${userName}`,
    'history'
  );

  useEffect(() => {
    const getOrder = async () => {
      try {
        const docRef = doc(completedOrdersRef, id);
        const data = await getDoc(docRef);
        return { ...data.data(), id: data.id };
      } catch (e) {
        console.error(e.message);
        return null;
      }
    };
    getOrder().then((order) => setOrder(order));
    // eslint-disable-next-line
  }, [id]);

  const fetchCourierData = async (courierId, setCourier) => {
    if (courierId) {
      const docRef = doc(couriersRef, courierId);
      const docSnap = await getDoc(docRef);
      setCourier(docSnap.exists() ? docSnap.data() : null);
    }
  };

  useEffect(() => {
    fetchCourierData(order?.courierOne, setCourierOne);
  }, [order?.courierOne]);

  useEffect(() => {
    fetchCourierData(order?.courierTwo, setCourierTwo);
  }, [order?.courierTwo]);

  const deleteOrder = async (id) => {
    try {
      const orderRef = doc(completedOrdersRef, id);
      await deleteDoc(orderRef);
      navigate('/orders/completed');
      toast.success('Заказ успешно удален', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      toast.error('Произошла ошибка при удалении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const imgList = [
    {
      urlPhoto: order?.senderItemPhoto,
      title: 'Фото отправителя',
    },
    {
      urlPhoto: order?.senderSignature,
      title: 'Подпись отправителя',
    },
    {
      urlPhoto: order?.receiverItemPhoto,
      title: 'Фото получателя',
    },
    {
      urlPhoto: order?.receiverSignature,
      title: 'Подпись получателя',
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleEditOrder = async (editedOrder, editField) => {
    setEditValue(editedOrder)
    setEditedField(editField)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveChanges = async (editedValue) => {
    const LOADING_MESSAGE = `Редактирование ${editField}...`;
    let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });
    try {
      const orderDocRef = doc(completedOrdersRef, id);
      const description = `${userType} ${userName} обновил ${editField} на ${editedValue} | ID: ${id}`
      const historyRef = collection(orderDocRef, 'history')

      await updateDoc(orderDocRef, { [editField]: editedValue });
      await addToHistory(historyRef, description)
      await addToHistory(userCollectionRef, description)

      setIsModalOpen(false);

      toast.dismiss(loadingToastId);
      toast.success('Заказ успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      console.error(error)
      toast.dismiss(loadingToastId);
      toast.error('Произошла ошибка при удалении заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      setIsModalOpen(false);
    }
  };

  function renderOrderStatus(order) {
    const isRejectedOrCancelled =
      order.status === 'status_rejected' || order.status === 'status_cancelled';

    return (
      <Stepper
        activeStep={
          isRejectedOrCancelled
            ? 10
            : orderStatus.findIndex((status) => status.value === order.status)
        }
        alternativeLabel
        sx={{ overflowX: 'scroll', paddingBottom: '20px' }}
        className='scrollable'
      >
        {isRejectedOrCancelled ? (
          <Step key={order.status} sx={{ minWidth: '130px' }}>
            <StepLabel sx={{ fontSize: '12px', color: 'red' }}>
              {orderStatus.find((status) => status.value === order.status).name}
            </StepLabel>
          </Step>
        ) : (
          orderStatus.slice(0, 10).map((label) => (
            <Step key={label.value} completed sx={{ minWidth: '130px' }}>
              <StepLabel sx={{ fontSize: '12px' }}>{label.name}</StepLabel>
            </Step>
          ))
        )}
      </Stepper>
    );
  }

  const OrderDetails = ({ sender, receiver }) => {
    return (
      <>
        <OrderDetailBox
          title='Отправитель'
          items={[
            { label: 'Имя', value: sender?.senderName },
            { label: 'Номер телефона', value: sender?.sender },
            { label: 'Город', value: sender?.addressFrom?.cityName },
            {
              label: 'Регион/село',
              value: sender?.addressFrom?.districtName || '----------',
            },
            { label: 'Адрес отправки', value: sender?.addressFrom?.address },
          ]}
        />
        <OrderDetailBox
          title='Получатель'
          items={[
            { label: 'Имя', value: receiver?.receiverName },
            { label: 'Номер телефона', value: receiver?.receiver },
            { label: 'Город', value: receiver?.addressTo?.cityName },
            {
              label: 'Регион/село',
              value: receiver?.addressTo?.districtName || '----------',
            },
            { label: 'Адрес отправки', value: receiver?.addressTo?.address },
          ]}
        />
      </>
    );
  };

  const renderCourierDetailBox = (courier, title) => {
    if (!courier) return null;

    const items = [
      { label: 'Имя', value: `${courier.name} ${courier.surName}` },
      { label: 'Номер телефона', value: courier.phone },
      { label: 'Город', value: courier.cityInfo?.name },
      { label: 'Рейтинг', value: courier.raiting },
      { label: 'Тип курьера', value: courier.type },
    ];

    return <OrderDetailBox title={title} items={items} />;
  };

  const renderPackageTypeIcon = (packageType) => {
    switch (packageType) {
      case 'document':
        return (
          <FilePresentRoundedIcon fontSize='small' sx={{ color: '#3A46D6' }} />
        );
      case 'medicine':
        return (
          <MedicationRoundedIcon fontSize='small' sx={{ color: '#b42318' }} />
        );
      case 'food':
        return (
          <FastfoodRoundedIcon fontSize='small' sx={{ color: '#f4450f' }} />
        );
      case 'large_box':
        return <GoPackage size={18} color='#f0ad4e' />;
      case 'small_box':
        return <BiPackage size={18} color='#f0ad4e' />;
      case 'box':
        return <FiPackage size={18} color='#f0ad4e' />;
      default:
        return null;
    }
  };

  const printOrderInfo = () => {
    if (qrCodeRef.current) {
      // Получите значение QR-кода и отправьте его на сервер для печати
      const qrCodeValue = qrCodeRef.current.toDataURL();
      // Ваш код для отправки qrCodeValue на сервер и печати
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Список заказов'} initial='Детали заказа' />
        <Title
          title={'Детали заказа'}
          icon={<DescriptionRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          {!order ? (
            <Loader />
          ) : (
            <div className='orders-more-wrapper'>
              <div className='orders-more-box delivered'>
                <div className='orders-more-box-header'>
                  <h3>Статус заказа</h3>
                </div>
                <Divider />
                <Box sx={{ width: '100%' }}>{renderOrderStatus(order)}</Box>
              </div>

              <ImageList
                sx={{ width: 500, height: 450 }}
                cols={2}
                rowHeight={164}
              >
                {imgList.map((item) => (
                  <ImageListItem key={item.urlPhoto}>
                    <img
                      srcSet={!item.urlPhoto ? noImage : item.urlPhoto}
                      src={!item.urlPhoto ? noImage : item.urlPhoto}
                      alt={item.title}
                      loading='lazy'
                    />
                    <ImageListItemBar title={item.title} position='top' />
                  </ImageListItem>
                ))}
              </ImageList>

              <div className='order-detail-wrap'>
                {[courierOne, courierTwo].map((courier, index) => (
                  <React.Fragment key={index}>
                    {renderCourierDetailBox(courier, `Курьер ${index + 1}`)}
                  </React.Fragment>
                ))}
              </div>

              <OrderDetails title='Заказ' sender={order} receiver={order} />

              <div className='order-detail-wrap'>
                <div className={'orders-more-box'}>
                  <div className='orders-more-box-header'>
                    <h3>Детали заказа</h3>
                  </div>
                  <Divider />
                  <ul className='orders-more-body-list'>
                    <li>
                      <span>ID</span>
                      <span>{order?.id}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Дата</span>
                      <span>{dateFormatter(order?.dateCreated?.seconds)}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Дата доставки</span>
                      <span>{dateFormatter(order?.dateDelivered?.seconds)}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Тип посылки</span>
                      <span className='order-more-list-type'>
                        {packageTypeLabels[order?.packageType]}
                        {renderPackageTypeIcon(order?.packageType)}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Тариф заказа</span>
                      <span className='order-more-list-type'>
                        {order?.tariff?.name}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Платежное лицо</span>
                      <span>
                        {(() => {
                          switch (order?.whoPays) {
                            case 1:
                              return 'Отправитель';
                            case 2:
                              return 'Получатель';
                            default:
                              return 'Совместно';
                          }
                        })()}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Метод оплаты</span>
                      <span>{paymentMethods[order?.paymentMethod]}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Статус оплаты</span>
                      <span className='order-more-list-type'>
                        {!order?.paymentStatus ? 'Не оплачен' : 'Оплачен'}
                        {!order?.paymentStatus ? (
                          <DoNotDisturbAltRoundedIcon
                            fontSize='small'
                            sx={{ color: '#b42318' }}
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            fontSize='small'
                            sx={{ color: '#0b815a' }}
                          />
                        )}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Реквизиты</span>
                      <span className='order-more-list-type-edit'>
                        {order?.requisite}
                        <NoteAltRoundedIcon onClick={() => handleEditOrder(order?.requisite, 'requisite')} />
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Выкуп</span>
                      <span>{order?.redemption}с</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Сумма</span>
                      <span>{order?.cost}с</span>
                    </li>
                    <Divider />
                    <li className='order-more-list-comments'>
                      <span>Коментарии</span>
                      <span>
                        {order?.comments ? order?.comments : '----------'}
                      </span>
                    </li>
                  </ul>
                </div>
                <Box
                  display='flex'
                  alignItems='center'
                  flexDirection='column'
                  gap='12px'
                // width={'50%'}
                // height={'200px'}
                >
                  <Box ref={qrCodeRef} className='order-qr-box'>
                    <div className='orders-more-box-header'>
                      <h3>PID: {order?.pid ? order?.pid : 'Нет PID'}</h3>
                    </div>
                    <QRCode value={order?.pid ?? 'Отсутствует'} size={260} />
                  </Box>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant='contained'
                        sx={{ background: '#000b1f' }}
                        onClick={printOrderInfo}
                        fullWidth
                      >
                        Распечатать
                      </Button>
                    )}
                    content={() => qrCodeRef.current}
                  />
                </Box>
              </div>
              <div className='orders-more-box actions'>
                {userType === 'Админ' && (
                  <Button variant='contained' onClick={handleClickOpen}>
                    Удалить
                  </Button>
                )}
                <Button
                  variant='contained'
                  onClick={() => navigate(`/order/history/orders/${id}`)}
                >
                  История
                </Button>
              </div>
            </div>
          )}
          <OrderEditModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveChanges}
            defaultValue={editValue}
            editingField={editField}
          />
          <CustomDialog
            open={open}
            onClose={handleClickClose}
            event={() => deleteOrder(id)}
            text='Вы уверены что хотите удалить заказ?'
            btnText='Удалить'
          />
        </div>
      </div>
    </>
  );
};

export default CompletedOrderDetail;
