import React, { useEffect, useState } from 'react';
import { Title } from '../../../../components/Title/Title';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { transactionsRef } from '../../../../utils/collectionRefs';
import { useNavigate, useParams } from 'react-router-dom';
import { doc,getDoc, updateDoc } from 'firebase/firestore';
import '../Couriers/scss/AddCourier.scss';
import {
  TextField,
  Button,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Helmet } from 'react-helmet';

const TransactionUpdate = ({pageTitle}) => {
  const { id } = useParams()
  const [transaction, setTranaction] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  useEffect(() => {
    const getTransactionById = async () => {
      const transactionRef = doc(transactionsRef, id);
      const tranactionSnap = await getDoc(transactionRef);

      if (tranactionSnap.exists()) {
        setTranaction(tranactionSnap.data());
      } else {
        console.log('No such tranaction!');
      }
    };

    getTransactionById();
  }, [id]);


  const handleUpdateTransaction = async (data) => {
    try {
      setOpen(true);

      await updateDoc(doc(transactionsRef, id), { ...data });
      setOpen(false);
      navigate('/transaction');

      toast.success('Транзакция успешно отредактирована', {
        autoClose: 2000,
        hideProgressBar: false,
      });


      reset();
    } catch (e) {
      console.error('Error updating document: ', e);
      toast.error('Произошла ошибка при редактировании транзакции', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className='container'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
        <Header previous='Статистика' initial='Редактирование транзакции' />
        <Title title={'Редактирование транзакции'} icon={<NoteAltRoundedIcon fontSize='24' />} />
        <div className='container-inner'>
          {!transaction ? (
            <Loader />
          ) : (
            <div className='addCourier-wrapper transaction'>
              <div className='order-block-head'>
                <h3>Редактирование транзакции</h3>
              </div>
              <form className='add-courier-form'>
                <Box className='add-courier-form__input'>
                  <Controller
                    name='comment'
                    control={control}
                    defaultValue={transaction?.comment || ''}
                    rules={{ required: 'Обьязательное полe!' }}
                    render={({ field }) => (
                      <TextField
                        label='Комментарии'
                        placeholder='Коментарии...'
                        multiline
                        rows={8}
                        variant='outlined'
                        size='small'
                        helperText={errors?.name?.message}
                        error={errors?.name && true}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Button
                  onClick={handleSubmit((data) => handleUpdateTransaction(data))}
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f', width: 'auto' }}
                  sx={{ marginTop: '10px' }}
                >
                  Сохранить
                </Button>
              </form>
            </div>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

export default TransactionUpdate;
